import Picker from "./component";

const TimePicker = ({ className, optionGroups, valueGroups, onChange, onChangeUnit }) => {
  return (
    <div className={className}>
      <div
        style={{
          position: "relative",
        }}
      >
        <Picker
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={onChange}
          itemHeight={44}
          height={132}
          onChangeUnit={onChangeUnit}
        />
      </div>
    </div>
  );
};

export default TimePicker;
