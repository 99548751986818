import { createReducer, createActions } from "reduxsauce";
import { REDUX_STATE, requestReducerFunc, successReducerFunc } from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  notificationSettingSet: ["data"],
  updateAccountSetting: ["data", "prefixKey"],
  updateGroupSetting: ["data", "prefixKey"],
  updateThreadSetting: ["data", "prefixKey"],
});

export const NotificationSettingTypes = Types;
export const NotificationSettingActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,

  updateAccountSetting: [], // updateList
  updateGroupSetting: {}, // { groupId, updateList: [] }
  updateThreadSetting: {}, // { threadId, updateList: [] }
};

/* ------------- Selector ------------- */
export const NotificationSettingSelectors = {
  getUpdateAccountSetting: ({ notificationSettingRedux }) => notificationSettingRedux.updateAccountSetting,
  getUpdateGroupSetting: ({ notificationSettingRedux }) => notificationSettingRedux.updateGroupSetting,
  getUpdateThreadSetting: ({ notificationSettingRedux }) => notificationSettingRedux.updateThreadSetting,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

const set = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.NOTIFICATION_SETTING_SET]: set,
  [Types.UPDATE_ACCOUNT_SETTING]: request,
  [Types.UPDATE_GROUP_SETTING]: request,
  [Types.UPDATE_THREAD_SETTING]: request,
};

/* ------------- Hookup Reducers To Types ------------- */
export const NotificationSettingReducer = createReducer(INITIAL_STATE, HANDLERS);
