import { createApiWithDomain } from "./api.config";
import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import QueryString from "qs";
import { toCamel } from "utils";
import { getLabel } from "language";
import { CommonBranchInfoService } from "services";
import { BGlobalServerImage } from "const/image.const";
import store, { BranchActions } from "redux-store";
import { getCommonInteractor } from "services/local.service";
import { changeBranchServer } from "utils/branch.utils";
import { handlingLogoutBranch, refreshTokenData } from "utils/auth.utils";

// Only create RemoteApi api object when there are accountId/ branchId
export default class RemoteApi {
  constructor(prefixKey, domain, token) {
    if (!domain) throw new Error(`CAN NOT INIT API - Invalid domain - ${domain}`);

    this.prefixKey = prefixKey;
    this.api = createApiWithDomain(domain);
    this.configApi();
    this.setToken(token);
    this.isRefreshToken = false;
  }

  configApi = () => {
    this.api.addAsyncResponseTransform(async response => {
      if (response.status === ApiConstant.STT_UNAUTHORIZED && response.config.url !== ApiConstant.POST_REFRESH_TOKEN) {
        const newResponse = await this.handleRefreshToken(response);
        if (newResponse?.ok) {
          response.ok = newResponse.ok;
          response.status = newResponse.status;
          response.data = JSON.parse(JSON.stringify(newResponse.data));
        }
      }
    });
  };

  setToken = token => {
    if (token) {
      this.api.setHeader("Authorization", `Bearer ${token}`);
    }
  };

  handleLogout = async url => {
    await handlingLogoutBranch(this.prefixKey, url);
    const LocalDbManagement = getCommonInteractor().dbManagement;
    const activeBranchList = await LocalDbManagement.find({ state: SystemConstant.STATE.active });
    let dbManagementInfo = activeBranchList.find(item => false === url.includes(item.branch_domain));

    if (dbManagementInfo) {
      dbManagementInfo = toCamel(dbManagementInfo);
      const avatarUrl = CommonBranchInfoService.getBranchAvatarUrl(
        dbManagementInfo.branchDomain,
        dbManagementInfo.branchId,
      );
      const selectBranch = {
        id: dbManagementInfo.branchId,
        accountId: dbManagementInfo.accountId,
        loginState: dbManagementInfo.state,
        phone: dbManagementInfo.ownerName,
        name: dbManagementInfo.branchName,
        domain: dbManagementInfo.branchDomain,
        type:
          dbManagementInfo.branchId === SystemConstant.GLOBAL_BRANCH_ID
            ? SystemConstant.SERVER_TYPE.server
            : SystemConstant.SERVER_TYPE.branch,
        branchIcon: avatarUrl || BGlobalServerImage,
      };

      changeBranchServer(selectBranch);
      window.location.reload();
    }
  };

  handleRefreshToken = async response => {
    if (this.isRefreshToken) return;

    this.isRefreshToken = true;
    // Logout if more than number retry refresh token
    const requestRefreshToken = async (response, retry = 1) => {
      if (retry > 3) {
        const currentDomain = window.electronUtils.storage.getCommonKey(KeyConstant.KEY_CURRENT_DOMAIN);
        const isCurrentBranch = this.api.getBaseURL().includes(currentDomain);
        if (isCurrentBranch) {
          window.dispatchEvent(
            new CustomEvent(AppConstant.NOTICE_EVENT_NAME, {
              detail: {
                content: getLabel(LangConstant.TXT_SESSION_EXPIRED),
                callback: () => this.handleLogout(this.api.getBaseURL()),
              },
            }),
          );
        } else {
          await handlingLogoutBranch(this.prefixKey, this.api.getBaseURL());
          store.dispatch(
            BranchActions.branchSet({
              fetchBranchTimestamp: new Date().getTime(),
            }),
          );
        }
        return response;
      }

      const access_token = window.electronUtils.storage.getItem(KeyConstant.KEY_TOKEN, this.prefixKey);
      const refresh_token = window.electronUtils.storage.getItem(KeyConstant.KEY_REFRESH_TOKEN, this.prefixKey);

      const refreshResponse = await this.refreshToken({ access_token, refresh_token });

      let retryResponse = { ...response };
      if (refreshResponse.status === ApiConstant.STT_OK) {
        const responseData = toCamel(refreshResponse.data);
        refreshTokenData(this.prefixKey, responseData);
        this.setToken(responseData.accessToken);

        const newApiConfig = { ...response.config };
        newApiConfig.headers["Authorization"] = `Bearer ${responseData.accessToken}`;
        retryResponse = await this.api.any(newApiConfig); // Retry calling API after refresh token
      } else if (
        retryResponse.status !== ApiConstant.STT_MAINTAIN_1 ||
        retryResponse.status !== ApiConstant.STT_MAINTAIN_2 ||
        retryResponse.status !== ApiConstant.STT_MAINTAIN_3
      ) {
        retryResponse = await requestRefreshToken(response, retry + 1);
      } else if (
        retry >= 3 &&
        (retryResponse.status === ApiConstant.STT_UNAUTHORIZED || retryResponse.status === ApiConstant.STT_FORBIDDEN)
      ) {
        await handlingLogoutBranch(this.prefixKey, retryResponse.config.url);
      }

      return retryResponse;
    };

    const retryResponse = await requestRefreshToken(response);
    this.isRefreshToken = false;

    return retryResponse;
  };

  // ------------------------ Auth Api ------------------------
  verify = async data => {
    const response = await this.api.post(ApiConstant.POST_VERIFY, QueryString.stringify(data, { skipNulls: true }));
    if (response.status === ApiConstant.STT_OK && response.data) {
      this.setToken(response.data[KeyConstant.KEY_TOKEN]);

      return toCamel(response.data);
    }

    return {};
  };

  refreshToken = data => this.api.post(ApiConstant.POST_REFRESH_TOKEN, QueryString.stringify(data));

  // ------------------------ Account Api --------------------------
  updateAccount = data =>
    this.api.post(
      ApiConstant.POST_UPDATE_ACCOUNT,
      QueryString.stringify(data, {
        skipNulls: true,
      }),
    );

  getAccountByIds = data =>
    this.api.post(ApiConstant.POST_GET_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  getAccountList = data => this.api.get(ApiConstant.GET_ACCOUNT_LIST, data);

  uploadFileAccount = data =>
    this.api.post(ApiConstant.POST_UPLOAD_FILE_ACCOUNT, data, {
      headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
    });

  blockAccount = data =>
    this.api.post(ApiConstant.POST_BlOCK_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  unblockAccount = data =>
    this.api.post(ApiConstant.POST_UNBLOCK_USER, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Device Api ---------------------
  getDevice = data => this.api.post(ApiConstant.POST_GET_DEVICE, QueryString.stringify(data, { skipNulls: true }));

  deleteDevice = data =>
    this.api.post(ApiConstant.POST_DELETE_DEVICE, QueryString.stringify(data, { skipNulls: true }));

  updateDevice = data =>
    this.api.post(ApiConstant.POST_UPDATE_DEVICE, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Key Api ------------------------
  uploadKeys = data =>
    this.api.post(ApiConstant.POST_UPLOAD_KEY_LIST, QueryString.stringify(data, { skipNulls: true }));

  getKeys = data => this.api.post(ApiConstant.POST_GET_KEY, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Contact Api --------------------
  getContactList = data => this.api.get(ApiConstant.GET_CONTACT_LIST, data);

  getContact = (account_id, contact_id) => this.api.get(ApiConstant.GET_CONTACT, { account_id, contact_id });

  updateContact = data =>
    this.api.post(ApiConstant.POST_UPDATE_CONTACT, QueryString.stringify(data, { skipNulls: true }));

  addContact = data => this.api.post(ApiConstant.POST_ADD_CONTACT, QueryString.stringify(data, { skipNulls: true }));

  deleteContact = contact_id => this.api.post(ApiConstant.POST_DELETE_CONTACT, QueryString.stringify({ contact_id }));

  // ------------------------ Branch Api -----------------------------
  getBranchList = () => this.api.post(ApiConstant.POST_GET_BRANCH_LIST);

  getBranchAccount = data =>
    this.api.post(ApiConstant.POST_GET_BRANCH_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  updateBranch = data =>
    this.api.post(ApiConstant.POST_UPDATE_BRANCH_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  syncMappingBranch = data => this.api.get(ApiConstant.GET_BRANCH_MAPPING, data);

  createBranchMapping = data => this.api.post(ApiConstant.POST_BRANCH_MAPPING, QueryString.stringify(data));

  // ----- [Conversation] Group Api - Include channel, group, personal -----
  addConversation = data =>
    this.api.post(ApiConstant.POST_CREATE_CONVERSATION, QueryString.stringify(data, { skipNulls: true }));
  getConversationList = data => this.api.get(ApiConstant.GET_CONVERSATION_LIST, data);

  getConversationMember = data => this.api.post(ApiConstant.POST_GET_CONVERSATION_MEMBER, QueryString.stringify(data));
  updateConversation = data =>
    this.api.post(ApiConstant.POST_UPDATE_CONVERSATION, QueryString.stringify(data, { skipNulls: true }));

  deleteConversation = data =>
    this.api.post(ApiConstant.POST_DELETE_CONVERSATION, QueryString.stringify(data, { skipNulls: true }));

  addConversationMembers = data =>
    this.api.post(ApiConstant.POST_ADD_MEMBERS, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Call Api ------------------------------
  getCallingStatus = data => this.api.get(ApiConstant.GET_CALL_STATUS, data);

  getCallHistory = data => this.api.get(ApiConstant.GET_CALL_HISTORY, data);

  updateCallHistory = data =>
    this.api.post(ApiConstant.POST_UPDATE_CALL_HISTORY, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Message Api ---------------------------
  sendMessage = data => this.api.post(ApiConstant.POST_SEND_MESSAGE, QueryString.stringify(data, { skipNulls: true }));

  sendMessageFile = data =>
    this.api.post(ApiConstant.POST_SEND_MESSAGE_FILE, data, {
      headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
    });

  getMessageList = data => this.api.get(ApiConstant.GET_MESSAGE_LIST, data);

  updateMessageStatus = data =>
    this.api.post(ApiConstant.POST_UPDATE_MESSAGE_STT, QueryString.stringify(data, { skipNulls: true }));

  // Get seen_member, received_member of message
  getMessageDetail = data => this.api.get(ApiConstant.GET_MESSAGE_DETAIL, data);

  // ---------------------- Backup/ Restore data ---------------------
  requestVerifyChangeDeviceRole = data =>
    this.api.post(ApiConstant.POST_VERIFY_OTP_CHANGE_DEVICE_ROLE, QueryString.stringify(data, { skipNulls: true }));

  requestBackupUpload = (data, branchId) =>
    this.api.post(ApiConstant.POST_BACKUP_UPLOAD, data, {
      headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
    });

  requestBackup = (data, branchId) =>
    this.api.post(ApiConstant.POST_BACKUP, QueryString.stringify(data, { skipNulls: true }));

  requestGetBackup = data => this.api.get(ApiConstant.GET_BACKUP_INFO, data);

  // ------------------------ JitsiMeet -------------------------------
  postMeetToken = data => this.api.post(ApiConstant.POST_MEET_TOKEN, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Other Api -------------------------------
  getEmoji = data => this.api.get(ApiConstant.GET_EMOJI, data);

  // ------------------------ ThirdParty Api --------------------------
  createThirdPartyToken = service_id =>
    this.api.post(ApiConstant.POST_CREATE_THIRD_TOKEN, QueryString.stringify({ service_id }));

  // ------------------------ Checking Server Status --------------------------
  checkingServerStatus = () => this.api.get(ApiConstant.GET_SERVER_TIME, {}, { timeout: 2000 });

  // ------------------------ PubSub ----------------------------------
  checkEvents = device_id => this.api.get(ApiConstant.GET_CHECK_EVENT, { device_id });

  postEventsFinish = data =>
    this.api.post(ApiConstant.POST_EVENT_FINISH, QueryString.stringify(data, { skipNulls: true }));

  getEventsList = data => this.api.get(ApiConstant.POST_EVENT_LIST, data);

  // ------------------------ Notification ----------------------------------
  getNotificationList = data => this.api.get(ApiConstant.GET_NOTIFICATION_LIST, data);

  // ------------------------ Multiple File ----------------------------------
  getFilesInfo = data => this.api.post(ApiConstant.GET_FILES_INFO, QueryString.stringify(data, { skipNulls: true }));

  getFileTus = (domain, url, groupId) =>
    this.api.get(
      url,
      {},
      {
        baseURL: domain,
        headers: {
          group_id: groupId,
        },
        responseType: "arraybuffer",
        timeout: 15 * 60 * 1000, // 15 minutes
      },
    );

  postFileStatus = data =>
    this.api.post(ApiConstant.POST_FILE_UPDATE, QueryString.stringify(data, { skipNulls: true }));

  // Pin message
  pinMessage = data => this.api.post(ApiConstant.POST_PIN_MESSAGE, QueryString.stringify(data, { skipNulls: true }));

  getPinList = data => this.api.post(ApiConstant.GET_PIN_LIST, QueryString.stringify(data, { skipNulls: true }));

  // Login by QR code: Manage Request
  getRequestList = data =>
    this.api.post(ApiConstant.POST_REQUEST_LIST, QueryString.stringify(data, { skipNulls: true }));

  updateRequest = data =>
    this.api.post(ApiConstant.POST_REQUEST_UPDATE, QueryString.stringify(data, { skipNulls: true }));

  // Get list Unit, Position, Department
  getListUnit = (branch_id, pagination) => this.api.get(ApiConstant.GET_UNIT_LIST, { branch_id, ...pagination });

  getListDepartment = (branch_id, unit_id, pagination) =>
    this.api.get(ApiConstant.GET_DEPARTMENT_LIST, { branch_id, unit_id, ...pagination });

  getListPosition = params => this.api.get(ApiConstant.GET_LIST_POSITIONS, params);

  // ------------------------ Notification Setting ----------------------------------
  // Required: setting_sub_type, state
  updateAccountNotiSetting = data =>
    this.api.post(ApiConstant.POST_UPDATE_ACCOUNT_NOTI_SETTING, QueryString.stringify(data, { skipNulls: true }));

  getAccountNotiSetting = data =>
    this.api.post(ApiConstant.POST_GET_ACCOUNT_NOTI_SETTING, QueryString.stringify(data, { skipNulls: true }));

  // Required: group_id, setting_sub_type, created, state
  updateGroupNotiSetting = data =>
    this.api.post(ApiConstant.POST_UPDATE_GROUP_NOTI_SETTING, QueryString.stringify(data, { skipNulls: true }));

  getGroupNotiSetting = data =>
    this.api.post(ApiConstant.POST_GET_GROUP_NOTI_SETTING, QueryString.stringify(data, { skipNulls: true }));

  // Required: thread_id, setting_sub_type, created, state
  updateThreadNotiSetting = data =>
    this.api.post(ApiConstant.POST_UPDATE_THREAD_NOTI_SETTING, QueryString.stringify(data, { skipNulls: true }));

  getThreadNotiSetting = data =>
    this.api.post(ApiConstant.POST_GET_THREAD_NOTI_SETTING, QueryString.stringify(data, { skipNulls: true }));
}
