import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { convertString2JSON, StorageUtil, toCamel } from "utils";
import { getInteractor } from "services/local.service";
import { getPinContent } from "utils/message.utils";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";
import { useAlertContext } from "components/context/AlertContext";
import ChatTypography from "./ChatTypography";
import { getLatestMessage } from "utils/view.utils";

const PinMessage = ({ message }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { showAlert } = useAlertContext();

  const [content, setContent] = useState("");
  const [pinMessage, setPinMessage] = useState(null);
  const [isPin, setIsPin] = useState(false);

  const handlePinMessage = async () => {
    const msgContent = convertString2JSON(message.content, {});
    const { pin_source_message_id, pin_type } = msgContent;
    const isValid = pin_source_message_id;
    if (!isValid) return;

    const pinedMessage = await getInteractor(prefixKey).LocalMessageService.findOne({
      source_id: pin_source_message_id,
      state: SystemConstant.STATE.active,
    });

    const latestPinMsg = await getLatestMessage(prefixKey, toCamel(pinedMessage));
    const pinMsgContent = getPinContent(latestPinMsg);

    const isPin = pin_type === SystemConstant.PIN_TYPE.pin;
    const displayContent = getNSLang(LangConstant.NS_HOME_CONVERSATION, isPin ? "FM_PIN_MESSAGE" : "FM_UNPIN_MESSAGE", {
      senderName: message.senderId === accountId ? "Bạn" : message.senderName,
      message: pinMsgContent,
    });

    setIsPin(isPin);
    setContent(displayContent);
    setPinMessage(latestPinMsg);
  };

  const handleJump2Message = () => {
    if (pinMessage && pinMessage.id) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: pinMessage.groupId,
          threadingId: pinMessage.threadId,
          scrollToChildId: pinMessage.state === SystemConstant.STATE.active ? pinMessage.sourceId : null,
        }),
      );
    } else {
      showAlert({ content: getCommonLang("TXT_NOT_FOUND_MESSAGE"), alertProps: { severity: "error" } });
    }
  };

  useEffect(() => {
    handlePinMessage();
  }, [message]);

  return (
    <Box className={classes.root}>
      <Stack direction="row" maxWidth="60%" justifyContent="center" alignItems="center" height="2rem">
        <ChatTypography className="ellipsis" messageContent={content} messageId={message.id} />
        {isPin && (
          <Button variant="text" classes={{ root: classes.btnRoot }} onClick={handleJump2Message}>
            {getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_VIEW_PIN")}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default PinMessage;

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnRoot: {
    minWidth: "unset",
    textTransform: "none",
  },
});
