import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ConfirmDialog } from "components";
import { LangConstant } from "const";
import { useState } from "react";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { SETTING_TIME } from "./setting-time.helper";

export const SettingTimeDialog = ({ onSubmitTime, onClose }) => {
  const classes = useStyles();
  const [settingTime, setSettingTime] = useState(null);

  const handleChangeTime = event => {
    setSettingTime(event.target.value);
  };

  const handleSubmitTime = () => {
    onSubmitTime(settingTime);
    setSettingTime(null);
  };

  const handleReset = () => {
    setSettingTime(null);
    onClose();
  };

  return (
    <ConfirmDialog
      open
      title={getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_TURN_OFF_NOTICE_TITLE")}
      content={
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={settingTime}
            onChange={handleChangeTime}
          >
            {SETTING_TIME.map(item => (
              <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
            ))}
          </RadioGroup>
        </FormControl>
      }
      submitProps={{
        submitText: getCommonLang("TXT_SAVE"),
        onClick: handleSubmitTime,
        className: classes.confirmBtn,
        disabled: settingTime === null,
        classes: { disabled: classes.disableBtn },
      }}
      cancelProps={{ onClick: handleReset }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  confirmBtn: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  disableBtn: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
}));
