import { LocalDbManagement } from "services/local.service";
import PubSubEvent from "./PubSubEvent";
import { KeyConstant, SystemConstant } from "const";
import { camelizeKeys } from "humps";
import { JsonObject } from "./PubSub.types";
import { subscribeRedisPubsub, unSubscribeRedisPubsub } from "../services/redisService";

declare global {
  interface Window {
    isStopSynchronize: boolean;
    electronUtils: {
      storage: JsonObject;
      common: JsonObject;
    };
    electronLibs: any;
    callingGroupDetail: any;
    electronEvent: any;
  }
}

const StorageUtil = window.electronUtils.storage;
const getPrefixKey = window.electronUtils.common.getPrefixKey;
class PubSubFactory {
  static instances: { [x: string]: PubSubEvent } = {};

  static initFromDB = async () => {
    try {
      const activeBranchList = await LocalDbManagement.find({ state: SystemConstant.STATE.active });

      activeBranchList.forEach((item: JsonObject) => {
        const branch = camelizeKeys<JsonObject>(item);
        const prefixKey = getPrefixKey(branch.accountId, branch.branchId);
        const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
        const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

        if (accountId && branchId) {
          // Start checking news for all active branches
          this.getEvent(prefixKey, branch.branchDomain).startCheckingNews();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Return pubsubEvent, init new object if not exist
  static getEvent(prefixKey: string, domain?: string): PubSubEvent {
    if (!this.instances[prefixKey]) {
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
      subscribeRedisPubsub(accountId, branchId);
      this.instances[prefixKey] = new PubSubEvent(prefixKey, domain);
    }

    return this.instances[prefixKey];
  }

  static destroyLogoutBranchThread = async () => {
    // Destroy all inactive branches
    const logoutBranchList = await LocalDbManagement.find({ state: SystemConstant.STATE.inactive });

    for (let index = 0; index < logoutBranchList.length; index++) {
      const item = logoutBranchList[index];
      const branch = camelizeKeys<JsonObject>(item);
      const prefixKey = getPrefixKey(branch.accountId, branch.branchId);

      if (this.instances[prefixKey]) {
        // Stop interval
        this.instances[prefixKey].destroyParallelThread();
        // Remove instance pubsub
        delete this.instances[prefixKey];
        unSubscribeRedisPubsub(branch.accountId, branch.branchId);
      }
    }
  };
}

export default PubSubFactory;
