import { call, put, select } from "redux-saga/effects";
import { remoteApiFactory } from "services";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import { AttachmentUtil, FileUtil, toCamel, toSnake, uuid } from "utils";
import { getInteractor } from "services/local.service";
import store, { ConversationActions, ConversationSelectors, GroupInfoActions, SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import {
  checkBlockedContact,
  createGroupService,
  deleteLocalConversationData,
  getConversation,
  synchPinList,
} from "pubsub/services/conversation.service";
import { tusUpload } from "services/multiple-file/tus-upload";
import { getSavedServer } from "utils/branch.utils";
import { getNSLang } from "utils/lang.utils";
import { ExecuteEvent } from "pubsub/PubSub.const";
import { groupSetting, threadSetting } from "pubsub/services/setting.service";
import { throwExceptionToast } from "./saga.helper";

export function* changeGroupPhoto(action) {
  try {
    const { data } = action;
    const uploadFile = data?.file;
    if (!uploadFile) {
      throwExceptionToast();
      return;
    }
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const attachmentId = uuid();

    const onError = error => {
      console.log("change group photo fail: ", error);
      throwExceptionToast();
    };

    const onSuccess = () => {
      remoteApiFactory.getBranchApi(prefixKey).updateConversation(
        toSnake({
          groupId: data.groupId,
          avatarId: attachmentId,
        }),
      );

      getInteractor(prefixKey).LocalGroupService.update({ avatar_id: attachmentId }, { id: data.groupId });

      store.dispatch(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            avatarId: attachmentId,
          },
        }),
      );
    };

    // Save file to local
    const fileName = `${attachmentId}.png`;
    // Compress image file before saving
    const compressedFile = yield FileUtil.compressImageFile(uploadFile);
    const savingPath = yield AttachmentUtil.saveFile2Local(attachmentId, compressedFile, data.groupId, {
      name: fileName,
      isPublic: true,
    });

    tusUpload(prefixKey, attachmentId, savingPath, SystemConstant.UPLOAD_TYPE.account, null, {
      onError,
      onSuccess,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* removeMemberGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let response = yield call(
      remoteApiFactory.getBranchApi(prefixKey).deleteConversation,
      toSnake({
        groupId: data.groupId,
        memberId: data.memberId,
      }),
    );
    if (response.status === ApiConstant.STT_OK) {
      yield getInteractor(prefixKey).LocalAccountGroupService.removeMemberGroup(data.groupId, data.memberId);
      const { groupMembers } = yield getInteractor(prefixKey).LocalGroupService.get(data.groupId);
      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            groupMembers: groupMembers,
            modified: new Date().getTime(),
          },
        }),
      );

      // Send message to remove member
      yield put(
        ConversationActions.sendMessage({
          groupId: data.groupId,
          sendType: SystemConstant.SEND_TYPE.leaveGroup,
          content: data.memberId,
          parentId: null,
          deviceList: [],
          branchId: getSavedServer().id,
          mentionIdsArr: [],
          threadId: null,
          removingId: data.memberId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addMemberGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).addConversationMembers, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      if (Array.isArray(response.data)) {
        const saveData = response.data.map(item => ({
          id: item.id,
          account_id: item.account_id,
          group_id: item.group_id,
          state: item.state,
          options: item.details ? item.details : null,
          created: item.created,
          modified: item.modified,
          invite_by: item.invite_by,
          type: item.type,
        }));

        yield getInteractor(prefixKey).LocalAccountGroupService.save(saveData);
      }

      const group = yield getInteractor(prefixKey).LocalGroupService.get(data.groupId);

      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            groupMembers: group.groupMembers,
            modified: new Date().getTime(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* muteGroupNotification(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    const selectGroup = yield getInteractor(prefixKey).LocalGroupSettingService.getByGroupId(data.groupId);
    let newSetting;

    if (selectGroup && selectGroup.id) {
      newSetting = {
        ...selectGroup,
        state: data.status,
      };
    } else {
      newSetting = {
        id: uuid(),
        groupId: data.groupId,
        settingId: SystemConstant.SETTING_TYPE.notification,
        status: SystemConstant.STATUS.active,
        state: data.status,
        options: "",
        created: Date.now(),
        modified: 0,
      };
    }

    yield getInteractor(prefixKey).LocalGroupSettingService.save([toSnake(newSetting)]);
    yield put(
      GroupInfoActions.groupInfoSuccess({
        updatingGroupData: {
          id: data.groupId,
          status: newSetting.status,
        },
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* addAdmin(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield getInteractor(prefixKey).LocalAccountGroupService.memberToAdmin(
        response.data.group_id,
        response.data.account_id,
      );
      yield put(
        GroupInfoActions.groupInfoSuccess({
          dataMemberToAdmin: toCamel(response).data,
        }),
      );
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* requestUploadImageCall(action) {
  try {
    const { data } = action;
    const uploadFile = data?.file;
    if (!uploadFile) {
      throwExceptionToast();
      return;
    }
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const attachmentId = uuid();

    const onError = error => {
      console.log("requestUploadImageCall: ", error);
      throwExceptionToast();
    };
    const onSuccess = () => {
      if (data.initCall) {
        store.dispatch(
          ConversationActions.conversationSet({
            idGroupCallAvatar: attachmentId,
          }),
        );
      } else {
        store.dispatch(
          ConversationActions.conversationSet({
            idAvatarEdit: attachmentId,
          }),
        );
      }
    };

    // Save file to local
    const fileName = `${attachmentId}.png`;
    // Compress image file before saving
    const compressedFile = yield FileUtil.compressImageFile(uploadFile);
    const savingPath = yield AttachmentUtil.saveFile2Local(attachmentId, compressedFile, data.groupId, {
      name: fileName,
      isPublic: true,
    });

    tusUpload(prefixKey, attachmentId, savingPath, SystemConstant.UPLOAD_TYPE.account, null, {
      onError,
      onSuccess,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* createGroup(action) {
  try {
    const prefixKey = action.prefixKey || StorageUtil.getCurrentPrefixKey();
    const newGroup = yield createGroupService(prefixKey, action.data);
    if (newGroup) {
      yield put(
        ConversationActions.conversationSet({
          demandingVoiceCall: Boolean(action.data.isDemandVoiceCall) ? newGroup.id : null,
          demandingVideoCall: Boolean(action.data.isDemandVideoCall) ? newGroup.id : null,
        }),
      );
      yield put(
        ConversationActions.setSelectGroupId({
          selectedGroupId: newGroup.id,
        }),
      );

      yield put(
        GroupInfoActions.groupInfoSet({
          newGroupId: newGroup.id,
        }),
      );
    } else {
      throwExceptionToast();
    }
  } catch (error) {
    console.log(error);
    throwExceptionToast();
  }
}

export function* deleteGroup(action) {
  try {
    const { data } = action;
    const { groupType, ...payload } = data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let isDeleteSuccess = false;
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).deleteConversation, toSnake(payload));
    if ([ApiConstant.STT_OK, ApiConstant.STT_NOT_FOUND].includes(response.status)) {
      isDeleteSuccess = true;
      yield deleteLocalConversationData(prefixKey, data.groupId);
      yield put(
        GroupInfoActions.groupInfoSuccess({
          deleteGroup: { groupId: data.groupId, modified: Date.now() },
        }),
      );

      const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
      if (selectedGroupId === data.groupId) {
        yield put(
          ConversationActions.setSelectGroupId({
            threadingId: null,
            selectedGroupId: null,
          }),
        );
      }
    } else {
      console.log("DELETE_GROUP_ERROR: ", response);
    }

    if (Boolean(data.all)) {
      const isChannel = groupType === SystemConstant.GROUP_CHAT_TYPE.channel;
      const toastContent = getDeleteToastContent(isDeleteSuccess, isChannel);

      yield put(
        SystemActions.systemSet({
          toast: {
            content: toastContent,
            severity: isDeleteSuccess ? "success" : "error",
            modified: Date.now(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateGroup(action) {
  try {
    const data = toSnake(action.data || {});
    const { group_name, ...payload } = data || {};
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const isUpdateGroupCategory = false === [undefined, null].includes(data.user_type);

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateConversation, payload);
    if (response.status === ApiConstant.STT_OK) {
      const { group_id, ...dataUpdate } = data;
      yield getInteractor(prefixKey).LocalGroupService.update(dataUpdate, { id: group_id });

      if (isUpdateGroupCategory) {
        yield put(
          GroupInfoActions.groupInfoSuccess({
            updateGroupCategory: {
              id: data.group_id,
              isReqUpdateFavorite: true,
              modified: Date.now(),
            },
          }),
        );

        yield put(
          SystemActions.systemSet({
            toast: {
              content:
                data.user_type === SystemConstant.GROUP_USER_TYPE.none
                  ? getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_REMOVE_FAVORITE_SUCCESS")
                  : getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_ADD_FAVORITE_SUCCESS"),
              severity: "success",
              modified: Date.now(),
            },
          }),
        );
      } else {
        yield put(
          GroupInfoActions.groupInfoSuccess({
            updatingGroupData: { id: group_id, ...toCamel(dataUpdate), modified: Date.now() },
          }),
        );
      }
    } else {
      if (isUpdateGroupCategory) {
        yield put(
          SystemActions.systemSet({
            toast: {
              content:
                data.user_type === SystemConstant.GROUP_USER_TYPE.none
                  ? getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_REMOVE_FAVORITE_FAIL")
                  : getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_ADD_FAVORITE_FAIL"),
              severity: "error",
              modified: Date.now(),
            },
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const handleSelectConversation = async action => {
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const { selectedGroupId } = action.data;
    if (!selectedGroupId) return; // Skip if selectedGroupId does not exist

    // Synch group info
    await getConversation(prefixKey, selectedGroupId);

    const selectedGroup = toCamel(await getInteractor(prefixKey).LocalGroupService.get(selectedGroupId));

    /**
     * If Boolean(group.forceSyncF) = true
     * Sync pin list, group setting, ...
     */
    if (Boolean(selectedGroup.forceSyncF)) {
      const syncPinResult = await synchPinList(prefixKey, selectedGroupId);
      const syncGroupSetting = await groupSetting(
        prefixKey,
        selectedGroupId,
        Object.values(SystemConstant.SETTING_SUB_TYPE),
      );
      if (syncPinResult === ExecuteEvent.SUCCESSES && syncGroupSetting === ExecuteEvent.SUCCESSES) {
        await getInteractor(prefixKey).LocalGroupService.update({ force_sync_f: 0 }, { id: selectedGroupId });
      }
    }

    /**
     * Sync setting for threads in group
     */
    const forceSyncThreads = await getInteractor(prefixKey).LocalThreadService.find({
      group_id: selectedGroupId,
      force_sync_f: 1,
      state: SystemConstant.STATE.active,
    });
    if (Array.isArray(forceSyncThreads) && forceSyncThreads.length > 0) {
      forceSyncThreads.forEach(thread => {
        threadSetting(prefixKey, thread.thread_id, [SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_THREAD]);
      });
    }

    const isChatWithPersonal =
      Array.isArray(selectedGroup.groupMembers) &&
      selectedGroup.groupMembers.length > 0 &&
      selectedGroup.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

    // Checking chatting person is block or not
    if (isChatWithPersonal && !selectedGroup.sendToMe) {
      await checkBlockedContact(
        prefixKey,
        selectedGroupId,
        selectedGroup.groupMembers.map(item => item.id),
      );
    }
  } catch (error) {
    console.log("handleSelectConversation", error);
  }
};

const getDeleteToastContent = (isSuccess, isChannel) => {
  switch (true) {
    case isSuccess && isChannel:
      return getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_CHANNEL_SUCCESS");

    case isSuccess && !isChannel:
      return getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_GROUP_SUCCESS");

    case !isSuccess && isChannel:
      return getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_CHANNEL_FAIL");

    case !isSuccess && !isChannel:
      return getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_GROUP_FAIL");

    default:
      return "";
  }
};
