import { makeStyles } from "@mui/styles";
import { ConfirmDialog } from "components";
import CustomTimePicker from "components/TimePickerScroll";
import { LangConstant } from "const";
import { useState } from "react";
import { getNSLang } from "utils/lang.utils";
import { convertTimeObj2Value, CUSTOM_TIME } from "./setting-message.helper";

const DEFAULT_OPTIONS_OBJ = {
  time: Array.from({ length: 59 }, (_, i) => i + 1).map(s => (s.toString().length === 1 ? "0" + s : s)),
  unit: ["Giây", "Phút", "Giờ", "Ngày", "Tuần"],
};
const DEFAULT_TIME_OBJ = { time: DEFAULT_OPTIONS_OBJ.time[0], unit: DEFAULT_OPTIONS_OBJ.unit[0] };

const CustomSettingTimeDialog = ({ open, onSubmit, onClose }) => {
  const classes = useStyles();

  const [optionsObj, setOptionsObj] = useState(DEFAULT_OPTIONS_OBJ);
  const [timeObj, setTimeObj] = useState(DEFAULT_TIME_OBJ);

  const handleSubmitTime = () => {
    onSubmit(convertTimeObj2Value(timeObj));
    onClose();
  };

  const handleChangeUnit = unit => {
    const customTimeObj = CUSTOM_TIME.find(item => item.label === unit);
    setOptionsObj(state => ({
      ...state,
      time: Array.from({ length: customTimeObj.maxValue }, (_, i) => i + 1).map(s =>
        s.toString().length === 1 ? "0" + s : s,
      ),
    }));
  };

  const handleChangeTimeObj = timeObj => {
    setTimeObj(timeObj);
  };

  return (
    <ConfirmDialog
      open={open}
      title={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CUSTOM_SETTING_TIME_TITLE")}
      content={
        <CustomTimePicker
          options={optionsObj}
          defaultValue={DEFAULT_TIME_OBJ}
          onChange={handleChangeTimeObj}
          onChangeUnit={handleChangeUnit}
        />
      }
      submitProps={{
        submitText: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_SELECT"),
        onClick: handleSubmitTime,
        className: classes.confirmBtn,
      }}
      cancelProps={{ onClick: onClose }}
    />
  );
};

export default CustomSettingTimeDialog;

const useStyles = makeStyles(theme => ({
  confirmBtn: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
