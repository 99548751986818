import {  WarningNote } from "components";
import { LangConstant } from "const";
import { getNSLang } from "utils/lang.utils";
import { convertTime2Label } from "./setting-time.helper";

const TurnOffNoticeNote = ({ time }) => {
  return (
    <WarningNote
      content={
        time === -1
          ? getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_TURN_OFF_NOTE")
          : getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "FM_TURN_OFF_IN_TIME", { time: convertTime2Label(time) })
      }
    />
  );
};

export default TurnOffNoticeNote;
