import { styled } from "@mui/styles";
import { useState } from "react";
import TimePicker from "./TimePicker";

const CustomTimePicker = ({ options, defaultValue, onChange, ...otherProps }) => {
  const [valueGroups, setValueGroups] = useState(defaultValue);

  // Update the value in response to user picking event
  const handleChange = (name, value) => {
    const newValue = { ...valueGroups, [name]: value };
    setValueGroups(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <CustomTimePickerComponent
      optionGroups={options}
      valueGroups={valueGroups}
      onChange={handleChange}
      {...otherProps}
    />
  );
};

export default CustomTimePicker;

const CustomTimePickerComponent = styled(TimePicker)({
  "&": {
    fontFamily: "Open Sans",
    fontSize: 12,
    ".picker-container .picker-column .picker-item.picker-item-selected": {
      color: "#3f3f46",
    },
    ".picker-container .picker-column .picker-item": {
      color: "#7d7d7d",
    },
    "& .picker-container .picker-inner": {
      padding: 0,
    },
    "& .picker-highlight": {
      color: "#212529",
      "&::before": {
        transform: "scaleY(1)",
        backgroundColor: "#d1d1d1",
      },
      "&::after": {
        transform: "scaleY(1)",
        backgroundColor: "#d1d1d1",
      },
    },
  },
});
