import { useTranslation } from "react-i18next";
import { ImageConstant, LangConstant, SystemConstant } from "const";
import {
  NoticeAvatar,
  NoticeContentText,
  NotificationContent,
  NotificationItem,
  NotificationTime,
  useNotificationStyles,
} from "./component";
import { makeStyles } from "@mui/styles";

const NotificationOTPItem = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const notificationStyles = useNotificationStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const isRead = data.status === SystemConstant.NOTIFICATION_STATUS.read;

  return (
    <NotificationItem className={isRead ? notificationStyles.readNotice : ""} {...otherProps}>
      <NoticeAvatar classes={{ img: classes.smartKeyIcon }} src={ImageConstant.ImageOTPKey} />
      <NotificationContent>
        <NoticeContentText
          className="regular-md-txt ellipsis"
          dangerouslySetInnerHTML={{
            __html: getLabel(LangConstant.FM_OTP_NOTIFICATION, {
              message: data.content,
            }),
          }}
        />
        <NotificationTime created={data.created} isUnread={!isRead} />
      </NotificationContent>
    </NotificationItem>
  );
};

export default NotificationOTPItem;

const useStyles = makeStyles({
  smartKeyIcon: {
    width: 20,
    height: 20,
  },
});
