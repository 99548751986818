import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNSLang } from "utils/lang.utils";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { convertString2JSON, StorageUtil } from "utils";
import { convertTimeLabel } from "../../GroupInfoDrawer/GroupSettingMessage/setting-message.helper";
import { useConversationContext } from "../ConversationContext";
import ChatTypography from "./ChatTypography";
import clsx from "clsx";

const DisappearMessageNotice = ({ message }) => {
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  const { groupDetail } = useConversationContext();

  const [content, setContent] = useState("");

  const handleDisappearMessage = message => {
    const msgContent = message.content ? convertString2JSON(message.content, {}) : {};
    const isTurnOnDisappear = msgContent.status === SystemConstant.STATE.active;
    const disappearTime = convertTimeLabel(Number(msgContent.disappear_time));
    const isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    let senderName = "";
    if (isPersonal) {
      senderName = message.senderId === accountId ? "Bạn" : message.senderName;
    } else {
      senderName = getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_ADMIN_MEMBER_LIST");
    }

    const content = getNSLang(
      LangConstant.NS_HOME_CONVERSATION,
      isTurnOnDisappear ? "FM_DISAPPEAR_MSG_TURN_ON" : "FM_DISAPPEAR_MSG_TURN_OFF",
      { senderName, disappearTime },
    );

    setContent(content);
  };

  useEffect(() => {
    handleDisappearMessage(message);
  }, [message]);

  return (
    <Box className={classes.root}>
      <ChatTypography className={clsx(classes.content, "ellipsis")} messageContent={content} messageId={message.id} />
    </Box>
  );
};

export default DisappearMessageNotice;

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  content: {
    maxWidth: "60%",
  },
});
