import PubSubFactory from "pubsub/PubSubFactory";
import { throwExceptionToast } from "./saga.helper";

export function* initPubsub() {
  yield PubSubFactory.initFromDB();
}

export function* destroyLogoutBranchThread() {
  yield PubSubFactory.destroyLogoutBranchThread();
}

export function* startCheckingNews(action) {
  const { prefixKey, domain } = action;

  if (prefixKey) yield PubSubFactory.getEvent(prefixKey, domain).startCheckingNews();
  else console.error("startCheckingNews - prefixKey is not defined");
}

export function* destroyParallelThread(action) {
  const { prefixKey } = action;

  if (prefixKey) yield PubSubFactory.getEvent(prefixKey).destroyParallelThread();
  else console.error("destroyParallelThread - prefixKey is not defined");
}
