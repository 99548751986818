import store, { ConversationActions } from "redux-store";
import { useEffect } from "react";
import { AppConstant } from "const";
import { useAlertContext } from "components/context/AlertContext";
import { getCommonLang } from "utils/lang.utils";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";

const HandleAppEvents = () => {
  const { showAlert } = useAlertContext();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  useEffect(() => {
    const handleUrlMetaData = (_, payload) => previewLinkMetaData(payload);
    const handleDownloadResult = (_, payload) => downloadResult(payload, showAlert);
    const handlePowerMonitor = () => handleSystemWakeUp(prefixKey);

    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.urlMetaData, handleUrlMetaData);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.downloadResult, handleDownloadResult);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.powerMonitor, handlePowerMonitor);

    return () => {
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.urlMetaData, handleUrlMetaData);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.downloadResult, handleDownloadResult);
    };
  }, []);
  return null;
};

export default HandleAppEvents;

const previewLinkMetaData = payload => {
  store.dispatch(
    ConversationActions.conversationSetLinkData({
      [payload.url]: {
        messageId: payload.messageId,
        metaData: payload.metaData,
      },
    }),
  );
};

const downloadResult = (payload, showAlert) => {
  const { count, total } = payload;

  showAlert({
    content: getCommonLang("FM_DOWNLOAD_FILES", { count, total }),
  });
};

const handleSystemWakeUp = prefixKey => {
  getInteractor(prefixKey)
    .LocalMessageService.getMinDisappearTime()
    .then(minDisappearTime => {
      if (minDisappearTime > 0) {
        store.dispatch(
          ConversationActions.conversationSet({
            disappearTime: minDisappearTime,
          }),
        );
      }
    });
};
