import { AppDrawer, IOSSwitch } from "components";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { getNSLang } from "utils/lang.utils";
import { NoPhotographyOutlined } from "@mui/icons-material";
import AutoDeleteMessageDrawer from "./AutoDeleteMessageDrawer";
import AccountItem from "../../Account/AccountItem";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { useConversationContext } from "../../MessengerChat/ConversationContext";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { ConversationSelectors, NotificationSettingActions, NotificationSettingSelectors } from "redux-store";
import { useDispatch, useSelector } from "react-redux";

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getSelectedGroupId, NotificationSettingSelectors.getUpdateGroupSetting],

  (selectedGroupId, updateGroupSetting) => {
    if (updateGroupSetting && updateGroupSetting.groupId === selectedGroupId) {
      return updateGroupSetting.updateList.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFY_CAPTURE_SCREEN,
      );
    }

    return null;
  },
);

const SettingMessageDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { t: getSettingMessage } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { groupDetail } = useConversationContext();

  const remoteCaptureSetting = useSelector(memoizedIncomingState);

  const [isTurnOnCapture, setIsTurnOnCapture] = useState(false);

  const handleSettingCapture = () => {
    const newSettingState = !isTurnOnCapture;
    dispatch(
      NotificationSettingActions.updateGroupSetting(
        {
          groupId: groupDetail.id,
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFY_CAPTURE_SCREEN,
          created: Date.now(),
          state: Number(newSettingState),
          isSettingCapture: true,
        },
        prefixKey,
      ),
    );
  };

  const handleInit = async () => {
    const localCaptureSetting = await getInteractor(prefixKey).LocalGroupSettingService.findOne({
      group_id: groupDetail.id,
      setting_sub_type: SystemConstant.SETTING_SUB_TYPE.NOTIFY_CAPTURE_SCREEN,
    });

    if (localCaptureSetting && localCaptureSetting.id) {
      setIsTurnOnCapture(Boolean(localCaptureSetting.state));
    } else {
      setIsTurnOnCapture(false);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    if (remoteCaptureSetting && remoteCaptureSetting.state !== isTurnOnCapture) {
      setIsTurnOnCapture(Boolean(remoteCaptureSetting.state));
    }
  }, [remoteCaptureSetting]);

  return (
    <AppDrawer open={open} onClose={onClose} title={getSettingMessage("TXT_MESSAGE_SETTING")} anchor="right">
      <AutoDeleteMessageDrawer />
      <AccountItem
        text={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CAPTURE_SCREEN_WARNING")}
        endIcon={<IOSSwitch checked={isTurnOnCapture} />}
        startIcon={<NoPhotographyOutlined />}
        onClick={handleSettingCapture}
      />
      ,
    </AppDrawer>
  );
};

export default SettingMessageDrawer;
