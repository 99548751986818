import React, { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles, styled } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ConversationActions, NotificationSettingSelectors } from "redux-store";
import { NotificationsOffOutlined } from "@mui/icons-material";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";

const ThreadFlow = ({ isMine, parentMessageId, messageThreadTotal, unreadMsgThreadTotal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  const remoteUpdateList = useSelector(NotificationSettingSelectors.getUpdateThreadSetting);

  const [isTurnOffNotice, setIsTurnOffNotice] = useState(false);

  const handleReplyMessage = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: parentMessageId,
      }),
    );
  };

  useEffect(() => {
    getInteractor()
      .LocalThreadSettingService.findOne({
        thread_id: parentMessageId,
        account_id: accountId,
      })
      .then(setting => {
        if (setting && setting.id) {
          setIsTurnOffNotice(setting.state === SystemConstant.STATE.inactive);
        } else {
          setIsTurnOffNotice(false);
        }
      });
  }, []);

  useEffect(() => {
    if (remoteUpdateList && remoteUpdateList.threadId === parentMessageId) {
      remoteUpdateList.updateList.forEach(setting => {
        if (setting.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_THREAD) {
          setIsTurnOffNotice(setting.state === SystemConstant.STATE.inactive);
        }
      });
    }
  }, [remoteUpdateList]);

  const hasUnreadMessage = unreadMsgThreadTotal > 0;

  if (!parentMessageId) return <Fragment />;
  return (
    <ReplyComponent
      className={clsx(isMine && classes.threadCountBoxMine, hasUnreadMessage && classes.unreadBox)}
      onClick={handleReplyMessage}
    >
      {hasUnreadMessage ? (
        <>
          <Box className={classes.newReplyCount}>+{unreadMsgThreadTotal}</Box>
          <Typography className={classes.threadCount}>{getLabel(LangConstant.FM_NEW_REPLY_COUNT)}</Typography>
        </>
      ) : (
        <Typography className={classes.threadCount}>
          {getLabel(LangConstant.FM_REPLY_COUNT, {
            count: messageThreadTotal,
          })}
        </Typography>
      )}
      {isTurnOffNotice && <NotificationOffIcon />}
    </ReplyComponent>
  );
};

export default memo(ThreadFlow);

const ReplyComponent = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: 12,
  padding: "0 4px",
  border: "1px solid white",
  backgroundColor: "#7F7F80",
  cursor: "pointer",
});

const NotificationOffIcon = styled(NotificationsOffOutlined)({
  color: "#ffffff",
  width: 16,
  height: 16,
});

const useStyles = makeStyles(theme => ({
  threadCountBoxMine: {
    backgroundColor: theme.palette.primary.main,
  },

  threadCount: {
    width: "max-content",
    fontSize: "0.9375rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    color: "#fff",
  },

  newReplyCount: {
    background: "red",
    borderRadius: "50%",
    width: 20,
    display: "flex",
    justifyContent: " center",
    marginRight: 5,
    color: "#fff",
  },

  unreadBox: {
    paddingLeft: 0,
  },
}));
