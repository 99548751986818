export default {
  TXT_SETTING_APP_NOTIFICATION: "Cài đặt thông báo",
  TXT_SETTING_CHANNEL_NOTICE: "Thông báo trong kênh",
  TXT_SETTING_GROUP_NOTICE: "Thông báo trong nhóm",
  TXT_NOTICE_NEW_MSG: "Báo tin nhắn mới",
  TXT_NOTICE_NEW_THREAD_MSG: "Báo tin nhắn mới trong luồng",
  TXT_NOTICE_ONLY_MENTION: "Chỉ nhận thông báo khi được nhắc tên @",
  TXT_TURN_OFF_NOTICE_TITLE: "Ngừng báo tin nhắn trong",
  TXT_UNTIL_TURN_ON: "Cho đến khi mở lại",
  TXT_TURN_ON_NOTICE_THREAD: "Bật thông báo luồng",
  TXT_TURN_OFF_NOTICE_THREAD: "Tắt thông báo luồng",
  TXT_TURN_OFF_NOTE: "<bold>Thông báo: </bold>bạn sẽ không nhận thông báo từ Trios cho đến khi mở lại.",
  TXT_SETTING_NOTICE_SUCCESS: "Cài đặt thông báo thành công",
  TXT_SETTING_NOTICE_FAIL: "Cài đặt thông báo không thành công",
  TXT_SETTING_MESSAGE_SUCCESS: "Cài đặt tin nhắn thành công",
  TXT_SETTING_MESSAGE_FAIL: "Cài đặt tin nhắn không thành công",

  FM_TURN_OFF_IN_TIME: "<bold>Thông báo: </bold>bạn sẽ không nhận thông báo từ Trios trong {{time}} tiếp theo.",
};
