import { convertString2JSON, StorageUtil } from "utils";
import { getInteractor } from "./local.service";
import { KeyConstant } from "const";

/**
 *
 * @param {*} prefixKey
 * @param {*} messageIds
 * @returns min disappear_time of updated message list in local db
 *
 * update disappear_time to local db
 */
export const updateDisappearTime = async (prefixKey, messageIds) => {
  try {
    const localMessages = await getInteractor(prefixKey).LocalMessageService.find({
      id: messageIds,
    });

    if (Array.isArray(localMessages) && localMessages.length > 0) {
      const disappearTimeList = [];
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      for (let index = 0; index < localMessages.length; index++) {
        const message = localMessages[index];

        const localSeen = await getInteractor(prefixKey).LocalSeenMemberService.findOne({
          source_id: message.source_id,
        });
        const messageOptions = message.options ? convertString2JSON(message.options, {}) : {};
        const { disappearing_f, time_to_live } = messageOptions;
        const isOtherMessage = message.sender_id !== accountId;
        if (
          Boolean(isOtherMessage) &&
          false === Boolean(message.disappear_time) &&
          Boolean(disappearing_f) &&
          Number(time_to_live) > 0
        ) {
          const disappearTime = Number(time_to_live) + (localSeen.seen_at || Date.now());
          await getInteractor(prefixKey).LocalMessageService.update(
            {
              disappear_time: disappearTime,
            },
            { id: message.id },
          );
          disappearTimeList.push(disappearTime);
        }
      }

      if (disappearTimeList.length > 0) return Math.min(...disappearTimeList);
    }
  } catch (error) {
    console.log("update message to read status error: ", error);
  }

  return 0;
};
