import { EventState, EventSubType, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { getInteractor } from "services/local.service";
import { EventDto, IEventDto } from "pubsub/dto";
import { refactorSavingEvent } from "pubsub/PubSub.helper";
import { accountSetting, groupSetting, threadSetting } from "pubsub/services/setting.service";

export default class SettingEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.SETTING);
  }

  override handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];
    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const eventContent = eventItem.contentObj;
      const groupId = eventContent.group_id;
      const threadId = eventContent.thread_id;
      const settingSubTypeList = eventContent.setting_sub_type_list;

      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;
      if (settingSubTypeList) {
        switch (eventItem.subtype) {
          case EventSubType.ACCOUNT_SETTING:
            executeEventValue = await accountSetting(this.prefixKey, settingSubTypeList);
            break;

          case EventSubType.GROUP_SETTING:
            executeEventValue = await groupSetting(this.prefixKey, groupId, settingSubTypeList);
            break;

          case EventSubType.THREAD_SETTING:
            executeEventValue = await threadSetting(this.prefixKey, threadId, settingSubTypeList);
            break;

          default:
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
