import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, PathConstant } from "const";
import { ConfirmDialog } from "components";
import { useDispatch } from "react-redux";
import { PubSubActions } from "redux-store";
import { getCommonLang } from "utils/lang.utils";
import { useDeviceList } from "hooks";
import ChooseDeviceDrawer from "./DeviceManagement/ChooseDeviceDrawer";
import { StorageUtil } from "utils";
import AccountItem from "./AccountItem";
import { useHistory } from "react-router-dom";
import { goToLoginPortal, handlingLogoutBranch, hasMoreLoggedInBranch } from "utils/auth.utils";
import { useAlertContext } from "components/context/AlertContext";
import { remoteApiFactory } from "services";

const LOGOUT_STEP = {
  none: "NOT_START",
  selectDevice: "SELECT_DEVICE",
  confirmLogout: "CONFIRM_LOGOUT",
};
const AccountLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { isMaster, slaveDeviceList } = useDeviceList();
  const { showAlert } = useAlertContext();

  const [logoutStep, setLogoutStep] = useState(LOGOUT_STEP.none);
  const [isPendingApi, setIsPendingApi] = useState(false);

  const handleShowConfirm = () => setLogoutStep(LOGOUT_STEP.confirmLogout);

  const handleChooseDevice = () => setLogoutStep(LOGOUT_STEP.selectDevice);

  const handleCancelLogout = () => setLogoutStep(LOGOUT_STEP.none);

  /**
   * Destroy pubsub
   * Call api to update device to server
   * If api success => logout local
   * Else => toast error, start pubsub
   */
  const handleLogout = async () => {
    dispatch(PubSubActions.destroyParallelThread(prefixKey));
    setIsPendingApi(true);
    const logoutRemote = await remoteApiFactory.getBranchApi(prefixKey).updateDevice({ slave_device_id: [deviceId] });
    if (logoutRemote.status === ApiConstant.STT_OK) {
      const isLogoutSuccess = await handlingLogoutBranch(prefixKey);
      if (isLogoutSuccess) {
        const hasMoreBranch = await hasMoreLoggedInBranch();
        if (hasMoreBranch) {
          history.replace(PathConstant.CHOOSE_BRANCH_PAGE);
        } else {
          goToLoginPortal(history);
        }
      } else {
        showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), alertProps: { severity: "error" } });
      }
    } else {
      const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);
      dispatch(PubSubActions.startCheckingNews(prefixKey, branchInfo.domain));
      showAlert({ content: getCommonLang("ERR_LOGOUT"), severity: "error" });
    }
    setIsPendingApi(false);
  };

  const hasSlave = slaveDeviceList.length > 0;
  const title = isMaster && hasSlave ? getCommonLang(LangConstant.TXT_CONFIRM_LOGOUT_MASTER) : accountObjContent.logout;
  const content = getCommonLang(
    isMaster
      ? hasSlave
        ? LangConstant.TXT_CONTENT_LOGOUT_MASTER
        : LangConstant.TXT_LOGOUT_MASTER_WITHOUT_SLAVE
      : LangConstant.TXT_CONTENT_LOGOUT_SLAVE,
  );

  const submitProps =
    isMaster && hasSlave
      ? {
          submitText: getCommonLang(LangConstant.TXT_CHOOSE_DEVICE),
          onClick: handleChooseDevice,
        }
      : {
          submitText: accountObjContent.logout,
          onClick: handleLogout,
        };

  return (
    <>
      <AccountItem text={accountObjContent.logout} onClick={handleShowConfirm} />

      {logoutStep === LOGOUT_STEP.confirmLogout && (
        <ConfirmDialog
          open
          title={title}
          content={content}
          submitProps={{ ...submitProps, disabled: isPendingApi, isLoading: isPendingApi }}
          cancelProps={{ onClick: handleCancelLogout, disabled: isPendingApi }}
        />
      )}

      {logoutStep === LOGOUT_STEP.selectDevice && (
        <ChooseDeviceDrawer open onClose={handleCancelLogout} onLogout={handleLogout} />
      )}
    </>
  );
};

AccountLogout.propTypes = {};

export default AccountLogout;
