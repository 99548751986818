import { ApiConstant, KeyConstant } from "const";
import { call, put } from "redux-saga/effects";
import { StorageUtil, toSnake } from "utils";
import { DeviceActions } from "redux-store";
import { remoteApiFactory } from "services";
import { checkCurrentBranchByPrefix } from "./saga.helper";
import { getDevice } from "pubsub/services/device.service";

export function* logoutDevice(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  yield put(
    DeviceActions.deviceSet({
      loggedOutDevice: [],
    }),
  );

  let errorReason;
  try {
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateDevice, toSnake(data));
    if (response.status === ApiConstant.STT_OK && Array.isArray(response.data)) {
      const loggedOutDevice = response.data;
      if (isCurrentBranch) {
        yield put(
          DeviceActions.deviceSuccess({
            loggedOutDevice: loggedOutDevice,
          }),
        );

        yield getDevice(prefixKey, currentAccountId, loggedOutDevice[0]);
      }
    } else {
      errorReason = response.error || response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (isCurrentBranch && errorReason) yield put(DeviceActions.deviceFailure(errorReason));
}
