import { KeyConstant, LangConstant, SystemConstant } from "const";
import { getLabel } from "language";
import {
  checkValidGlobalGroupSettingOff,
  checkValidGroupSettingOff,
} from "pages/HomePage/components/Account/SettingApp/SettingAppNotification/setting-time.helper";
import { ARR_NOTICE_NORMAL, ARR_NOTICE_SPECIAL } from "sagas/saga.helper";
import { getInteractor, LocalAppNotificationService } from "services/local.service";
import { convertString2JSON, removeDuplicateInArray, StorageUtil } from "utils";
import { getNSLang } from "utils/lang.utils";
import { replaceId2Name } from "utils/message.utils";
import { checkCurrentGroup, checkCurrentThread } from "utils/view.utils";

export const pushNotificationList = async (prefixKey, messageList) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  // Check global setting
  // If account setting notification for new msg is off => return false
  const globalSettings = await getInteractor(prefixKey).LocalAccountSettingService.find({
    account_id: accountId,
  });
  const globalMsgSetting = globalSettings.find(
    item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
  );
  const isValidTurnOffGlobalSetting = checkValidGlobalGroupSettingOff(prefixKey, globalMsgSetting);
  if (isValidTurnOffGlobalSetting) return;

  // Get global thread setting
  const globalThreadSetting =
    globalSettings.find(item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL_THREAD) ||
    {};
  const isTurnOffGlobalThreadNotice = globalThreadSetting.state === SystemConstant.STATE.inactive;

  // Get group setting notification
  const groupIds = removeDuplicateInArray(messageList.map(item => item.groupId));
  const settingGroups = await getGroupSetting(prefixKey, groupIds, accountId);

  // Get Thread setting
  const threadIds = removeDuplicateInArray(messageList.map(item => item.threadId));
  const settingThreads = await getThreadSetting(prefixKey, threadIds, accountId);
  messageList.forEach(newMessage => {
    const settingGroup = settingGroups[newMessage.groupId];
    const settingThread = settingThreads[newMessage.threadId] || {};

    const isEditMessage =
      SystemConstant.EDITABLE_SEND_TYPE.includes(newMessage.sendType) && true === Boolean(newMessage.parentId);
    const isNotifyThreadMsg = checkNotifyThreadMsg(
      prefixKey,
      newMessage,
      settingThread,
      settingGroup,
      isTurnOffGlobalThreadNotice,
    );
    const isNotifyMsg = checkNotifyGroupMsg(prefixKey, newMessage, settingGroup);
    if (false === isEditMessage && (isNotifyMsg || isNotifyThreadMsg)) {
      handleShowPushNotification(prefixKey, newMessage);
    }
  });
};

const handleShowPushNotification = async (prefixKey, newMessage) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const messageSourceId = newMessage.parentId || newMessage.sourceId;
  const groupDetails = await getInteractor(prefixKey).LocalGroupService.get(newMessage.groupId);
  const groupName = groupDetails.groupName;
  const groupMembers = groupDetails.groupMembers;
  const senderName = (groupMembers.find(item => item.id === newMessage.senderId) || {}).name;

  if (ARR_NOTICE_NORMAL.includes(newMessage.sendType) && groupMembers.length > 0) {
    const msgContent = await replaceId2Name(newMessage.content, groupMembers, false);
    LocalAppNotificationService.showNotification(groupName, {
      content: `${senderName}: ${msgContent}`,
      groupId: newMessage.groupId,
      threadId: newMessage.threadId,
      messageSourceId,
      prefixKey,
    });
  } else if (ARR_NOTICE_SPECIAL.includes(newMessage.sendType)) {
    const multimedia = getLabel(LangConstant.OBJ_SEND_MES_TYPE, { returnObjects: true });
    LocalAppNotificationService.showNotification(groupName, {
      content: `${senderName}: ${multimedia[newMessage.sendType]}`,
      groupId: newMessage.groupId,
      threadId: newMessage.threadId,
      messageSourceId,
      prefixKey,
    });
  } else if (
    SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType) &&
    newMessage.callStatus === SystemConstant.MESSAGE_CALL_STATUS.missed
  ) {
    LocalAppNotificationService.showNotification(groupName, {
      content: getNSLang(LangConstant.NS_CALLING, LangConstant.FM_MISSED_CALL, {
        first: "Bạn",
        second: senderName,
      }),
      groupId: newMessage.groupId,
      prefixKey,
    });
  } else if (newMessage.sendType === SystemConstant.SEND_TYPE.pinMessage) {
    const messageContent = newMessage.content ? convertString2JSON(newMessage.content, {}) : {};
    const isPin = messageContent.pin_type === SystemConstant.PIN_TYPE.pin;
    const displayContent = getNSLang(LangConstant.NS_HOME_CONVERSATION, isPin ? "FM_PIN_MESSAGE" : "FM_UNPIN_MESSAGE", {
      senderName: newMessage.senderId === accountId ? "Bạn" : senderName,
      message: "",
    });
    LocalAppNotificationService.showNotification(groupName, {
      content: displayContent,
      groupId: newMessage.groupId,
      prefixKey,
    });
  }
};

const getGroupSetting = async (prefixKey, groupIds, accountId) => {
  const settingGroups = {};
  const groupSettingList = await getInteractor(prefixKey).LocalGroupSettingService.find({
    account_id: accountId,
    group_id: groupIds,
  });
  groupIds.forEach(groupId => {
    const groupSettings = groupSettingList.filter(item => item.group_id === groupId);
    if (groupSettings.length > 0) {
      const settings = {};
      const groupSetting = groupSettings.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
      );
      settings.isTurnOffNotice = checkValidGroupSettingOff(prefixKey, groupSetting, groupId);

      const mentionSetting = groupSettings.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_MENTION,
      );
      settings.isOnlyMention = mentionSetting ? Boolean(mentionSetting.state) : false;

      const threadGroupSetting =
        groupSettings.find(
          item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_THREAD,
        ) || {};
      settings.isTurnOffThreadNotice = threadGroupSetting.state === SystemConstant.STATE.inactive;

      settingGroups[groupId] = settings;
    } else {
      settingGroups[groupId] = {
        isTurnOffNotice: false,
        isOnlyMention: false,
        isTurnOffThreadNotice: false,
      };
    }
  });

  return settingGroups;
};

const getThreadSetting = async (prefixKey, threadIds, accountId) => {
  const settingThreads = {};
  const threadSettingList = await getInteractor(prefixKey).LocalThreadSettingService.find({
    account_id: accountId,
    thread_id: threadIds,
  });
  threadIds.forEach(threadId => {
    const threadSetting = threadSettingList.find(item => item.thread_id === threadId) || {};
    settingThreads[threadId] = {
      isTurnOffNotice: threadSetting.state === SystemConstant.STATE.inactive,
    };
  });

  return settingThreads;
};

const checkNotifyGroupMsg = (prefixKey, message, settingGroup) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const isNotCurrentGroup = false === checkCurrentGroup(message.groupId);
  const isNotMimeMsg = message.senderId !== accountId;
  const isNotThreadMsg = false === Boolean(message.threadId);

  const mentionList = message.mentions ? JSON.parse(message.mentions) : [];
  const isMentionMe = mentionList.includes(accountId) || mentionList.includes(`@{${SystemConstant.MENTION_ALL.id}}`);

  const isNoticeGroupMsg =
    isNotCurrentGroup && isNotThreadMsg && isNotMimeMsg && settingGroup.isTurnOffNotice === false;

  if (settingGroup.isOnlyMention) {
    return isNoticeGroupMsg && isMentionMe;
  } else {
    return isNoticeGroupMsg;
  }
};

const checkNotifyThreadMsg = (prefixKey, message, settingThread, settingGroup, isTurnOffGlobalThreadNotice) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  if (isTurnOffGlobalThreadNotice || settingGroup.isTurnOffNotice) return false;

  const isThreadMsg = Boolean(message.threadId);
  const isNotCurrentThread = false === checkCurrentThread(message.threadId);
  const isNotMimeMsg = message.senderId !== accountId;

  const isValidShowNotification = isThreadMsg && isNotCurrentThread && isNotMimeMsg;

  // Checking notification only mention
  // If setting mention notification is on => return true
  const mentionList = message.mentions ? JSON.parse(message.mentions) : [];
  const isMentionMe = mentionList.includes(accountId) || mentionList.includes(`@{${SystemConstant.MENTION_ALL.id}}`);
  if (settingGroup.isOnlyMention && isMentionMe && isValidShowNotification) return true;

  // Checking notification group new msg in thread
  // if settingOff is valid => return false
  if (settingGroup.isTurnOffThreadNotice) return false;

  // Checking setting thread notification
  return (
    false === Boolean(settingGroup.isOnlyMention) &&
    isValidShowNotification &&
    false === Boolean(settingThread.isTurnOffNotice)
  );
};
