// Copyright 2021 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import React from "react";
import type { ConversationType } from "../state/ducks/conversations";
import type { CallingConversationType } from "../types/Calling";
import type { LocalizerType } from "../types/Util";
// import { Avatar, AvatarSize } from "./Avatar";
// import { getParticipantName } from "../util/callingGetParticipantName";
// import { missingCaseError } from "../util/missingCaseError";
import { UserText } from "./UserText";
import { AvatarGroup } from "components";
import { useSelector } from "react-redux";

const missingCaseError = window.electronLibs.libs.missingCaseError;

export enum RingMode {
  WillNotRing,
  WillRing,
  IsRinging,
  IsConnecting,
}

export type PropsType = {
  conversation: Pick<
    CallingConversationType,
    | "acceptedMessageRequest"
    | "avatarUrl"
    | "color"
    | "isMe"
    | "phoneNumber"
    | "profileName"
    | "sharedGroupNames"
    | "systemGivenName"
    | "systemNickname"
    | "title"
    | "type"
    | "unblurredAvatarUrl"
  >;
  i18n: LocalizerType;
  me: Pick<ConversationType, "id" | "serviceId">;
  ringMode: RingMode;

  // The following should only be set for group conversations.
  groupMembers?: Array<Pick<ConversationType, "id" | "firstName" | "systemGivenName" | "systemNickname" | "title">>;
  isCallFull?: boolean;
  peekedParticipants?: Array<
    Pick<ConversationType, "firstName" | "systemGivenName" | "systemNickname" | "title" | "serviceId">
  >;
  title?: string;
};

export function CallingPreCallInfo({
  conversation,
  i18n,
  isCallFull = false,
  me,
  peekedParticipants = [],
  ringMode,
  title,
}: PropsType): JSX.Element {
  const callingGroupDetailRing = useSelector((state: any) => state.callingRedux.callingGroupDetailRing);
  let subtitle: string;
  if (ringMode === RingMode.IsRinging) {
    subtitle = i18n.t("outgoingCallRinging");
  } else if (ringMode === RingMode.IsConnecting) {
    subtitle = i18n.t("settingUpSecureCall");
  } else if (isCallFull) {
    subtitle = i18n.t("calling__call-is-full");
  } else if (peekedParticipants.length) {
    // It should be rare to see yourself in this list, but it's possible if (1) you rejoin
    //   quickly, causing the server to return stale state (2) you have joined on another
    //   device.
    let hasYou = false;
    const participantNames = [conversation?.title];

    switch (participantNames.length) {
      case 1:
        subtitle = hasYou
          ? i18n.t("calling__pre-call-info--another-device-in-call")
          : i18n.t("calling__pre-call-info--1-person-in-call");
        break;
      case 2:
        subtitle = i18n.t("calling__pre-call-info--2-people-in-call");
        break;
      case 3:
        subtitle = i18n.t("calling__pre-call-info--3-people-in-call");
        break;
      default:
        subtitle = i18n.t("calling__pre-call-info--many-people-in-call");
        break;
    }
  } else {
    let memberNames: Array<string>;
    switch (conversation.type) {
      case "direct":
        memberNames = [conversation?.title];
        break;
      default:
        throw missingCaseError(conversation.type);
    }

    const ring = ringMode === RingMode.WillRing;

    switch (memberNames.length) {
      case 0:
        subtitle = i18n.t("calling__pre-call-info--empty-group");
        break;
      case 1: {
        subtitle = ring
          ? i18n.t("calling__pre-call-info--will-ring-1")
          : i18n.t("calling__pre-call-info--will-notify-1");
        break;
      }
      case 2: {
        subtitle = ring
          ? i18n.t("calling__pre-call-info--will-ring-2")
          : i18n.t("calling__pre-call-info--will-notify-2");
        break;
      }
      case 3: {
        subtitle = ring
          ? i18n.t("calling__pre-call-info--will-ring-3")
          : i18n.t("calling__pre-call-info--will-notify-3");
        break;
      }
      default: {
        subtitle = ring
          ? i18n.t("calling__pre-call-info--will-ring-many")
          : i18n.t("calling__pre-call-info--will-notify-many");
        break;
      }
    }
  }

  return (
    <div className="module-CallingPreCallInfo">
      <div className="module-Avatar">
        <AvatarGroup
          avatarId={callingGroupDetailRing?.avatarId}
          prefixKey={callingGroupDetailRing?.prefixKey}
          group={{
            groupType: callingGroupDetailRing?.groupType,
            groupName: callingGroupDetailRing?.groupName,
            privateF: callingGroupDetailRing?.privateF,
          }}
          size={64}
        />
      </div>
      <div className="module-CallingPreCallInfo__title">
        <UserText text={conversation.title} />
      </div>
      <div className="module-CallingPreCallInfo__subtitle">{title ?? subtitle}</div>
    </div>
  );
}
