import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { ConversationActions, ConversationSelectors } from "redux-store";
import { createSelector } from "reselect";

const memoizedDisappearList = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    state => state.conversationRedux.disappearMsg,
  ],
  (selectedGroupId, selectedThreadId, disappearMsg) => {
    let disappearMsgIdsRedux = [];
    if (selectedThreadId) {
      disappearMsgIdsRedux = disappearMsg.filter(item => item.thread_id === selectedThreadId).map(item => item.id);
    } else if (selectedGroupId) {
      disappearMsgIdsRedux = disappearMsg.filter(item => item.group_id === selectedGroupId).map(item => item.id);
    }

    return { disappearMsgIdsRedux };
  },
);

const useDisappearMessage = ({ onCallback }) => {
  const { disappearMsgIdsRedux } = useSelector(memoizedDisappearList);
  const disappearListIdRef = useRef([]);

  const handleSetDisappearListId = listId => {
    const currentList = disappearListIdRef.current;
    disappearListIdRef.current = currentList.concat(listId);
  };

  useEffect(() => {
    if (Array.isArray(disappearMsgIdsRedux) && disappearMsgIdsRedux.length > 0) {
      handleSetDisappearListId(disappearMsgIdsRedux);
      onCallback(disappearMsgIdsRedux);
    } else {
      disappearListIdRef.current = [];
    }
  }, [disappearMsgIdsRedux]);

  return { disappearListIdRef };
};

export default useDisappearMessage;
