import { ApiConstant } from "const";
import { remoteApiFactory } from "./remote-api/RemoteApiFactory";
import { getInteractor } from "./local.service";
import { formatPagingParams } from "sagas/saga.helper";
import { toSnake } from "utils";

export const updateAccountNotiSetting = async (prefixKey, setting_sub_type, created, state, value) => {
  const payload = { setting_sub_type, created, state };
  if (value && value > 0) payload.value = value;

  const response = await remoteApiFactory.getBranchApi(prefixKey).updateAccountNotiSetting(payload);
  if (response.status === ApiConstant.STT_OK) {
    const responseData = response.data;
    await getInteractor(prefixKey).LocalAccountSettingService.save([responseData]);

    return responseData;
  }

  return null;
};

export const getAccountNotiSetting = async (prefixKey, settingSubType) => {
  const paging = 1;
  let offset = 0;
  let hasMore = true;
  let remoteData = [];

  try {
    do {
      const payload = formatPagingParams({ paging, offset, settingSubType });
      const response = await remoteApiFactory.getBranchApi(prefixKey).getAccountNotiSetting(payload);
      if (response.status === ApiConstant.STT_OK) {
        const responseData = response.data.data;
        await getInteractor(prefixKey).LocalAccountSettingService.save(responseData);
        remoteData = remoteData.concat(responseData);
        hasMore = response.data.number_of_elements === payload.limit;
        offset = offset + responseData.length;
      } else {
        hasMore = false;
      }
    } while (hasMore);
  } catch (error) {
    console.log("get remote account setting error: ", error);
  }

  return remoteData;
};

export const updateGroupNotiSetting = async (prefixKey, groupId, settingSubType, created, state, value) => {
  const payload = { groupId, settingSubType, created, state };
  if (value && value > 0) payload.value = value;
  const response = await remoteApiFactory.getBranchApi(prefixKey).updateGroupNotiSetting(toSnake(payload));
  if (response.status === ApiConstant.STT_OK) {
    const responseData = response.data;
    await getInteractor(prefixKey).LocalGroupSettingService.save([responseData]);

    return responseData;
  }

  return null;
};

export const getGroupNotiSetting = async (prefixKey, settingSubType, groupId = null) => {
  const paging = 1;
  let offset = 0;
  let hasMore = true;
  let remoteData = [];

  try {
    do {
      const payload = formatPagingParams({ paging, offset, settingSubType, groupId });
      const response = await remoteApiFactory.getBranchApi(prefixKey).getGroupNotiSetting(payload);
      if (response.status === ApiConstant.STT_OK) {
        const responseData = response.data.data;
        await getInteractor(prefixKey).LocalGroupSettingService.save(responseData);
        remoteData = remoteData.concat(responseData);
        hasMore = response.data.number_of_elements === payload.limit;
        offset = offset + responseData.length;
      } else {
        hasMore = false;
      }
    } while (hasMore);
  } catch (error) {
    console.log("get remote account setting error: ", error);
  }

  return remoteData;
};

export const updateThreadNotiSetting = async (prefixKey, threadId, settingSubType, created, state, value) => {
  const payload = { threadId, settingSubType, created, state };
  if (value && value > 0) payload.value = value;
  const response = await remoteApiFactory.getBranchApi(prefixKey).updateThreadNotiSetting(toSnake(payload));
  if (response.status === ApiConstant.STT_OK) {
    const responseData = response.data;
    await getInteractor(prefixKey).LocalThreadSettingService.save([responseData]);

    return responseData;
  }

  return null;
};

export const getThreadNotiSetting = async (prefixKey, settingSubType, threadId = null) => {
  const paging = 1;
  let offset = 0;
  let hasMore = true;
  let remoteData = [];

  try {
    do {
      const payload = formatPagingParams({ paging, offset, settingSubType, threadId });
      const response = await remoteApiFactory.getBranchApi(prefixKey).getThreadNotiSetting(payload);
      if (response.status === ApiConstant.STT_OK) {
        const responseData = response.data.data;
        await getInteractor(prefixKey).LocalThreadSettingService.save(responseData);
        remoteData = remoteData.concat(responseData);
        hasMore = response.data.number_of_elements === payload.limit;
        offset = offset + responseData.length;
      } else {
        hasMore = false;
      }
    } while (hasMore);
  } catch (error) {
    console.log("get remote account setting error: ", error);
    return null;
  }

  return remoteData;
};
