import { ExecuteEvent } from "pubsub/PubSub.const";
import store, { NotificationSettingActions } from "redux-store";
import { getInteractor } from "services/local.service";
import {
  getAccountNotiSetting,
  getGroupNotiSetting,
  getThreadNotiSetting,
} from "services/notification-setting.service";

export const accountSetting = async (prefixKey, settingSubTypeList) => {
  try {
    const remoteResponse = await getAccountNotiSetting(prefixKey, settingSubTypeList);

    if (Array.isArray(remoteResponse) && remoteResponse.length > 0) {
      store.dispatch(
        NotificationSettingActions.notificationSettingSet({
          updateAccountSetting: remoteResponse,
        }),
      );
    }

    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("account setting event fail: ", error);
  }

  return ExecuteEvent.FAIL;
};

export const groupSetting = async (prefixKey, groupId, settingSubTypeList) => {
  try {
    const remoteResponse = await getGroupNotiSetting(prefixKey, settingSubTypeList, groupId);

    if (Array.isArray(remoteResponse) && remoteResponse.length > 0) {
      store.dispatch(
        NotificationSettingActions.notificationSettingSet({
          updateGroupSetting: { groupId, updateList: remoteResponse },
        }),
      );
    }

    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("group setting event fail: ", error);
  }
  await getInteractor(prefixKey).LocalGroupService.update({ force_sync_f: 1 }, { id: groupId });
  return ExecuteEvent.FAIL;
};

export const threadSetting = async (prefixKey, threadId, settingSubTypeList) => {
  try {
    const remoteResponse = await getThreadNotiSetting(prefixKey, settingSubTypeList, threadId);

    if (Array.isArray(remoteResponse) && remoteResponse.length > 0) {
      store.dispatch(
        NotificationSettingActions.notificationSettingSet({
          updateThreadSetting: { threadId, updateList: remoteResponse },
        }),
      );
    }
    await getInteractor(prefixKey).LocalThreadService.update({ force_sync_f: 0 }, { thread_id: threadId });
    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("thread setting event fail: ", error);
  }

  await getInteractor(prefixKey).LocalThreadService.update({ force_sync_f: 1 }, { thread_id: threadId });
  return ExecuteEvent.FAIL;
};
