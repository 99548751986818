import { KeyConstant, SystemConstant } from "const";
import { useLazyEffect } from "hooks";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { updateGroupNotiSetting } from "services/notification-setting.service";
import { StorageUtil } from "utils";

const MigrateVersion56 = () => {
  const selectedBranchReduxId = useSelector(BranchSelectors.getSelectedBranchId);
  const currentPrefixKey = StorageUtil.getCurrentPrefixKey();

  const handleMigrateGroupSetting = async prefixKey => {
    try {
      // Check current version
      const dbVersion = await getInteractor(prefixKey).LocalDBVersionService.getMaxVersion();
      if (dbVersion <= 56) {
        return false; // Skip if using old version
      }

      const oldGroupSetting = await getInteractor(prefixKey).LocalGroupSettingService.getOldGroupSetting_v56();
      const deleteOldSettingIds = [];
      if (Array.isArray(oldGroupSetting) && oldGroupSetting.length > 0) {
        for (let index = 0; index < oldGroupSetting.length; index++) {
          const oldSetting = oldGroupSetting[index];
          const remoteUpdate = await updateGroupNotiSetting(
            prefixKey,
            oldSetting.group_id,
            SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
            oldSetting.created,
            oldSetting.state,
          );

          if (remoteUpdate) {
            deleteOldSettingIds.push(oldSetting.id);
          }
        }

        await getInteractor(prefixKey).LocalGroupSettingService.deleteOldGroupSetting_v56(deleteOldSettingIds);
        if (deleteOldSettingIds.length === oldGroupSetting.length) {
          StorageUtil.setItem(KeyConstant.KEY_MIGRATE_VERSION_56, 1, prefixKey);
          await getInteractor(prefixKey).LocalGroupSettingService.dropOldGroupSettingTable();
        }
      } else {
        StorageUtil.setItem(KeyConstant.KEY_MIGRATE_VERSION_56, 1, prefixKey);
        await getInteractor(prefixKey).LocalGroupSettingService.dropOldGroupSettingTable();
      }
    } catch (error) {
      console.log("migrate version 56 error: ", error);
    }
  };

  useLazyEffect(
    () => {
      const isMigrateVersion56 = StorageUtil.getItem(KeyConstant.KEY_MIGRATE_VERSION_56, currentPrefixKey);
      if (!Boolean(isMigrateVersion56)) {
        handleMigrateGroupSetting(currentPrefixKey);
      }
    },
    [selectedBranchReduxId, currentPrefixKey],
    100,
  );

  return null;
};

export default MigrateVersion56;
