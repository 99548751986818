import PropTypes from "prop-types";
import { Avatar, Box } from "@mui/material";
import { getFirstLetter } from "utils";
import { useFileUrl } from "hooks";
import { makeStyles, styled } from "@mui/styles";
import clsx from "clsx";

const AppAvatar = ({
  name,
  size = 50,
  avatarId,
  children,
  BadgeIcon,
  sx,
  className,
  isServerLogo = false,
  backgroundColor,
  prefixKey = "",
  ...otherProps
}) => {
  const defaultClasses = useStyles();
  const firstLetter = getFirstLetter(name) || "T";

  const { fileUrl } = useFileUrl(avatarId, prefixKey);

  return (
    <Box position="relative">
      <Avatar
        loading="lazy"
        alt={name}
        sx={{ width: size, height: size, backgroundColor: backgroundColor || "#bebebe", ...sx }}
        src={avatarId && fileUrl}
        className={clsx(isServerLogo && defaultClasses.serverLogo, className)}
        {...otherProps}
      >
        {children || firstLetter}
      </Avatar>
      {BadgeIcon && <AvatarBadge>{BadgeIcon}</AvatarBadge>}
    </Box>
  );
};

AppAvatar.propTypes = {
  name: PropTypes.string,
  avatarId: PropTypes.string,
  size: PropTypes.number,
  prefixKey: PropTypes.string,
};

export default AppAvatar;

const AvatarBadge = styled(Box)({
  position: "absolute",
  bottom: -4,
  right: 0,
});

const useStyles = makeStyles({
  serverLogo: {
    backgroundColor: "transparent",

    "& img": {
      objectFit: "contain",
      width: "80%",
      height: "80%",
    },
  },
});
