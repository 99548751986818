import { FormatConstant, KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { convertString2JSON, StorageUtil, toCamel } from "utils";
import { findLastIndex, sortBy, uniqBy, max } from "lodash";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { convertMillisecondsToDate } from "utils/date.utils";
import store, { ConversationActions } from "redux-store";

export const PERSONAL_DISPLAY_CALL_STATUS = [
  SystemConstant.MESSAGE_CALL_STATUS.end,
  SystemConstant.MESSAGE_CALL_STATUS.missed,
  SystemConstant.MESSAGE_CALL_STATUS.reject,
];

export const handleNewMessageList = async (newMessageList, currentMsgList, selectedGroupId) => {
  const isValid = Array.isArray(newMessageList) && newMessageList.length > 0;
  if (!isValid) return; // Do nothing if data is invalid

  let categoryId; // categoryId is key to get message list in group/ thread
  let handlingMessages = [...currentMsgList];
  let messageIds = [];
  const sortNewMessageList = sortBy(newMessageList, "created");

  for (let index = 0; index < sortNewMessageList.length; index++) {
    const newMessage = sortNewMessageList[index];
    handlingMessages = await handleNewMessage(newMessage, handlingMessages, selectedGroupId);

    if (!categoryId) {
      categoryId = newMessage.threadId || newMessage.groupId;
    }
    // Remove message in redux if it sent to server
    if (newMessage.modified) {
      messageIds.push(newMessage.id);
    }
  }

  // Remove all handling message in redux
  store.dispatch(ConversationActions.clearNewMessage(categoryId, messageIds));

  return uniqBy(sortBy(handlingMessages, "created").reverse(), "id");
};

/**
 *
 * @param {JSON} newMessage: Incoming message that includes local message or remote message
 * @param {Array<JSON>} messageList: this contains calling message and others.
 * 1. About calling message, it contains origin message and children message.
 * 2. Others, it just only be latest message
 * @param {string} selectedGroupId: Selected group Id
 */
const handleNewMessage = async (newMessage, messageList, selectedGroupId) => {
  const isValid = newMessage && newMessage.id && selectedGroupId;
  if (!isValid) return; // Do nothing if data is invalid

  let newMessageList = [...messageList];
  const existedIndex = newMessageList.findIndex(
    item => newMessage.id === item.id || newMessage.sourceId === item.sourceId,
  );
  const existedParentIndex = newMessageList.findIndex(item => item.sourceId === newMessage.parentId);
  const existedParentThreadIndex = newMessageList.findIndex(item => item.sourceId === newMessage.threadId);

  let oldMessage = newMessageList[existedIndex];
  switch (true) {
    case existedIndex >= 0:
      // 1. Overwrite message in view list or delete local db
      if (isDeletedMessage(newMessage)) {
        newMessageList[existedIndex] = {
          ...oldMessage,
          ...newMessage,
          isDeleted: true,
        };
      } else if (
        max([newMessage.created, newMessage.modified, 0]) > max([oldMessage.created, oldMessage.modified, 0])
      ) {
        newMessageList[existedIndex] = {
          ...oldMessage,
          ...newMessage,
        };
      }
      break;

    case existedParentIndex >= 0:
      // 2. New message is child message: reaction, edit, delete, calling .v.v
      oldMessage = newMessageList[existedParentIndex];
      if (SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType)) {
        newMessageList = await getDisplayMessage(newMessageList.concat(newMessage), selectedGroupId);
      } else if (isDeletedMessage(newMessage)) {
        newMessageList[existedParentIndex] = {
          ...oldMessage,
          isDeleted: true,
        };
      } else if (
        max([newMessage.created, newMessage.modified, 0]) > max([oldMessage.created, oldMessage.modified, 0])
      ) {
        newMessageList[existedParentIndex] = {
          ...oldMessage,
          modified: newMessage.modified || newMessage.created,
        };
      }

      break;

    case existedParentThreadIndex >= 0:
      oldMessage = newMessageList[existedParentThreadIndex];
      newMessageList[existedParentThreadIndex] = {
        ...oldMessage,
        modified: newMessage.modified || newMessage.created,
      };
      break;

    case SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType):
      // 3.2 Add new calling message
      newMessageList = await getDisplayMessage(newMessageList.concat(newMessage), selectedGroupId);
      break;

    case ![...SystemConstant.ARR_CALLING_TYPES, SystemConstant.SEND_TYPE.reaction].includes(newMessage.sendType) &&
      false === Boolean(newMessage.parentId):
      // 3.2 Add new message
      const displayMsgItem = await refactorMessage(newMessage, newMessageList[0]);
      newMessageList.unshift(displayMsgItem);
      break;

    default:
      break;
  }

  return newMessageList;
};

export const getDisplayMessage = async (messages, selectedGroupId) => {
  // Skip if data is invalid
  if (!Boolean(selectedGroupId)) return [];

  const groupDetail = await getInteractor().LocalGroupService.get(selectedGroupId);
  const { callingMessageList, otherMessages } = messages.reduce(
    (accumulator, messageItem) => {
      const isCalling = SystemConstant.ARR_CALLING_TYPES.includes(messageItem.sendType);
      if (isCalling) {
        accumulator.callingMessageList.push(messageItem);
      } else {
        accumulator.otherMessages.push(messageItem);
      }

      return accumulator;
    },
    { callingMessageList: [], otherMessages: [] },
  );

  // Calling messages
  const callingList =
    callingMessageList.length > 0
      ? await getDisplayCallingMsg(
          callingMessageList,
          groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal,
        )
      : [];

  // Remove duplicate
  let showingList = uniqBy(otherMessages.concat(callingList), "id");
  // Sort by created
  showingList = sortBy(showingList, "created").reverse();

  const refactorList = await refactorMessageList(showingList);
  return refactorList;
};

const getDisplayCallingMsg = async (callingMessages, isPersonal) => {
  let displayCallingList = [];
  try {
    if (isPersonal) {
      displayCallingList = await callingMessages.reduce(async (previousPromise, message) => {
        const messageList = await previousPromise;

        const parentId = message.parentId || message.sourceId;
        const childMessageList = await getInteractor().LocalMessageService.getChildMessages(
          parentId,
          SystemConstant.ARR_CALLING_TYPES,
        );
        const lastChildMessage = toCamel(childMessageList[0]);

        if (lastChildMessage && PERSONAL_DISPLAY_CALL_STATUS.includes(lastChildMessage.callStatus)) {
          messageList.push(toCamel(lastChildMessage));
        }

        return messageList;
      }, Promise.resolve([]));
    } else {
      const messageIds = [];
      const originCallingMsgs = (
        await Promise.all(
          callingMessages.map(async message => {
            const originMessage = isChildMessage(message)
              ? await getInteractor().LocalMessageService.get(message.parentId)
              : message;

            return toCamel(originMessage);
          }),
        )
      ).filter(message => {
        if (!message?.id || messageIds.includes(message.id)) return false;

        messageIds.push(message.id);
        return true;
      });

      displayCallingList = originCallingMsgs.reduce(async (previousPromise, message) => {
        const messageList = await previousPromise;

        const childMessageList = await getInteractor().LocalMessageService.getChildMessages(
          message.sourceId,
          SystemConstant.ARR_CALLING_TYPES,
        );

        // Remove duplicate ended calling
        const removeDuplicateEndCalling = toCamel([message, ...childMessageList]).filter((message, index) => {
          const lastIndex = findLastIndex(
            displayCallingList,
            last => last.parentId === message.parentId && last.callStatus === message.callStatus,
          );
          const isDuplicateEndMessage =
            lastIndex !== index && message.callStatus === SystemConstant.MESSAGE_CALL_STATUS.end;

          return false === isDuplicateEndMessage;
        });

        return messageList.concat(toCamel(removeDuplicateEndCalling));
      }, Promise.resolve([]));
    }
  } catch (error) {
    console.error(error);
  }

  return displayCallingList;
};

export const refactorMessage = async (messageItem, previousMessage = null) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  // Check message can be display avatar and timeline
  let isAvatar = false;
  let isShowTime = Boolean(!previousMessage?.created && !messageItem.threadId);
  const diffDays =
    messageItem.created && previousMessage?.created
      ? differenceInCalendarDays(new Date(messageItem.created), new Date(previousMessage.created))
      : 0;
  isShowTime = isShowTime || diffDays > 0;

  if (messageItem.senderId !== accountId) {
    const isDiffMessageType =
      previousMessage &&
      SystemConstant.ARR_CALLING_TYPES.concat(SystemConstant.MESSAGE_NOTIFY).includes(previousMessage.sendType) &&
      !SystemConstant.ARR_CALLING_TYPES.concat(SystemConstant.MESSAGE_NOTIFY).includes(messageItem.sendType);

    const messageOptions = messageItem.options ? toCamel(convertString2JSON(messageItem.options)) : {};
    const previousOptions = previousMessage ? toCamel(convertString2JSON(previousMessage.options)) : {};
    const isDiffDisappear = false === Boolean(previousOptions.disappearingF) && Boolean(messageOptions.disappearingF);

    isAvatar =
      !SystemConstant.ARR_CALLING_TYPES.includes(messageItem.sendType) &&
      (isShowTime ||
        !previousMessage ||
        isDiffMessageType ||
        previousMessage.senderId !== messageItem.senderId ||
        isDiffDisappear);
  }

  const senderAccount = toCamel(
    (await getInteractor(prefixKey).LocalAccountService.get(messageItem.senderId, branchId)) || {},
  );

  let isDeletedMsg = messageItem.state === SystemConstant.STATE.inactive;
  if (!isDeletedMsg) {
    const totalDeletingChildMsg = await getInteractor(prefixKey).LocalMessageService.count({
      parent_id: messageItem.sourceId,
      send_type: SystemConstant.SEND_TYPE.deleteMessage,
    });
    isDeletedMsg = totalDeletingChildMsg > 0;
  }

  return {
    ...messageItem,
    isDeleted: isDeletedMsg,
    isShowTime: isShowTime,
    isAvatar: isAvatar,
    avatarId: senderAccount.avatarId,
    avatarUrl: senderAccount.avatarUrl,
    senderName: senderAccount.name,
    sentTime: convertMillisecondsToDate(messageItem.created, FormatConstant.FM_HH_MM),
  };
};

export const refactorMessageList = async messages => {
  const messageList = messages.filter(messageItem => messageItem && messageItem.id);

  try {
    const rewriteMessageList = [];

    for (let index = 0; index < messageList.length; index++) {
      const messageItem = await refactorMessage(messageList[index], messageList[index + 1]);
      rewriteMessageList.push(messageItem);
    }

    return rewriteMessageList;
  } catch (error) {
    console.error(error);
  }

  return messageList;
};

export const handleDisappearMsg = (currentMessageList, disappearIds) => {
  const newMessageList = [...currentMessageList];

  for (let index = 0; index < disappearIds.length; index++) {
    const disappearId = disappearIds[index];
    const disappearIndex = currentMessageList.findIndex(item => item.id === disappearId);
    const disappearMessage = newMessageList[disappearIndex];
    const nextMessage = currentMessageList[disappearIndex - 1];

    if (disappearIndex >= 0 && nextMessage && nextMessage.senderId === disappearMessage.senderId) {
      newMessageList[disappearIndex - 1] = {
        ...nextMessage,
        isShowTime: disappearMessage.isShowTime,
        isAvatar: disappearMessage.isAvatar,
      };
    }
  }

  const filterList = newMessageList.filter(item => false === Boolean(disappearIds.includes(item.id)));
  return filterList;
};

const isChildMessage = mes => Boolean(mes.parentId);

const isDeletedMessage = messageItem => {
  return messageItem.sendType === SystemConstant.SEND_TYPE.deleteMessage;
};

export const CHAT_WRAPPER_ID = "conversation-id";
export const scrollToTopInbox = () => {
  const wrapper = document.getElementById(CHAT_WRAPPER_ID);
  if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;

  store.dispatch(
    ConversationActions.conversationSet(
      {
        scrollToChildId: null,
      },
      StorageUtil.getCurrentPrefixKey(),
    ),
  );
};

export const isLocalMessage = newestMessage => {
  const camelMessage = toCamel(newestMessage);
  return (
    camelMessage.senderDeviceId === StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID) &&
    !camelMessage.parentId &&
    [
      SystemConstant.SEND_TYPE.message,
      SystemConstant.SEND_TYPE.groupMessage,
      SystemConstant.SEND_TYPE.link,
      ...SystemConstant.ARR_CALLING_TYPES,
      ...Object.values(SystemConstant.MEDIA_SEND_TYPE),
    ].includes(camelMessage.sendType)
  );
};
