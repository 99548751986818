import { memo } from "react";
import PropTypes from "prop-types";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { FormatConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import PersonalCallHistory from "./PersonalCallHistory";
import ChatItem from "./ChatItem";
import LineChat from "./LineChat";
import { convertLineChatTime } from "utils/date.utils";
import { StorageUtil } from "utils";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem/ChatItem.helper";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AppAvatar } from "components";
import StringFormat from "string-format";
import PinMessage from "./ChatItem/PinMessage";
import LeaveGroupMessage from "./ChatItem/LeaveGroupMessage";
import DisappearMessageNotice from "./ChatItem/DisappearMessageNotice";
import ScreenCaptureMessage from "./ChatItem/ScreenCaptureMessage";

const MessageItem = ({ message, isThreadMode, isSearchMode }) => {
  const isSkipDeletedMessage = Boolean(isSearchMode && message.isDeleted);
  const isSkipMessage =
    isSkipDeletedMessage ||
    (Boolean(isThreadMode || isSearchMode) &&
      [
        SystemConstant.SEND_TYPE.pinMessage,
        SystemConstant.SEND_TYPE.leaveGroup,
        SystemConstant.SEND_TYPE.screenCapture,
        SystemConstant.SEND_TYPE.disappearMessage,
      ].includes(message.sendType));

  if (isSkipMessage) return null;
  return (
    <MessageContainer id={StringFormat(FormatConstant.FM_CHAT_ITEM_ID, message.parentId || message.sourceId)}>
      {message.isShowTime && <LineChat data={convertLineChatTime(message.created)} />}

      {message.isDeleted ? (
        <DeleteMessage message={message} />
      ) : SystemConstant.ARR_CALLING_TYPES.includes(message.sendType) ? (
        Boolean(message.senderId) && <PersonalCallHistory data={message} />
      ) : message.sendType === SystemConstant.SEND_TYPE.pinMessage ? (
        <PinMessage message={message} />
      ) : message.sendType === SystemConstant.SEND_TYPE.leaveGroup ? (
        <LeaveGroupMessage message={message} />
      ) : message.sendType === SystemConstant.SEND_TYPE.screenCapture ? (
        <ScreenCaptureMessage message={message} />
      ) : message.sendType === SystemConstant.SEND_TYPE.disappearMessage ? (
        <DisappearMessageNotice message={message} />
      ) : (
        <ChatItem data={message} isThreadMode={isThreadMode} isSearchMode={isSearchMode} />
      )}
    </MessageContainer>
  );
};

MessageItem.prototype = {
  message: PropTypes.object,
  isThreadMode: PropTypes.bool,
};
export default memo(MessageItem);

const DeleteMessage = memo(({ message }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const messengerClasses = useMessageStyle();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isMine = message.senderId === accountId;
  const isAvatar = Boolean(message.isAvatar);
  const msgCreatedTime = getCreatedTimeText(message);

  const isDeletedByMe = isMine || message.state === SystemConstant.STATE.inactive;
  return (
    <DeleteMessageContainer justifyContent={isMine ? "flex-end" : "flex-start"}>
      <Stack direction="row" spacing={2}>
        {!isMine && (
          <AppAvatar
            avatarId={message.avatarId}
            size={40}
            className={clsx(!isAvatar && messengerClasses.hiddenAvatar)}
            alt="chatting-avatar"
          />
        )}

        <Box>
          {msgCreatedTime && (
            <Typography className={clsx("ellipsis", messengerClasses.createdTimeText, "msg-sender-time")}>
              {msgCreatedTime}
            </Typography>
          )}

          <Button variant="outlined" disabled>
            {getLabel(isDeletedByMe ? "FM_DELETED_MSG_BY_ME" : "FM_DELETED_MSG_BY_OTHER", { name: message.senderName })}
          </Button>
        </Box>
      </Stack>
    </DeleteMessageContainer>
  );
});

const MessageContainer = styled(Box)({
  position: "relative",
  width: "100%",
});

const DeleteMessageContainer = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  paddingTop: 8,
  paddingBottom: 0,
  borderRadius: 20,

  "& button": {
    textTransform: "none",
    borderRadius: 20,
    padding: "6px 12px",
  },
});
