import { KeyConstant, LangConstant, SystemConstant } from "const";
import { put } from "redux-saga/effects";
import { ConversationActions, NotificationSettingActions, SystemActions } from "redux-store";
import {
  updateAccountNotiSetting,
  updateGroupNotiSetting,
  updateThreadNotiSetting,
} from "services/notification-setting.service";
import { StorageUtil, uuid } from "utils";
import { getSavedServer } from "utils/branch.utils";
import { getNSLang } from "utils/lang.utils";

export function* updateAccountSetting(action) {
  const { prefixKey, data } = action;
  const { settingSubType, created, state, value } = data;
  const updateResponse = yield updateAccountNotiSetting(prefixKey, settingSubType, created, state, value);
  if (updateResponse) {
    yield put(
      NotificationSettingActions.notificationSettingSet({
        updateAccountSetting: [updateResponse],
      }),
    );

    yield put(
      SystemActions.systemSet({
        toast: {
          content: getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_SETTING_NOTICE_SUCCESS"),
          modified: Date.now(),
        },
      }),
    );
  } else {
    yield put(
      SystemActions.systemSet({
        toast: {
          content: getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_SETTING_NOTICE_FAIL"),
          severity: "error",
          modified: Date.now(),
        },
      }),
    );
  }
}

export function* updateGroupSetting(action) {
  const { prefixKey, data } = action;
  const { groupId, settingSubType, created, state, value } = data;
  const isSettingDisappear = settingSubType === SystemConstant.SETTING_SUB_TYPE.DISAPPEAR_MESSAGE;
  const updateResponse = yield updateGroupNotiSetting(prefixKey, groupId, settingSubType, created, state, value);
  if (updateResponse) {
    yield put(
      NotificationSettingActions.notificationSettingSet({
        updateGroupSetting: {
          groupId: groupId,
          updateList: [updateResponse],
        },
      }),
    );

    // If update success && isSettingDisappear => send message with sendType = DISAPPEAR_MESSAGE (25)
    if (isSettingDisappear) {
      const content = { status: state, disappear_time: value };
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
      const saveMessage = {
        account_id: accountId,
        branch_id: getSavedServer().id,
        content: JSON.stringify(content),
        created: Date.now(),
        device_id: deviceId,
        group_id: groupId,
        id: uuid(),
        mentions: null,
        modified: 0,
        options: null,
        parent_id: null,
        send_type: SystemConstant.SEND_TYPE.disappearMessage,
        sender_device_id: deviceId,
        sender_id: accountId,
        source_id: uuid(),
        state: 1,
        status: 1,
        thread_id: null,
        call_status: null,
      };
      yield put(
        ConversationActions.sendMessage(
          {
            groupId: groupId,
            sendType: SystemConstant.SEND_TYPE.disappearMessage,
            content: JSON.stringify(content),
            parentId: null,
            branchId: getSavedServer().id,
            mentionIdsArr: [],
            threadId: null,
            currentMessage: saveMessage,
          },
          prefixKey,
        ),
      );
    }
    // Show toast success if is not setting disappear message or setting capture screen
    yield put(
      SystemActions.systemSet({
        toast: {
          content: getNSLang(
            LangConstant.NS_SETTING_NOTIFICATION,
            isSettingDisappear ? "TXT_SETTING_MESSAGE_SUCCESS" : "TXT_SETTING_NOTICE_SUCCESS",
          ),
          modified: Date.now(),
        },
      }),
    );
  } else {
    // Show toast error if is not setting disappear message or setting capture screen
    yield put(
      SystemActions.systemSet({
        toast: {
          content: getNSLang(
            LangConstant.NS_SETTING_NOTIFICATION,
            isSettingDisappear ? "TXT_SETTING_MESSAGE_FAIL" : "TXT_SETTING_NOTICE_FAIL",
          ),
          severity: "error",
          modified: Date.now(),
        },
      }),
    );
  }
}

export function* updateThreadSetting(action) {
  const { prefixKey, data } = action;
  const { threadId, settingSubType, created, state, value } = data;
  const updateResponse = yield updateThreadNotiSetting(prefixKey, threadId, settingSubType, created, state, value);
  if (updateResponse) {
    yield put(
      NotificationSettingActions.notificationSettingSet({
        updateThreadSetting: {
          threadId: threadId,
          updateList: [updateResponse],
        },
      }),
    );

    yield put(
      SystemActions.systemSet({
        toast: {
          content: getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_SETTING_NOTICE_SUCCESS"),
          modified: Date.now(),
        },
      }),
    );
  } else {
    yield put(
      SystemActions.systemSet({
        toast: {
          content: getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_SETTING_NOTICE_FAIL"),
          severity: "error",
          modified: Date.now(),
        },
      }),
    );
  }
}
