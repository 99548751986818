// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import React, { useCallback, useEffect } from "react";
import { noop } from "lodash";
import { CallNeedPermissionScreen } from "./CallNeedPermissionScreen";
import { CallScreen } from "./CallScreen";
import { CallingLobby } from "./CallingLobby";
import { CallingSelectPresentingSourcesModal } from "./CallingSelectPresentingSourcesModal";
import { CallingPip } from "./CallingPip";
import { IncomingCallBar } from "./IncomingCallBar";
import type { ActiveCallType, CallViewMode } from "../types/Calling";
import { CallEndedReason, CallState } from "../types/Calling";
import { CallMode } from "../types/CallDisposition";
import type { ConversationType } from "../state/ducks/conversations";
import type {
  AcceptCallType,
  CancelCallType,
  DeclineCallType,
  SetLocalAudioType,
  SetLocalPreviewType,
  SetLocalVideoType,
  SetRendererCanvasType,
  StartCallType,
} from "../state/ducks/calling";
import type { LocalizerType } from "../types/Util";
// import { missingCaseError } from "../util/missingCaseError";
import { CallingToastProvider } from "./CallingToast";
// import * as log from "../../../public/ring-rtc/logging/log";
import { usePrevious } from "../hooks/usePrevious";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CallingAction from "redux-store/calling.redux";
import { StorageUtil } from "utils";
import { KeyConstant } from "const";
const prefixKey = StorageUtil.getCurrentPrefixKey();

const log = window.electronLibs.libs.log;
const missingCaseError = window.electronLibs.libs.missingCaseError;

export type DirectIncomingCall = Readonly<{
  callMode: CallMode.Direct;
  callState?: CallState;
  callEndedReason?: CallEndedReason;
  conversation: ConversationType;
  isVideoCall: boolean;
}>;

export type CallingImageDataCache = Map<number, ImageData>;

export type PropsType = {
  activeCall?: ActiveCallType;
  availableCameras: Array<MediaDeviceInfo>;
  cancelCall: (_: CancelCallType) => void;
  changeCallView: (mode: CallViewMode) => void;
  closeNeedPermissionScreen: () => void;
  getIsSharingPhoneNumberWithEverybody: () => boolean;
  getPresentingSources: () => void;
  incomingCall: DirectIncomingCall | null;
  renderDeviceSelection: () => JSX.Element;
  // renderReactionPicker: (props: React.ComponentProps<typeof SmartReactionPicker>) => JSX.Element;
  showContactModal: (contactId: string, conversationId?: string) => void;
  startCall: (payload: StartCallType) => void;
  toggleParticipants: () => void;
  acceptCall: (_: AcceptCallType) => void;
  bounceAppIconStart: () => unknown;
  bounceAppIconStop: () => unknown;
  cancelPresenting: () => void;
  declineCall: (_: DeclineCallType) => void;
  hasInitialLoadCompleted: boolean;
  i18n: LocalizerType;
  me: ConversationType;
  notifyForCall: (conversationId: string, title: string, isVideoCall: boolean) => unknown;
  openSystemPreferencesAction: () => unknown;
  playRingtone: () => unknown;
  selectPresentingSource: (id: string) => void;
  setIsCallActive: (_: boolean) => void;
  setLocalAudio: (_: SetLocalAudioType) => void;
  setLocalVideo: (_: SetLocalVideoType) => void;
  setLocalPreview: (_: SetLocalPreviewType) => void;
  setOutgoingRing: (_: boolean) => void;
  setRendererCanvas: (_: SetRendererCanvasType) => void;
  stopRingtone: () => unknown;
  switchToPresentationView: () => void;
  switchFromPresentationView: () => void;
  hangUpActiveCall: (reason: string) => void;
  togglePip: () => void;
  toggleScreenRecordingPermissionsDialog: () => unknown;
  toggleSettings: () => void;
  isConversationTooBigToRing: boolean;
  pauseVoiceNotePlayer: () => void;
};
// & Pick<ReactionPickerProps, "renderEmojiPicker">;

type ActiveCallManagerPropsType = {
  activeCall: ActiveCallType;
} & Omit<
  PropsType,
  | "acceptCall"
  | "bounceAppIconStart"
  | "bounceAppIconStop"
  | "declineCall"
  | "hasInitialLoadCompleted"
  | "incomingCall"
  | "notifyForCall"
  | "playRingtone"
  | "setIsCallActive"
  | "stopRingtone"
  | "isConversationTooBigToRing"
>;

function ActiveCallManager({
  activeCall,
  availableCameras,
  cancelCall,
  cancelPresenting,
  changeCallView,
  closeNeedPermissionScreen,
  hangUpActiveCall,
  i18n,
  getIsSharingPhoneNumberWithEverybody,
  getPresentingSources,
  me,
  openSystemPreferencesAction,
  renderDeviceSelection,
  // renderEmojiPicker,
  // renderReactionPicker,
  selectPresentingSource,
  setLocalAudio,
  setLocalPreview,
  setLocalVideo,
  setRendererCanvas,
  setOutgoingRing,
  startCall,
  switchToPresentationView,
  switchFromPresentationView,
  toggleParticipants,
  togglePip,
  toggleScreenRecordingPermissionsDialog,
  toggleSettings,
  pauseVoiceNotePlayer,
}: ActiveCallManagerPropsType): JSX.Element {
  const {
    conversation,
    hasLocalAudio,
    hasLocalVideo,
    peekedParticipants,
    pip,
    presentingSourcesAvailable,
    settingsDialogOpen,
    showParticipantsList,
    outgoingRing,
  } = activeCall;
  const history = useHistory();
  const callingGroupDetailRing = useSelector((state: any) => state.callingRedux.callingGroupDetailRing);
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  const cancelActiveCall = useCallback(() => {
    console.log("handleResetCallingGroupDetailRing");
    cancelCall({ conversationId: conversation.id });
    dispatch(
      CallingAction.callingSet({
        callingGroupDetail: null,
        callingGroupDetailRing: null,
      }),
    );
  }, [cancelCall, conversation.id, history]);

  const joinActiveCall = useCallback(() => {
    // pause any voice note playback
    dispatch(
      CallingAction.onCallCheck({
        accountId: callingGroupDetailRing?.groupMembers.filter(item => item.id !== accountId)[0]?.id,
      }),
    );
    pauseVoiceNotePlayer();
    startCall({
      callMode: activeCall.callMode,
      conversationId: callingGroupDetailRing?.id,
      hasLocalAudio,
      hasLocalVideo,
      remoteId: callingGroupDetailRing?.groupMembers.filter(item => item.id !== accountId)[0]?.id,
    });
  }, [startCall, activeCall.callMode, conversation.id, hasLocalAudio, hasLocalVideo, pauseVoiceNotePlayer]);

  // For caching screenshare frames which update slowly, between Pip and CallScreen.
  const imageDataCache = React.useRef<CallingImageDataCache>(new Map());

  const previousConversationId = usePrevious(conversation.id, conversation.id);
  useEffect(() => {
    if (conversation.id !== previousConversationId) {
      imageDataCache.current.clear();
    }
  }, [conversation.id, previousConversationId]);

  let isCallFull: boolean;
  let showCallLobby: boolean;
  let isConvoTooBigToRing = false;
  let isAdhocAdminApprovalRequired = false;
  let isAdhocJoinRequestPending = false;

  switch (activeCall.callMode) {
    case CallMode.Direct: {
      const { callState, callEndedReason } = activeCall;
      const ended = callState === CallState.Ended;
      if (ended && callEndedReason === CallEndedReason.RemoteHangupNeedPermission) {
        return <CallNeedPermissionScreen close={closeNeedPermissionScreen} conversation={conversation} i18n={i18n} />;
      }
      showCallLobby = !callState;
      isCallFull = false;
      break;
    }
    default:
      throw missingCaseError(activeCall);
  }

  if (pip) {
    return (
      <CallingPip
        activeCall={activeCall}
        imageDataCache={imageDataCache}
        hangUpActiveCall={hangUpActiveCall}
        hasLocalVideo={hasLocalVideo}
        i18n={i18n}
        setLocalPreview={setLocalPreview}
        setRendererCanvas={setRendererCanvas}
        switchToPresentationView={switchToPresentationView}
        switchFromPresentationView={switchFromPresentationView}
        togglePip={togglePip}
      />
    );
  }

  if (showCallLobby) {
    return (
      <>
        <CallingLobby
          availableCameras={availableCameras}
          callMode={activeCall.callMode}
          conversation={conversation}
          hasLocalAudio={hasLocalAudio}
          hasLocalVideo={hasLocalVideo}
          i18n={i18n}
          isAdhocAdminApprovalRequired={isAdhocAdminApprovalRequired}
          isAdhocJoinRequestPending={isAdhocJoinRequestPending}
          isCallFull={isCallFull}
          isConversationTooBigToRing={isConvoTooBigToRing}
          getIsSharingPhoneNumberWithEverybody={getIsSharingPhoneNumberWithEverybody}
          me={me}
          onCallCanceled={cancelActiveCall}
          onJoinCall={joinActiveCall}
          outgoingRing={outgoingRing}
          peekedParticipants={peekedParticipants}
          setLocalPreview={setLocalPreview}
          setLocalAudio={setLocalAudio}
          setLocalVideo={setLocalVideo}
          setOutgoingRing={setOutgoingRing}
          showParticipantsList={showParticipantsList}
          toggleParticipants={toggleParticipants}
          togglePip={togglePip}
          toggleSettings={toggleSettings}
        />
        {settingsDialogOpen && renderDeviceSelection()}
      </>
    );
  }

  return (
    <>
      <CallScreen
        activeCall={activeCall}
        cancelPresenting={cancelPresenting}
        changeCallView={changeCallView}
        getPresentingSources={getPresentingSources}
        hangUpActiveCall={hangUpActiveCall}
        i18n={i18n}
        imageDataCache={imageDataCache}
        me={me}
        openSystemPreferencesAction={openSystemPreferencesAction}
        // renderEmojiPicker={renderEmojiPicker}
        // renderReactionPicker={renderReactionPicker}
        setLocalPreview={setLocalPreview}
        setRendererCanvas={setRendererCanvas}
        setLocalAudio={setLocalAudio}
        setLocalVideo={setLocalVideo}
        stickyControls={showParticipantsList}
        switchToPresentationView={switchToPresentationView}
        switchFromPresentationView={switchFromPresentationView}
        toggleScreenRecordingPermissionsDialog={toggleScreenRecordingPermissionsDialog}
        toggleParticipants={toggleParticipants}
        togglePip={togglePip}
        toggleSettings={toggleSettings}
      />
      {presentingSourcesAvailable && presentingSourcesAvailable.length ? (
        <CallingSelectPresentingSourcesModal
          i18n={i18n}
          presentingSourcesAvailable={presentingSourcesAvailable}
          selectPresentingSource={selectPresentingSource}
          cancelPresenting={cancelPresenting}
        />
      ) : null}
      {settingsDialogOpen && renderDeviceSelection()}
    </>
  );
}

export function CallManager({
  acceptCall,
  activeCall,
  availableCameras,
  bounceAppIconStart,
  bounceAppIconStop,
  cancelCall,
  cancelPresenting,
  changeCallView,
  closeNeedPermissionScreen,
  declineCall,
  getPresentingSources,
  hangUpActiveCall,
  hasInitialLoadCompleted,
  i18n,
  incomingCall,
  isConversationTooBigToRing,
  getIsSharingPhoneNumberWithEverybody,
  me,
  notifyForCall,
  openSystemPreferencesAction,
  pauseVoiceNotePlayer,
  playRingtone,
  renderDeviceSelection,
  // renderEmojiPicker,
  // renderReactionPicker,
  selectPresentingSource,
  setIsCallActive,
  setLocalAudio,
  setLocalPreview,
  setLocalVideo,
  setOutgoingRing,
  setRendererCanvas,
  showContactModal,
  startCall,
  stopRingtone,
  switchFromPresentationView,
  switchToPresentationView,
  toggleParticipants,
  togglePip,
  toggleScreenRecordingPermissionsDialog,
  toggleSettings,
}: PropsType): JSX.Element | null {
  const isCallActive = Boolean(activeCall);
  useEffect(() => {
    setIsCallActive(isCallActive);
  }, [isCallActive, setIsCallActive]);

  const shouldRing = getShouldRing({
    activeCall,
    incomingCall,
    isConversationTooBigToRing,
    hasInitialLoadCompleted,
  });
  useEffect(() => {
    if (shouldRing) {
      log.info("CallManager: Playing ringtone");
      playRingtone();
      return () => {
        log.info("CallManager: Stopping ringtone");
        stopRingtone();
      };
    }

    stopRingtone();
    return noop;
  }, [shouldRing]);

  if (activeCall) {
    // `props` should logically have an `activeCall` at this point, but TypeScript can't
    //   figure that out, so we pass it in again.
    return (
      <CallingToastProvider i18n={i18n}>
        <ActiveCallManager
          activeCall={activeCall}
          availableCameras={availableCameras}
          cancelCall={cancelCall}
          cancelPresenting={cancelPresenting}
          changeCallView={changeCallView}
          closeNeedPermissionScreen={closeNeedPermissionScreen}
          getPresentingSources={getPresentingSources}
          hangUpActiveCall={hangUpActiveCall}
          i18n={i18n}
          getIsSharingPhoneNumberWithEverybody={getIsSharingPhoneNumberWithEverybody}
          me={me}
          openSystemPreferencesAction={openSystemPreferencesAction}
          pauseVoiceNotePlayer={pauseVoiceNotePlayer}
          renderDeviceSelection={renderDeviceSelection}
          // renderEmojiPicker={renderEmojiPicker}
          // renderReactionPicker={renderReactionPicker}
          selectPresentingSource={selectPresentingSource}
          setLocalAudio={setLocalAudio}
          setLocalPreview={setLocalPreview}
          setLocalVideo={setLocalVideo}
          setOutgoingRing={setOutgoingRing}
          setRendererCanvas={setRendererCanvas}
          showContactModal={showContactModal}
          startCall={startCall}
          switchFromPresentationView={switchFromPresentationView}
          switchToPresentationView={switchToPresentationView}
          toggleParticipants={toggleParticipants}
          togglePip={togglePip}
          toggleScreenRecordingPermissionsDialog={toggleScreenRecordingPermissionsDialog}
          toggleSettings={toggleSettings}
        />
      </CallingToastProvider>
    );
  }

  // In the future, we may want to show the incoming call bar when a call is active.
  if (incomingCall) {
    return (
      <IncomingCallBar
        acceptCall={acceptCall}
        bounceAppIconStart={bounceAppIconStart}
        bounceAppIconStop={bounceAppIconStop}
        declineCall={declineCall}
        i18n={i18n}
        notifyForCall={notifyForCall}
        {...incomingCall}
      />
    );
  }

  return null;
}

function isRinging(callState: CallState | undefined): boolean {
  return callState === CallState.Prering || callState === CallState.Ringing;
}

function getShouldRing({
  activeCall,
  incomingCall,
  isConversationTooBigToRing,
  hasInitialLoadCompleted,
}: Readonly<
  Pick<PropsType, "activeCall" | "incomingCall" | "isConversationTooBigToRing" | "hasInitialLoadCompleted">
>): boolean {
  if (!hasInitialLoadCompleted) {
    return false;
  }

  if (incomingCall != null) {
    // don't ring a large group
    if (isConversationTooBigToRing) {
      return false;
    }

    if (activeCall != null) {
      return false;
    }

    if (incomingCall.callMode === CallMode.Direct) {
      return isRinging(incomingCall.callState) && incomingCall.callEndedReason == null;
    }

    throw missingCaseError(incomingCall);
  }

  if (activeCall != null) {
    switch (activeCall.callMode) {
      case CallMode.Direct:
        return activeCall.callState === CallState.Prering || activeCall.callState === CallState.Ringing;
      default:
        throw missingCaseError(activeCall);
    }
  }

  return false;
}
