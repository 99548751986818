import { SystemConstant } from "const";
import store, { SystemActions } from "redux-store";
import { StorageUtil, toCamel, toSnake } from "utils";
import { getCommonLang } from "utils/lang.utils";
import { checkCurrentGroup } from "utils/view.utils";

export const ARR_NOTICE_NORMAL = [
  SystemConstant.SEND_TYPE.message,
  SystemConstant.SEND_TYPE.groupMessage,
  SystemConstant.SEND_TYPE.link,
  SystemConstant.SEND_TYPE.botMessage,
];

export const ARR_NOTICE_SPECIAL = [
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
];

export const DISPLAY_MSG_TYPES = [...SystemConstant.ARR_CALLING_TYPES, ...SystemConstant.ARR_MSG_SEND_TYPES];

export const isFileMessage = sendType => ARR_NOTICE_SPECIAL.includes(sendType);

export const formatPagingParams = newParamsObj => {
  const params = {
    paging: 1,
    orderBy: "created",
    asc: 1,
    offset: 0,
    limit: 100,
    filter: "",
    groupId: null,
    sinceTime: 0,
    ...newParamsObj,
  };

  Object.entries(params).forEach(result => {
    if (result[1] === "" || result[1] === null || result[1] === undefined) {
      delete params[result[0]];
    }
  });

  return toSnake(params);
};

export const formatArray2Key = initialObject => {
  let result = {};
  if (!initialObject) return result;

  Object.entries(initialObject).forEach(pair => {
    if (Array.isArray(pair[1])) {
      pair[1].forEach((item, index) => {
        result[`${pair[0]}[${index}] `] = item;
      });
      delete result[pair[0]];
    }
  });

  return result;
};

export const checkCurrentBranchByPrefix = prefixKey => {
  const currentPrefixKey = StorageUtil.getCurrentPrefixKey();
  return prefixKey && prefixKey === currentPrefixKey;
};

export const checkTriggerMessageUI = (prefixKey, message) => {
  const camelMessage = toCamel(message);

  return (
    camelMessage &&
    DISPLAY_MSG_TYPES.includes(camelMessage.sendType) &&
    checkCurrentBranchByPrefix(prefixKey) &&
    checkCurrentGroup(camelMessage.groupId)
  );
};

// Define types to only mapping with saga logic (not mapping with redux)
export const SAGA_TYPES = {
  GET_BRANCH_MAPPING: "GET_BRANCH_MAPPING",
};

export const isEncryptionType = (device, sendType) => {
  const isCalling = SystemConstant.ARR_CALLING_TYPES.includes(sendType);
  if (isCalling) {
    const deviceType = parseInt(device.type);
    switch (deviceType) {
      case SystemConstant.DEVICE_TYPE.android:
        if (device.version >= SystemConstant.UN_ENCRYPTION_CALL_VERSION.android)
          return SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;
        break;

      case SystemConstant.DEVICE_TYPE.ios:
        if (device.version >= SystemConstant.UN_ENCRYPTION_CALL_VERSION.ios)
          return SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;
        break;

      default:
        if (device.version >= SystemConstant.UN_ENCRYPTION_CALL_VERSION.desktop)
          return SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;
        break;
    }
  }

  return SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION;
};

export const throwExceptionToast = content => {
  store.dispatch(
    SystemActions.systemSet({
      toast: {
        content: content || getCommonLang("TXT_SERVER_ERROR"),
        severity: "error",
        modified: Date.now(),
      },
    }),
  );
};
