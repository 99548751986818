import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import { TransLanguage } from "components";

const WarningNote = ({ content }) => {
  return (
    <WarningNoteComponent>
      <TransLanguage i18nKey={content} />
    </WarningNoteComponent>
  );
};

export default WarningNote;

const WarningNoteComponent = styled(Box)(props => ({
  backgroundColor: props.theme.palette.grey[100],
  margin: "8px 16px",
  padding: 8,
  borderRadius: 5,
}));
