import { AppConstant, KeyConstant, PathConstant, SystemConstant } from "const";
import store, { ConversationActions, SystemActions } from "redux-store";
import { CommonBranchInfoService, ElectronService } from "services";
import { convertString2JSON, StorageUtil, toCamel } from "utils";
import { isLoginBranch } from "utils/auth.utils";
import { changeBranchServer } from "utils/branch.utils";

// Connect to DB
export const getInteractor = prefixKey => {
  const interactor = window.interactor.getInstance(prefixKey || StorageUtil.getCurrentPrefixKey());
  return {
    // Independent - the data is saved in branch's database (format name: <accountId_branchId>.db)
    LocalAccountService: interactor.account,
    LocalAccountKeyService: interactor.accountKey,
    LocalGroupService: interactor.group,
    LocalGroupSettingService: interactor.groupSetting,
    LocalSettingService: interactor.setting,
    LocalBranchAccountService: interactor.branchAccount,
    LocalBranchService: interactor.branch,
    LocalContactService: interactor.contact,
    LocalMessageService: interactor.message,
    LocalDeviceService: interactor.device,
    LocalAccountGroupService: interactor.accountGroup,
    LocalInitDataService: interactor.initData,

    LocalNotificationService: interactor.notification,
    LocalSenderKeySharedService: interactor.senderKeyShared,
    LocalEmojiService: interactor.emoji,
    LocalCallHistoryService: interactor.callHistory,
    LocalMsgErrorResendService: interactor.messageErrorResend,
    LocalMsgErrorSendNullService: interactor.messageErrorSendNull,
    LocalThreadService: interactor.thread,
    LocalRestoreService: interactor.restore,
    LocalApiCallService: interactor.apiCall,
    LocalEventService: interactor.events,

    LocalCryptoService: interactor.crypto,
    LocalReceivedMemberService: interactor.receivedMember,
    LocalSeenMemberService: interactor.seenMember,
    LocalFileService: interactor.file,
    LocalFileGroupService: interactor.fileGroup,
    LocalGroupMessageService: interactor.groupMessage,

    LocalAccountSettingService: interactor.accountSetting,
    LocalThreadSettingService: interactor.threadSetting,
    LocalDBVersionService: interactor.dbVersion,
  };
};

export const getCommonInteractor = () => window.interactor.getCommonInteractor();

// Common - logic is not related prefixKey (common function or query data in trios.db)
export const LocalDbManagement = getCommonInteractor().dbManagement;
export const LocalKeyActionService = getCommonInteractor().keyAction;
export const LocalDeviceSettingService = getCommonInteractor().deviceSetting;

export const LocalAppNotificationService = {
  setBadgeCount: count => {
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.setBadgeCount, count || 0);
  },

  showNotification: (title, noticeData, newNotification) => {
    const { content, groupId, threadId, messageSourceId, prefixKey } = noticeData;

    store.dispatch(
      SystemActions.systemSet({
        newNotification: { groupId, messageSourceId, prefixKey, ...newNotification },
      }),
    );

    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
    const branchLogo =
      branchInfo.domain && branchInfo.id
        ? CommonBranchInfoService.getBranchAvatarUrl(branchInfo.domain, branchInfo.id)
        : "";

    if (!window.Notification) {
      console.warn("Browser does not support notifications.");
    } else {
      createNotification(
        title,
        {
          icon: branchLogo,
          body: content,
        },
        () => {
          ElectronService.showApplication();

          const notScreenLockPage = false === Boolean(window.location.href.includes(PathConstant.SCREEN_LOCK));
          const notChooseBranchPage = false === Boolean(window.location.href.includes(PathConstant.CHOOSE_BRANCH_PAGE));
          if (isLoginBranch(branchInfo) && notScreenLockPage && notChooseBranchPage) {
            // Switch to branch
            changeBranchServer(branchInfo);

            if (newNotification && newNotification.type === SystemConstant.NotifyType.BRANCH_REQUEST) {
              // Open request form dialog
              store.dispatch(
                SystemActions.systemSet({
                  requestNotice: toCamel(convertString2JSON(newNotification.options, {})),
                }),
              );
            } else {
              // Go to message by groupId/ messageId
              store.dispatch(
                ConversationActions.setSelectGroupId({
                  selectedGroupId: groupId,
                  threadingId: threadId,
                  scrollToChildId: messageSourceId,
                }),
              );
            }
          }
        },
      );
    }
  },
};

const createNotification = async (title, notificationOptions = {}, onClick) => {
  let notice;
  // Check if permission is already granted
  if (Notification.permission === "granted") {
    // Show notification here
    notice = new Notification(title, notificationOptions);
  } else {
    try {
      // Request permission from user
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Show notification here
        notice = new Notification(title, notificationOptions);
      } else {
        console.error("User blocked notifications.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (onClick) notice.onclick = onClick;

  return notice;
};
