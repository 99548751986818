import { AppDrawer, IOSSwitch } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useConversationContext } from "../MessengerChat/ConversationContext";
import AccountItem from "../Account/AccountItem";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { SettingTimeDialog } from "../Account/SettingApp/SettingAppNotification/SettingTimeDialog";
import TurnOffNoticeNote from "../Account/SettingApp/SettingAppNotification/TurnOffNoticeNote";
import {
  checkValidGlobalGroupSettingOff,
  checkValidGroupSettingOff,
} from "../Account/SettingApp/SettingAppNotification/setting-time.helper";
import { createSelector } from "reselect";
import { ConversationSelectors, NotificationSettingActions, NotificationSettingSelectors } from "redux-store";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupNotiSetting } from "services/notification-setting.service";

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getSelectedGroupId, NotificationSettingSelectors.getUpdateGroupSetting],

  (selectedGroupId, updateGroupSetting) => {
    if (updateGroupSetting && updateGroupSetting.groupId === selectedGroupId) {
      return updateGroupSetting.updateList;
    }

    return null;
  },
);

const GroupSettingNotification = ({ open, onClose }) => {
  const { groupDetail } = useConversationContext();
  const { t: getSettingNoticeLabel } = useTranslation(LangConstant.NS_SETTING_NOTIFICATION);
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  let checkingTimeout;

  const remoteUpdateList = useSelector(memoizedIncomingState);

  const [openSettingTime, setOpenSettingTime] = useState(false);
  const [groupNoticeSetting, setGroupNoticeSetting] = useState({
    state: SystemConstant.STATE.active,
    value: null, // setting.value + modified
  });
  const [mentionSetting, setMentionSetting] = useState(SystemConstant.STATE.inactive);
  const [threadSetting, setThreadSetting] = useState(SystemConstant.STATE.active);
  const [isDisableGroupSetting, setIsDisableGroupSetting] = useState(false);
  const [isDisableThreadSetting, setIsDisableThreadSetting] = useState(false);

  const handleGroupNotice = () => {
    if (Boolean(groupNoticeSetting.state)) {
      setOpenSettingTime(true);
    } else {
      setOpenSettingTime(false);
      dispatch(
        NotificationSettingActions.updateGroupSetting(
          {
            groupId: groupDetail.id,
            settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
            created: Date.now(),
            state: SystemConstant.STATE.active,
          },
          prefixKey,
        ),
      );
    }
  };

  const handleMentionNotice = async () => {
    const newSettingState = !mentionSetting;
    dispatch(
      NotificationSettingActions.updateGroupSetting(
        {
          groupId: groupDetail.id,
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_MENTION,
          created: Date.now(),
          state: newSettingState,
        },
        prefixKey,
      ),
    );
  };

  const handleThreadNotice = async () => {
    const newSettingState = !threadSetting;
    dispatch(
      NotificationSettingActions.updateGroupSetting(
        {
          groupId: groupDetail.id,
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_THREAD,
          created: Date.now(),
          state: newSettingState,
        },
        prefixKey,
      ),
    );
  };

  const handleSubmitTime = settingTime => {
    dispatch(
      NotificationSettingActions.updateGroupSetting(
        {
          groupId: groupDetail.id,
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
          created: Date.now(),
          state: SystemConstant.STATE.inactive,
          value: settingTime,
        },
        prefixKey,
      ),
    );
    setOpenSettingTime(false);
  };

  const handleInit = async () => {
    const localAccountSetting = await getInteractor(prefixKey).LocalAccountSettingService.find({
      account_id: accountId,
    });
    const localGroupSetting = await getInteractor(prefixKey).LocalGroupSettingService.find({
      account_id: accountId,
      group_id: groupDetail.id,
    });

    const globalGroupSetting = localAccountSetting.find(
      item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
    );
    const globalThreadSetting = localAccountSetting.find(
      item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL_THREAD,
    );

    // Init group notification new msg setting
    const isValidGlobalGroupSettingOff = checkValidGlobalGroupSettingOff(prefixKey, globalGroupSetting);
    if (isValidGlobalGroupSettingOff) {
      setIsDisableGroupSetting(true);
      setGroupNoticeSetting({
        state: SystemConstant.STATE.inactive,
        value: Boolean(globalGroupSetting.value) ? Number(globalGroupSetting.value) + globalGroupSetting.modified : -1,
      });
    } else {
      setIsDisableGroupSetting(false);
      const groupSetting = localGroupSetting.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
      );
      const isValidOffGroupSetting = checkValidGroupSettingOff(prefixKey, groupSetting, groupDetail.id);
      setGroupNoticeSetting({
        state: isValidOffGroupSetting ? SystemConstant.STATE.inactive : SystemConstant.STATE.active,
        value:
          isValidOffGroupSetting && Boolean(groupSetting.value)
            ? Number(groupSetting.value) + groupSetting.modified
            : -1,
      });
    }

    // Init group notification thread msg setting
    if (globalThreadSetting && globalThreadSetting.state === SystemConstant.STATE.inactive) {
      setIsDisableThreadSetting(true);
      setThreadSetting(globalThreadSetting.state);
    } else {
      setIsDisableThreadSetting(false);
      const threadSetting = localGroupSetting.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_THREAD,
      );

      setThreadSetting(threadSetting ? threadSetting.state : SystemConstant.STATE.active);
    }

    // Init group notification mention msg setting
    const mentionSetting = localGroupSetting.find(
      item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_MENTION,
    );
    setMentionSetting(mentionSetting ? mentionSetting.state : SystemConstant.STATE.inactive);
  };

  useEffect(() => {
    handleInit();

    return () => {
      if (checkingTimeout) clearTimeout(checkingTimeout);
    };
  }, []);

  // Auto update turn on notification setting when expired time
  useEffect(() => {
    if (
      groupNoticeSetting &&
      groupNoticeSetting.state === SystemConstant.STATE.inactive &&
      groupNoticeSetting.value > 0
    ) {
      const timeout = groupNoticeSetting.value - Date.now();
      if (checkingTimeout) clearTimeout(checkingTimeout);

      checkingTimeout = setTimeout(() => {
        // Update UI
        setGroupNoticeSetting({
          state: SystemConstant.STATE.active,
          value: -1,
        });

        // Update to server
        updateGroupNotiSetting(
          prefixKey,
          groupDetail.id,
          SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
          Date.now(),
          SystemConstant.STATE.active,
        );
      }, timeout);
    }
  }, [groupNoticeSetting]);

  useEffect(() => {
    if (remoteUpdateList) {
      remoteUpdateList.forEach(setting => {
        switch (setting.setting_sub_type) {
          case SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP:
            setGroupNoticeSetting({
              state: setting.state,
              value: Boolean(setting.value) ? Number(setting.value) + setting.modified : -1,
            });
            break;

          case SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_THREAD:
            setThreadSetting(setting.state);
            break;

          case SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP_MENTION:
            setMentionSetting(setting.state);
            break;

          default:
            break;
        }
      });
    }
  }, [remoteUpdateList]);

  const isChannel = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;

  return (
    <AppDrawer
      open={open}
      onClose={onClose}
      title={getSettingNoticeLabel(isChannel ? "TXT_SETTING_CHANNEL_NOTICE" : "TXT_SETTING_GROUP_NOTICE")}
      anchor="right"
    >
      <AccountItem
        text={getSettingNoticeLabel("TXT_NOTICE_NEW_MSG")}
        endIcon={<IOSSwitch checked={Boolean(groupNoticeSetting.state)} />}
        onClick={handleGroupNotice}
        disabled={isDisableGroupSetting}
      />

      {Boolean(groupNoticeSetting.state) ? (
        <>
          <AccountItem
            text={getSettingNoticeLabel("TXT_NOTICE_ONLY_MENTION")}
            endIcon={<IOSSwitch checked={Boolean(mentionSetting)} />}
            onClick={handleMentionNotice}
          />

          <AccountItem
            text={getSettingNoticeLabel("TXT_NOTICE_NEW_THREAD_MSG")}
            endIcon={<IOSSwitch checked={Boolean(threadSetting)} />}
            onClick={handleThreadNotice}
            disabled={isDisableThreadSetting}
          />
        </>
      ) : (
        <TurnOffNoticeNote time={groupNoticeSetting.value} />
      )}

      {openSettingTime && (
        <SettingTimeDialog onSubmitTime={handleSubmitTime} onClose={() => setOpenSettingTime(false)} />
      )}
    </AppDrawer>
  );
};

export default GroupSettingNotification;
