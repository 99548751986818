import React, { useState, createContext, useContext, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import store, {
  AccountSelectors,
  BranchSelectors,
  ConversationActions,
  ConversationSelectors,
  GroupInfoSelectors,
} from "redux-store";
import { StorageUtil, convertString2JSON, deepCloneJsonObject, uuid } from "utils";
import { getSendType } from "utils/view.utils";
import { CarouselMedia } from "components";
import { getInteractor } from "services/local.service";
import { KeyConstant, SystemConstant } from "const";
import { refactorFiles } from "pages/ManageFilePage/file.helper";
import uniqBy from "lodash/uniqBy";
import { createSelector } from "reselect";

/* ------------- Initial State ------------- */
const DEFAULT_GROUP_DATA = {
  groupName: "",
  groupMembers: [],
};

const DEFAULT_STORE = {
  selectedMediaId: null, // Not select media
  isInactive: false, // False: User is inactive in case personal group, else True
};

const INITIAL_STATE = {
  store: { ...DEFAULT_STORE },
  groupDetail: { ...DEFAULT_GROUP_DATA },

  clickMedia: fileInfo => {},
};

const ConversationContext = createContext(INITIAL_STATE);
export const useConversationContext = () => useContext(ConversationContext);

const memoizedReduxState = createSelector(
  [
    BranchSelectors.getSelectedBranch,
    ConversationSelectors.getSelectedGroupId,
    AccountSelectors.getUpdateChattingMember,
    GroupInfoSelectors.getUpdatingGroup,
  ],
  (selectedBranch, selectedGroupId, updateChattingMember, updatingGroupData) => {
    const isChanged = Boolean(
      selectedGroupId === updatingGroupData?.id &&
        (updatingGroupData.groupName || updatingGroupData.avatarId || updatingGroupData.groupMembers),
    );
    return {
      selectedBranch,
      selectedGroupId,
      updateChattingMember,
      updatingGroupData: isChanged ? updatingGroupData : null,
    };
  },
);

export const ConversationProvider = ({ children }) => {
  const groupListRef = useRef({});

  const { selectedBranch, selectedGroupId, updateChattingMember, updatingGroupData } = useSelector(memoizedReduxState);

  const [store, setStore] = useState({ ...DEFAULT_STORE });
  const [groupDetail, setGroupDetail] = useState({
    ...DEFAULT_GROUP_DATA,
  });

  const handleChangeGroup = useCallback(async selectedGroupId => {
    if (selectedGroupId && !groupListRef.current[selectedGroupId]) {
      const groupDetail = await getInteractor(StorageUtil.getCurrentPrefixKey()).LocalGroupService.get(selectedGroupId);
      groupListRef.current[selectedGroupId] = groupDetail;
    }

    if (groupListRef.current[selectedGroupId]?.id) {
      const newGroupDetail = groupListRef.current[selectedGroupId];
      setGroupDetail(deepCloneJsonObject(newGroupDetail));

      // Checking disable chatting or not
      const isInactive = await isDisableChatting(newGroupDetail);
      setStore(preState => ({ ...preState, isInactive: isInactive }));
    } else {
      setGroupDetail({ ...DEFAULT_GROUP_DATA });
    }
  }, []);

  const clickMedia = useCallback(
    fileInfo => setStore(preState => ({ ...preState, selectedMediaId: fileInfo.mediaId })),
    [],
  );

  useEffect(() => {
    setGroupDetail({ ...DEFAULT_GROUP_DATA });
    setStore({ ...DEFAULT_STORE });

    handleChangeGroup(selectedGroupId);
  }, [selectedGroupId, selectedBranch]);

  useEffect(() => {
    const groupMembersId = groupDetail.groupMembers.map(item => item.id);
    if (
      groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
      groupMembersId &&
      groupMembersId.includes(updateChattingMember.id)
    ) {
      setStore(preState => ({
        ...preState,
        isInactive: false === Boolean(updateChattingMember.state && updateChattingMember.status),
      }));
      handleChangeGroup(groupDetail.id);
    }
  }, [updateChattingMember]);

  useEffect(() => {
    if (updatingGroupData?.id) {
      // Refresh group data
      groupListRef.current[selectedGroupId] = { ...groupListRef.current[selectedGroupId], ...updatingGroupData };
      setGroupDetail(deepCloneJsonObject(groupListRef.current[selectedGroupId]));
    }
  }, [updatingGroupData]);

  return (
    <ConversationContext.Provider value={{ store, groupDetail, clickMedia }}>
      {children}

      <MediaCarouselDialog
        groupDetail={groupDetail}
        selectedMediaId={store.selectedMediaId}
        onClose={() => setStore(preState => ({ ...preState, selectedMediaId: null }))}
      />
    </ConversationContext.Provider>
  );
};

const PAGE_SIZE = 12;
const MediaCarouselDialog = ({ groupDetail, selectedMediaId, onClose }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [mediaFileList, setMediaFileList] = useState([]);

  const handleQueryMediaMessage = isNewer => async () => {
    let recordList = await getInteractor(prefixKey).LocalFileGroupService.getFiles({
      group_id: groupDetail.id,
      file_type: [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO],
      limit: PAGE_SIZE,
      isNewer,
      compare_date: mediaFileList[isNewer ? mediaFileList.length - 1 : 0].created,
    });

    if (!isNewer) recordList.reverse();
    recordList = await refactorFiles(prefixKey, recordList);
    const newFileList = uniqBy(isNewer ? mediaFileList.concat(recordList) : recordList.concat(mediaFileList), "id");

    setMediaFileList(newFileList);
  };

  const handleInitData = async () => {
    const [sourceMessageId, fileId] = selectedMediaId.split("_"); // <sourceMessageId>_<fileId>

    const selectedFile = await getSelectedFile(prefixKey, {
      groupId: groupDetail.id,
      attachmentId: fileId,
      sourceMessageId: sourceMessageId,
    });

    if (!(selectedFile && selectedFile.id)) return;

    // Show selected media file before
    const initList = await refactorFiles(prefixKey, [selectedFile]);
    setMediaFileList(() => initList);

    // Query file list around selected file
    const recordList = await getInteractor(prefixKey).LocalFileGroupService.getAroundFiles(selectedFile, {
      group_id: groupDetail.id,
      limit: PAGE_SIZE,
      file_type: [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO],
    });

    refactorFiles(prefixKey, recordList).then(fileList => setMediaFileList(fileList));
  };

  useEffect(() => {
    const isValid = groupDetail && groupDetail.id && selectedMediaId;
    if (!isValid) return;

    setMediaFileList([]);
    handleInitData();
  }, [groupDetail, selectedMediaId]);

  const selectedMediaIndex = mediaFileList.findIndex(fileItem => fileItem.metaData.mediaId === selectedMediaId);
  const isShowCarousel = selectedMediaIndex >= 0;

  return (
    <CarouselMedia
      open={isShowCarousel}
      onClose={onClose}
      data={mediaFileList}
      initialIndex={selectedMediaIndex}
      onScrollRightEnd={handleQueryMediaMessage(true)}
      onScrollLeftEnd={handleQueryMediaMessage(false)}
    />
  );
};

export const saveMessageInQueue = async ({
  prefixKey = StorageUtil.getCurrentPrefixKey(),
  groupId,
  threadId,
  sendType,
  content,
  parentId = null,
  mentionIds = [],
  forward,
}) => {
  const isValid = groupId && prefixKey;
  if (!isValid) {
    console.error("INVALID_MESSAGE: ", { groupId, prefixKey, isValid });
    throw new Error("INVALID_MESSAGE");
  }

  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(groupId);
    const isValidGroup = Boolean(
      groupDetail && groupDetail.id && Array.isArray(groupDetail.groupMembers) && groupDetail.groupMembers.length > 0,
    );
    if (!content || content === "" || false === Boolean(branchId) || false === isValidGroup) return;

    sendType = getSendType(sendType, content, groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal);

    const mentions = Array.isArray(mentionIds) ? mentionIds : convertString2JSON(mentionIds, []);
    const options = {
      encryption_f: groupDetail.encryptionF,
    };

    if (SystemConstant.ALLOW_DISAPPEAR_MESSAGE.includes(sendType) && false === Boolean(parentId)) {
      const groupSetting = await getInteractor(prefixKey).LocalGroupSettingService.findOne({
        group_id: groupId,
        setting_sub_type: SystemConstant.SETTING_SUB_TYPE.DISAPPEAR_MESSAGE,
      });

      if (groupSetting && groupSetting.state === SystemConstant.STATE.active) {
        options.disappearing_f = true;
        options.time_to_live = Number(groupSetting.value);
      }
    }

    const saveMessage = {
      account_id: accountId,
      branch_id: branchId,
      content: content,
      created: Date.now(),
      device_id: deviceId,
      group_id: groupId,
      id: uuid(),
      mentions: JSON.stringify(mentions),
      modified: 0,
      options: JSON.stringify(options),
      parent_id: parentId,
      send_type: sendType,
      sender_device_id: deviceId,
      sender_id: accountId,
      source_id: uuid(),
      state: 1,
      status: 1,
      thread_id: threadId,
      call_status: null,
    };

    if ([SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.file].includes(sendType) && !forward) {
      store.dispatch(
        ConversationActions.sendFileMessage(
          {
            groupId: groupId,
            sendType: sendType,
            content: content,
            parentId: parentId,
            branchId: branchId,
            mentionIdsArr: JSON.stringify(mentions),
            threadId: threadId,
            currentMessage: saveMessage,
            forward: forward,
          },
          StorageUtil.getCurrentPrefixKey(),
        ),
      );
    } else {
      store.dispatch(
        ConversationActions.sendMessage(
          {
            groupId: groupId,
            sendType: sendType,
            content: content,
            parentId: parentId,
            branchId: branchId,
            mentionIdsArr: JSON.stringify(mentions),
            threadId: threadId,
            currentMessage: saveMessage,
            forward: forward,
          },
          StorageUtil.getCurrentPrefixKey(),
        ),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

const getSelectedFile = async (prefixKey, { groupId, sourceMessageId, attachmentId }) => {
  const mediaFileType = [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO];
  const queryCondition = {
    group_id: groupId,
    source_message_id: sourceMessageId,
    file_id: attachmentId,
    file_type: mediaFileType,
  };
  const selectedFile = await getInteractor(prefixKey).LocalFileGroupService.findOne(queryCondition);

  // Try to store file_group record from sourceMessage
  if (!(selectedFile && selectedFile.id)) {
    const message = await getInteractor(prefixKey).LocalMessageService.findOne({
      source_id: sourceMessageId,
    });

    if (message && message.id) {
      await getInteractor(prefixKey).LocalFileGroupService.saveFromMessage([message]);
    }
  }

  return getInteractor(prefixKey).LocalFileGroupService.findOne(queryCondition);
};

const isDisableChatting = async group => {
  if (!group?.id) return true;

  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  // Checking receiver is active or not in case personal conversation
  const chattingMember = group.groupMembers.find(member => member.id !== accountId);
  const inactiveAccountIds = (
    await getInteractor(prefixKey).LocalBranchAccountService.getAllInactiveAccount(branchId)
  ).map(item => item.account_id);
  const deletedAccountIds = (
    await getInteractor(prefixKey).LocalBranchAccountService.getAllDeleteAccount(branchId)
  ).map(item => item.account_id);

  const isDeletedAccount = chattingMember && deletedAccountIds.includes(chattingMember.id);
  const isInactiveAccount = chattingMember && inactiveAccountIds.includes(chattingMember.id);
  const isDisable =
    group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal && (isDeletedAccount || isInactiveAccount);

  return isDisable;
};
