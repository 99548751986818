import React, { useEffect, useState } from "react";
import { Container, Box, Typography, TextField, Stack, Button, InputAdornment, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { getSavedServer } from "utils/branch.utils";
import { useAccount, useScreenLock } from "hooks";
import { AppAvatar } from "components";
import { LocalDeviceSettingService } from "services/local.service";
import { convertString2JSON, StorageUtil } from "utils";
import { convertScreenLockPassword } from "utils/view.utils";

const ScreenLockPage = () => {
  const classes = useStyles();
  const { t: getScreenLockLabel } = useTranslation(LangConstant.NS_SCREEN_LOCK);
  const { getScreenLockDetail, unlockScreenApp } = useScreenLock();

  const { currentAccount } = useAccount();
  const currentBranch = getSavedServer();

  const [password, setPassword] = useState("");
  const [localPassword, setLocalPassword] = useState("");
  const [error, setError] = useState("");
  const [isLockEnterPassword, setIsLockEnterPassword] = useState(false);
  const [unlockTime, setUnlockTime] = useState(0);

  const handleChangePassword = event => {
    const newPassword = convertScreenLockPassword(event.target.value);
    setPassword(newPassword);
    if (!isLockEnterPassword) setError("");
  };

  const handleSubmit = event => {
    event.preventDefault();

    const screenLockDetail = getScreenLockDetail();
    if (password !== localPassword) {
      const newRetries = screenLockDetail.retries + 1;
      if (newRetries >= AppConstant.MAX_RETRY_TIMES) {
        setIsLockEnterPassword(true);
        const unlockTime = getUnlockTime(newRetries);
        screenLockDetail.unlockTime = unlockTime;
        setUnlockTime(unlockTime);
      } else {
        setError(getScreenLockLabel("TXT_WRONG_PASSWORD"));
      }
      screenLockDetail.retries = newRetries;
      StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK, screenLockDetail);
    } else if (password === localPassword) {
      setError("");
      unlockScreenApp();
    }
  };

  const handleSetLocalPassword = async () => {
    const setting = await LocalDeviceSettingService.findOne({
      setting_type: AppConstant.DEVICE_SETTING_TYPE.lockScreen,
      state: SystemConstant.STATE.active,
    });

    if (setting && setting.id) {
      const settingOptions = convertString2JSON(setting.options, {});
      setLocalPassword(settingOptions.password);
    } else {
      setLocalPassword("");
    }
  };

  useEffect(() => {
    handleSetLocalPassword();

    const screenLockDetail = getScreenLockDetail();
    if (screenLockDetail && screenLockDetail.retries) {
      setUnlockTime(screenLockDetail.unlockTime);
    }
  }, []);

  useEffect(() => {
    let countdownTimeout = null;
    let countdownTime = unlockTime - Date.now();

    if (countdownTime > 1000) {
      countdownTimeout = setInterval(() => {
        if (countdownTime > 0) {
          const countdown = Math.round(countdownTime / 1000);
          setError(getScreenLockLabel("FM_LOCK_PASSWORD", { time: countdown }));
          countdownTime = countdownTime - 1000;
        } else {
          setError("");
          setIsLockEnterPassword(false);
          clearInterval(countdownTimeout);
        }
      }, 1000);
    } else {
      setIsLockEnterPassword(false);
    }

    return () => {
      if (countdownTimeout) clearInterval(countdownTimeout);
    };
  }, [unlockTime]);

  return (
    <Container className={classes.root}>
      <Stack className={classes.contentRoot}>
        <Stack alignItems="center" justifyContent="center" spacing={1} paddingTop={2}>
          <AppAvatar
            variant="square"
            size={60}
            src={currentBranch.branchIcon}
            name={currentBranch.name}
            isServerLogo={true}
          />
          <Typography variant="body2" color="#000000" fontSize={15}>
            {currentBranch.name}
          </Typography>
        </Stack>

        <Box component="form" onSubmit={handleSubmit}>
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <AppAvatar size={100} avatarId={currentAccount.avatarId} name={currentAccount.name} />
            <Typography textAlign="center" variant="h2" color="#000000" fontSize={15}>
              {currentAccount.name}
            </Typography>

            <TextField
              variant="outlined"
              placeholder={getScreenLockLabel("TXT_PASSWORD")}
              type="password"
              fullWidth
              size="small"
              value={password}
              error={Boolean(error)}
              helperText={error}
              sx={{ minHeight: 64 }}
              InputProps={{
                sx: { padding: 0 },
                inputProps: { style: { paddingLeft: 4, fontSize: 12, fontWeight: 400 } },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={password.length < 4 || isLockEnterPassword}
                      className={classes.submitBtn}
                    >
                      {getScreenLockLabel("LABEL_OPEN")}
                    </Button>
                  </InputAdornment>
                ),
              }}
              disabled={isLockEnterPassword}
              onChange={handleChangePassword}
            />
          </Stack>
        </Box>
        <Typography
          variant="caption"
          className={classes.note}
          dangerouslySetInnerHTML={{
            __html: getScreenLockLabel("TXT_FORGOT_PASSWORD_NOTE").replace(", ", ", <br/>"),
          }}
        />
      </Stack>
    </Container>
  );
};

export default ScreenLockPage;

/**
 *
 * @param {*} retries
 * @param {*} limitRetries: default = 3
 * @param {*} baseTime second
 * @returns millisecond
 */
const getUnlockTime = (retries, limitRetries = AppConstant.MAX_RETRY_TIMES, baseTime = 5) => {
  return Date.now() + (retries - limitRetries + 1) * baseTime * 1000;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100vw",
  },

  contentRoot: {
    width: "fit-content",
    height: "100%",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 20,
  },

  input: {
    height: 36,
    "& *": {
      maxHeight: 36,
    },
  },

  submitBtn: {
    width: 38,
    height: "100%",
    minWidth: 38,
    minHeight: 32,
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: 0,
    color: "white",
    fontSize: 12,
    fontWeight: 600,

    "&:disabled, &[disabled]": { backgroundColor: "#72B9E6", color: "white" },
  },

  helperError: {
    margin: "4px 0px",
    textAlign: "center",
    fontSize: 12,
  },

  note: {
    textAlign: "center",
    color: theme.palette.grey[300],
  },
}));
