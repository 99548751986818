import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { LocalAppNotificationService, LocalDbManagement, getInteractor } from "services/local.service";
import {
  CryptoUtil,
  StorageUtil,
  convertString2JSON,
  getDataFromPrefixKey,
  getPrefixKey,
  isExternalLink,
  toCamel,
  toSnake,
} from "utils";
import { checkCurrentBranchByPrefix, checkTriggerMessageUI, formatPagingParams } from "../../../sagas/saga.helper";
import { remoteApiFactory } from "services";
import { saveKeysOfDevice } from "../../../sagas/account-key.saga";
import store, { ConversationActions, SystemActions } from "redux-store";
import { updateThread } from "../../../sagas/thread.saga";
import { updateMessageStatus } from "../../../sagas/conversation-message.saga";
import { sortBy, uniqWith, isEqual, uniqBy } from "lodash";
import { getDevice } from "../device.service";
import { handleNewConversation } from "../conversation.service";
import { handleSendErrorMsg, handlingErrorMessage } from "./error-message";
import { handleCallingMessage } from "./call-message";
import { checkCurrentGroup, checkCurrentThread, checkFocusedApp, checkingPushNotification } from "utils/view.utils";
import { pushNotificationList } from "./push-notification";
import { checkValidGroupSettingOff } from "pages/HomePage/components/Account/SettingApp/SettingAppNotification/setting-time.helper";

export const getMessage = async (prefixKey, time2FetchMessage) => {
  try {
    const localTime2FetchMessage = StorageUtil.getItem(KeyConstant.KEY_TIME_2_FETCH_MESSAGE, prefixKey) || 0;
    let lastMessage = await getInteractor(prefixKey).LocalMessageService.getLastMessage();
    const minTime2Fetching = Math.min(lastMessage?.created, time2FetchMessage, localTime2FetchMessage) || 0;

    // Call API to get ${LIMIT_MESSAGE_API} message until get all new message on server
    const messageParams = formatPagingParams({
      isOnline: true,
      limit: 100,
      offset: 0,
      paging: 1,
      since_time: minTime2Fetching,
    });
    let isContinue = navigator.onLine;
    let currentPage = 1;

    while (isContinue) {
      const response = await remoteApiFactory.getBranchApi(prefixKey).getMessageList(toSnake(messageParams));
      const responseData = Array.isArray(response.data?.data) ? response.data.data : [];

      if (response.status === ApiConstant.STT_OK && responseData.length > 0) {
        const remoteMessageList = sortBy(responseData, [message => message.created]); // ASC sort by created
        await handleRemoteMessageList(prefixKey, remoteMessageList);
        await setUnreadInAppLogo();

        lastMessage = await getInteractor(prefixKey).LocalMessageService.getLastMessage();
        const maxTime2Fetching = Math.max(lastMessage?.created, localTime2FetchMessage) || 0;
        StorageUtil.setItem(KeyConstant.KEY_TIME_2_FETCH_MESSAGE, maxTime2Fetching, prefixKey);
      }

      isContinue =
        response.status === ApiConstant.STT_OK && responseData.length === messageParams.limit && navigator.onLine;
      if (isContinue) {
        // Update params for next api
        messageParams.offset = currentPage * messageParams.limit;
        currentPage = currentPage + 1;
      }
    }
  } catch (error) {
    console.log("fetch message error: ", error);
    return false;
  }

  return true;
};

const handleRemoteMessageList = async (prefixKey, remoteMessageList) => {
  if (!Array.isArray(remoteMessageList)) throw new Error("remoteMessageList is not array");

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const noticeMessageList = [];

  // 1. Synch keys of all devices to prepare decrypt remote message
  let deviceIds = remoteMessageList.map(item => ({
    deviceId: item.sender_device_id,
    accountId: item.sender_id,
  }));
  deviceIds = uniqWith(deviceIds, isEqual); // Remove duplicate data
  for (let deviceIndex = 0; deviceIndex < deviceIds.length; deviceIndex++) {
    const deviceFrom = deviceIds[deviceIndex];
    let deviceLocal = await getInteractor(prefixKey).LocalDeviceService.get(deviceFrom.deviceId);
    const deviceLocalByMac = await getInteractor(prefixKey).LocalDeviceService.getDeviceByMac(deviceFrom.deviceId);
    if (deviceLocalByMac && !deviceLocal) deviceLocal = deviceLocalByMac;
    if (!deviceLocal) {
      deviceLocal = await getDevice(prefixKey, deviceFrom.accountId, deviceFrom.deviceId);
    }

    if (deviceLocal && deviceLocal.key_f !== SystemConstant.DEVICE_KEY_STATE.correct) {
      await saveKeysOfDevice(prefixKey, {
        accountId: deviceLocal.account_id,
        deviceId: deviceLocal.id,
      });
    }
  }

  // 2. Handling message
  let isNeedUpdateUI = true;
  const uniqueGroupId = uniqBy(remoteMessageList, message => message.group_id).map(item => item.group_id);
  const handlingMessagePromises = uniqueGroupId.map(async groupId => {
    const group = await getGroup(groupId, prefixKey);
    if (group && group.id) {
      const messageInGroup = sortBy(
        remoteMessageList.filter(item => item.group_id === groupId),
        [message => message.created],
      );

      for (let i = 0; i < messageInGroup.length; i++) {
        const remoteMessage = toCamel(messageInGroup[i]);
        const localMessage = await getInteractor(prefixKey).LocalMessageService.get(remoteMessage.id);

        if (remoteMessage.sendType !== SystemConstant.SEND_TYPE.restoreData && !(localMessage && localMessage.id)) {
          const handlingMessageResult = await handleMessage(remoteMessage, group, prefixKey);
          isNeedUpdateUI = Boolean(handlingMessageResult.isNeedUpdateUI);

          // Check created time message and confirm message need to show or not
          if (checkingPushNotification(handlingMessageResult.noticeMessage?.message)) {
            noticeMessageList.push(handlingMessageResult.noticeMessage);
          }
        }
      }
    }
  });

  await Promise.all(handlingMessagePromises);

  // 3. Show notification
  const noticeMsgList = [];
  for (let index = 0; index < noticeMessageList.length; index++) {
    const { prefixKey, message } = noticeMessageList[index];

    // Skip show notification if message is read/ seen
    const seenMessageRecord = await getInteractor(prefixKey).LocalSeenMemberService.findOne({
      source_id: message.sourceId,
      member_account_id: accountId,
    });

    if (false === Boolean(seenMessageRecord && seenMessageRecord.id)) {
      noticeMsgList.push(message);
    }
  }
  await pushNotificationList(prefixKey, noticeMsgList);

  // Trigger disappear timeout => cover case my message is sent from multiple device
  const minDisappearTime = await getInteractor(prefixKey).LocalMessageService.getMinDisappearTime();
  if (minDisappearTime > 0) {
    store.dispatch(
      ConversationActions.conversationSet({
        disappearTime: minDisappearTime,
      }),
    );
  }

  // 4. Update message status
  // Send "READ" status to server if message is in selectedGroup
  // Message not in selected group: Send "RECEIVED" status to server
  const normalMessages = remoteMessageList.filter(
    mes =>
      mes.send_type !== SystemConstant.SEND_TYPE.senderKey &&
      mes.send_type !== SystemConstant.SEND_TYPE.keyError &&
      false === SystemConstant.ARR_CALLING_TYPES.includes(mes.send_type) &&
      mes.sender_id !== accountId,
  );

  const callMessages = remoteMessageList.filter(
    mes => SystemConstant.ARR_CALLING_TYPES.includes(mes.send_type) && mes.sender_id !== accountId,
  );

  const readNormalMsgIds = [];
  const receivedNormalMsgIds = [];
  normalMessages.forEach(mes => {
    const isNotUpdateLastMessage = SystemConstant.NOT_UPDATE_LAST_MESSAGE_SEND_TYPE.includes(mes.send_type);
    const isUpdateReadGroup =
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      false === Boolean(mes.thread_id) &&
      checkCurrentGroup(mes.group_id) &&
      checkFocusedApp();

    const isUpdateReadThread =
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      Boolean(mes.thread_id) &&
      checkCurrentThread(mes.thread_id) &&
      checkFocusedApp();

    if (isNotUpdateLastMessage || isUpdateReadGroup || isUpdateReadThread) {
      readNormalMsgIds.push(mes.id);
    } else if (
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      mes.status !== SystemConstant.MESSAGE_STATUS.received
    ) {
      receivedNormalMsgIds.push(mes.id);
    }
  });

  const readCallMsgIds = [];
  const receivedCallMsgIds = [];
  callMessages.forEach(mes => {
    if (
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      mes.call_status !== SystemConstant.MESSAGE_CALL_STATUS.missed
    ) {
      readCallMsgIds.push(mes.id);
    } else if (
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      mes.status !== SystemConstant.MESSAGE_STATUS.received
    ) {
      receivedCallMsgIds.push(mes.id);
    }
  });

  const readMessageIds = readNormalMsgIds.concat(readCallMsgIds);
  const receivedMessageIds = receivedNormalMsgIds.concat(receivedCallMsgIds);

  if (readMessageIds.length > 0) {
    await updateMessageStatus({
      data: {
        messageIds: readMessageIds,
        status: SystemConstant.MESSAGE_STATUS.read,
      },
      prefixKey,
    });
  }

  if (receivedMessageIds.length > 0) {
    await updateMessageStatus({
      data: {
        messageIds: receivedMessageIds,
        status: SystemConstant.MESSAGE_STATUS.received,
      },
      prefixKey,
    });
  }

  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  if (isCurrentBranch && isNeedUpdateUI) {
    store.dispatch(
      ConversationActions.conversationSet({
        isUpdateViewMode: Date.now(),
      }),
    );
  }
};

const getGroup = async (groupId, prefixKey) => {
  let group = await getInteractor(prefixKey).LocalGroupService.get(groupId);
  // Group is not exist in local db => sync group
  if (!(group && group.id)) {
    const isSynchGroup = await handleNewConversation(prefixKey, groupId);
    if (isSynchGroup) {
      group = await getInteractor(prefixKey).LocalGroupService.get(groupId);
    }

    // Check if selected group is draft => update selectedGroupId
    const groupMemberIds = group.groupMembers.map(item => item.id);
    const selectedGroupId = store.getState().conversationRedux.selectedGroupId;
    const selectGroupDetail = await getInteractor(prefixKey).LocalGroupService.get(selectedGroupId);
    const draftMemberIds = selectGroupDetail.draftGroupMembers;
    if (
      selectGroupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
      draftMemberIds &&
      isEqual(groupMemberIds.sort(), draftMemberIds.sort())
    ) {
      store.dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: groupId,
        }),
      );
      getInteractor(prefixKey).LocalGroupService.delete(selectGroupDetail.id);
    }
  }

  return group;
};

const handleMessage = async (newMessage, group, prefixKey) => {
  if (false === Boolean(newMessage) || false === Boolean(newMessage.id)) return;
  let isNeedUpdateUI = false;
  let isInvolvedThread = false;
  let noticeMessage;

  try {
    const ERROR_MSG_SEND_TYPE = [
      SystemConstant.SEND_TYPE.senderKeyDeliveryError,
      SystemConstant.SEND_TYPE.keyError,
      SystemConstant.SEND_TYPE.senderKey,
    ];
    if (ERROR_MSG_SEND_TYPE.includes(newMessage.sendType)) {
      await handlingErrorMessage(newMessage, group, prefixKey);
    } else {
      let checkSenderIdBlock = await getInteractor(prefixKey).LocalContactService.getContact(
        newMessage.accountId,
        newMessage.senderId,
      );
      if (newMessage.accountId === newMessage.senderId) {
        checkSenderIdBlock = null;
      }
      const isBlockedSender = checkSenderIdBlock && checkSenderIdBlock.status === SystemConstant.CONTACT_STATUS.block;

      if (isBlockedSender) {
        newMessage.status = SystemConstant.MESSAGE_STATUS.block;
      }

      const messageOptions = convertString2JSON(newMessage.options, {});
      const encryption_type =
        messageOptions?.encryption_f !== SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION
          ? SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION
          : SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;

      // Decryption message
      let decryptContent = null;
      if (newMessage.sendType === SystemConstant.SEND_TYPE.botMessage) {
        const [_, branchId] = getDataFromPrefixKey(prefixKey);
        const branch = await getInteractor(prefixKey).LocalBranchService.findOne({ id: branchId });
        const branchOptions = branch.options ? convertString2JSON(branch.options, {}) : {};
        const publicKeyRSA = branchOptions.public_key_rsa;
        const encryptKey = messageOptions.s_key;
        const encryptIv = messageOptions.iv;
        if (false === Boolean(publicKeyRSA && encryptKey && encryptIv))
          return false; // Skip execute message if not enough data to decrypt message;
        else {
          const key = await CryptoUtil.decryptRSA(encryptKey, publicKeyRSA);
          const iv = await CryptoUtil.decryptRSA(encryptIv, publicKeyRSA);

          decryptContent = await CryptoUtil.decryptCBC_v2(newMessage.content, key, iv);
          if (isExternalLink(decryptContent)) {
            const newMsgOptions = { ...messageOptions, is_link: 1 };
            newMessage.options = JSON.stringify(newMsgOptions);
          }
        }
      } else {
        if (encryption_type === SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION) {
          if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
            decryptContent = await getInteractor(prefixKey).LocalCryptoService.decryptE2EMessage(
              newMessage.senderId,
              newMessage.senderDeviceId,
              newMessage.groupId,
              newMessage.content,
            );
          } else {
            decryptContent = await getInteractor(prefixKey).LocalCryptoService.decryptE2EEMessage(
              newMessage.senderId,
              newMessage.senderDeviceId,
              newMessage.groupId,
              newMessage.content,
            );
          }
        } else {
          decryptContent = newMessage.content;
        }
      }

      // Decrypt fail: resend
      if (decryptContent === null) {
        await handleSendErrorMsg(newMessage, SystemConstant.SEND_TYPE.keyError, group, prefixKey);
        newMessage.sendType = SystemConstant.SEND_TYPE.keyError;

        isNeedUpdateUI =
          (await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)])) || isNeedUpdateUI;
      } else if (decryptContent) {
        newMessage.content = decryptContent;

        const checkError = await getInteractor(prefixKey).LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
          newMessage.senderDeviceId,
          newMessage.groupId,
          0,
        );

        if (checkError != null)
          await getInteractor(prefixKey).LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(
            newMessage.groupId,
            newMessage.senderDeviceId,
            0,
          );

        const localMsgSourceId = await getInteractor(prefixKey).LocalMessageService.findOne({
          source_id: newMessage.sourceId,
        });
        if (localMsgSourceId?.id) {
          newMessage.sendType = SystemConstant.SEND_TYPE.keyError;
        }

        isNeedUpdateUI =
          (await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)])) || isNeedUpdateUI;

        isInvolvedThread = updateThread(prefixKey, toSnake(newMessage));

        if (SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType)) {
          await handleCallingMessage(prefixKey, newMessage.id, group);
        }

        const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
        const groupSetting = await getInteractor(prefixKey).LocalGroupSettingService.findOne({
          account_id: accountId,
          group_id: newMessage.groupId,
          setting_sub_type: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
        });
        const isMutedGroup = checkValidGroupSettingOff(prefixKey, groupSetting, newMessage.groupId);
        const isBlockedOnGlobalBranch = Boolean(
          newMessage.branchId === SystemConstant.GLOBAL_BRANCH_ID && isBlockedSender,
        );
        if (!isMutedGroup && !isBlockedOnGlobalBranch && newMessage.status !== SystemConstant.MESSAGE_STATUS.read) {
          // Save message into noticeMessage that will show notification on OS
          noticeMessage = {
            prefixKey,
            message: newMessage,
            isInvolvedThread,
          };
        }

        // Trigger to UI
        if (checkTriggerMessageUI(prefixKey, newMessage)) {
          store.dispatch(ConversationActions.receivedRemoteMessage(newMessage));
        }
      }
    }
  } catch (error) {
    console.error({ error });

    isNeedUpdateUI =
      (await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)])) || isNeedUpdateUI;
  }

  return { isNeedUpdateUI, noticeMessage };
};

// Unread message + thread message + notification in all active branch
export const setUnreadInAppLogo = async () => {
  let allBranchUnread = 0;
  let allBranchUnreadObj = {}; // [{ branchId: totalUnread }]
  const activeBranchList = await LocalDbManagement.find({
    state: SystemConstant.STATE.active,
  });
  if (Array.isArray(activeBranchList) && activeBranchList.length > 0) {
    for (let index = 0; index < activeBranchList.length; index++) {
      const branch = activeBranchList[index];
      const branchPrefixKey = getPrefixKey(branch.account_id, branch.branch_id);
      const branchTotalUnreadInGroup = await getInteractor(branchPrefixKey).LocalGroupService.getTotalUnread();
      const branchTotalUnreadInThread =
        await getInteractor(branchPrefixKey).LocalThreadService.countTotalUnreadMessage();
      const numberUnreadNotice = (
        await getInteractor(branchPrefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(branch.branch_id)
      ).length;

      const totalUnread = branchTotalUnreadInGroup + branchTotalUnreadInThread + numberUnreadNotice;
      allBranchUnreadObj[branch.branch_id] = totalUnread;
      allBranchUnread = allBranchUnread + totalUnread;
    }
  }

  // Set count unread in all branch to App Logo
  LocalAppNotificationService.setBadgeCount(allBranchUnread);
  store.dispatch(
    SystemActions.systemSet({
      allBranchUnreadObj: allBranchUnreadObj,
    }),
  );
};
