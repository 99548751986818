import { useCallback, useEffect, useRef, useState } from "react";
import store, { ConversationActions, ConversationSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { CHAT_WRAPPER_ID, getDisplayMessage, refactorMessage, scrollToTopInbox } from "./ViewMode.helper";
import { StorageUtil } from "utils";
import { useCleanUpEffect } from "hooks";
import { checkCurrentGroup, getLatestMessage } from "utils/view.utils";
import StringFormat from "string-format";
import { FormatConstant, KeyConstant, SystemConstant } from "const";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";
import { getCommonLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const memoizedReduxState = createSelector(
  [ConversationSelectors.getChangePinList, state => state.contactRedux.updateSender],
  (changePinList, updateSender) => ({
    changePinList,
    updateSender: Boolean(updateSender && Array.isArray(updateSender.affectGroupIds) && updateSender.accountId)
      ? updateSender
      : null,
  }),
);

const useQueryMessage = ({ selectedGroupId, selectedThreadId, scrollToChildId }) => {
  const { isMounted } = useCleanUpEffect();
  const { showAlert } = useAlertContext();
  const scrollDataRef = useRef({ ...SCROLL_DATA_DEFAULT });
  const messageListRef = useRef([]);
  const reduxRef = useRef({});

  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { changePinList, updateSender } = useSelector(memoizedReduxState);

  const [pagination, setPagination] = useState(getDefaultViewPaging);

  // Overwrite message
  const setMessageList = useCallback(displayMessageList => {
    const message = displayMessageList.find(message => !checkCurrentGroup(message.groupId));

    if (isMounted()) {
      const isNotCurrentGroup = Boolean(message?.id);
      if (isNotCurrentGroup) resetData();
      else {
        messageListRef.current = displayMessageList;
        setPagination(preState => ({
          ...preState,
          showMessage: displayMessageList,
          isLoadMore:
            displayMessageList.length > 0 &&
            false === Boolean(scrollDataRef.current.isNewest && scrollDataRef.current.isOldest),
          isNewest: scrollDataRef.current.isNewest,
          isOldest: scrollDataRef.current.isOldest,
        }));
      }
    }
  }, []);

  // Handling when query new message list
  const updateMessageList = useCallback(async (records = [], isNewest = false) => {
    const newMessageList = await getDisplayMessage(records, reduxRef.current.groupId);
    const displayMessageList = isNewest
      ? uniqBy(newMessageList.concat(messageListRef.current), "id")
      : uniqBy(messageListRef.current.concat(newMessageList).reverse(), "id").reverse();
    const message = displayMessageList.find(message => !checkCurrentGroup(message.groupId));
    const isNotCurrentGroup = Boolean(message?.id);
    console.log("setMessageList", { isCurrentGroup: !isNotCurrentGroup, message });

    if (isMounted()) {
      if (isNotCurrentGroup) resetData();
      else {
        messageListRef.current = displayMessageList;
        setPagination({
          showMessage: displayMessageList,
          isLoadMore:
            displayMessageList.length > 0 &&
            false === Boolean(scrollDataRef.current.isNewest && scrollDataRef.current.isOldest),
          isNewest: scrollDataRef.current.isNewest,
          isOldest: scrollDataRef.current.isOldest,
        });
      }
    }
  }, []);

  const getOlderMessage = useCallback(async (limit = PAGING_SIZE) => {
    const isValid =
      !scrollDataRef.current.isOldest && ![PROCESS_STATUS.queryOldest].includes(scrollDataRef.current.processStatus);
    console.log("getOlderMessage", {
      isValid,
      isFetching: scrollDataRef.current.processStatus,
      isOldest: scrollDataRef.current.isOldest,
      isNewest: scrollDataRef.current.isNewest,
      groupDetail: reduxRef.current,
    });
    // Skip query if during get data from db
    if (!isValid) return;

    scrollDataRef.current.processStatus = PROCESS_STATUS.queryOldest;

    const olderMessage = messageListRef.current[messageListRef.current.length - 1] || { created: Date.now() };
    const records = await queryMessage(
      {
        limit,
        compareTime: olderMessage.created,
      },
      reduxRef.current,
    );
    scrollDataRef.current.isOldest = records.length < limit;

    if (records.length > 0) {
      await updateMessageList(records);
    }
    scrollDataRef.current.processStatus = PROCESS_STATUS.nothing;
  }, []);

  const getNewerMessage = useCallback(async (limit = PAGING_SIZE) => {
    const isValid =
      !scrollDataRef.current.isNewest && ![PROCESS_STATUS.queryNewest].includes(scrollDataRef.current.processStatus);
    console.log("getNewerMessage", {
      isValid,
      isFetching: scrollDataRef.current.processStatus,
      isNewest: scrollDataRef.current.isNewest,
      isOldest: scrollDataRef.current.isOldest,
      groupDetail: reduxRef.current,
    });

    // Skip query if during get data from db
    if (!isValid) return;

    scrollDataRef.current.processStatus = PROCESS_STATUS.queryNewest;

    const newerMessage = messageListRef.current[0] || { created: Date.now() };
    const records = await queryMessage(
      {
        limit,
        compareTime: newerMessage.created,
        isNewer: true,
      },
      reduxRef.current,
    );
    scrollDataRef.current.isNewest = records.length < limit;

    if (records.length > 0) {
      await updateMessageList(records, true);
    }
    scrollDataRef.current.processStatus = PROCESS_STATUS.nothing;
    console.log("getNewerMessage", { records, isNewest: scrollDataRef.current.isNewest, newerMessage });
  }, []);

  const scrollToLastMessage = useCallback(async () => {
    if (!scrollDataRef.current.isNewest) {
      resetData();
      await getOlderMessage();
    }

    scrollToTopInbox();
    console.log({ isNewest: scrollDataRef.current.isNewest });
  }, []);

  const handleInitData = useCallback(
    debounce(async scrollToChildId => {
      console.log("handleInitData", { ...reduxRef.current, scrollToChildId });

      const scrollMessage = scrollToChildId
        ? await getInteractor().LocalMessageService.findOne({
            source_id: scrollToChildId,
            state: SystemConstant.STATE.active,
            send_type: SystemConstant.DISPLAY_MESSAGE_TYPES,
          })
        : null;
      const lastMessage = scrollMessage ? await getLatestMessage(prefixKey, scrollMessage) : null;
      const displayScrollMsg = lastMessage ? await refactorMessage(lastMessage) : null;

      if (displayScrollMsg?.id && displayScrollMsg.groupId === reduxRef.current.groupId) {
        const isMyMessage = displayScrollMsg.senderId === StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
        // Get message in db and scroll to message if message is invisible
        const isScroll = scrollToMessage(scrollToChildId, isMyMessage);
        if (!isScroll) {
          // Else reset data
          resetData();

          scrollDataRef.current.processStatus = PROCESS_STATUS.scrollMessage;
          messageListRef.current = [displayScrollMsg];
          setMessageList([displayScrollMsg]);
          await getNewerMessage(PAGING_SIZE);
          await getOlderMessage(PAGING_SIZE);

          setTimeout(() => {
            scrollToMessage(scrollToChildId, isMyMessage);
            scrollDataRef.current.processStatus = PROCESS_STATUS.nothing;
          }, 1000);
        }
      } else {
        if (scrollToChildId) {
          showAlert({ content: getCommonLang("TXT_NOT_FOUND_MESSAGE"), alertProps: { severity: "error" } });
        }

        scrollDataRef.current.processStatus = PROCESS_STATUS.init;
        scrollDataRef.current.isNewest = true;
        // Get 2 message to display and then get next page later
        await getOlderMessage(2);
        await getOlderMessage(PAGING_SIZE * 2);
      }

      clearScrollId();
    }, 500),
    [],
  );

  const resetData = useCallback(() => {
    scrollDataRef.current = { ...SCROLL_DATA_DEFAULT };
    setPagination(getDefaultViewPaging);
    messageListRef.current = [];
  }, []);

  useEffect(() => {
    const isCurrentGroup = selectedGroupId && selectedGroupId === reduxRef.current.groupId;
    const isCurrentThread = selectedThreadId && selectedThreadId === reduxRef.current.threadId;
    const isSkipQuery = !scrollToChildId && (selectedThreadId ? isCurrentThread && isCurrentGroup : isCurrentGroup);
    if (isSkipQuery) return;

    const isChangeGroup = selectedGroupId && selectedGroupId !== reduxRef.current.groupId;
    const isChangeThread = selectedThreadId && selectedThreadId !== reduxRef.current.threadId;
    // Set default view mode if group is changed
    if (isChangeGroup || isChangeThread) {
      resetData();
    }
    reduxRef.current = { groupId: selectedGroupId, threadId: selectedThreadId };
    handleInitData(scrollToChildId);
  }, [selectedGroupId, selectedThreadId, scrollToChildId]);

  useEffect(() => {
    let isMounted = true;
    if (changePinList.sourceMessageId) {
      const msgUpdatePinIndex = pagination.showMessage.findIndex(
        item => item.sourceId === changePinList.sourceMessageId,
      );
      if (msgUpdatePinIndex >= 0) {
        const showMessage = [...pagination.showMessage];
        const oldMessage = showMessage[msgUpdatePinIndex];
        const isPinMessage = Boolean(changePinList.state);

        if (isMounted && Boolean(oldMessage.isPin) !== isPinMessage) {
          showMessage[msgUpdatePinIndex] = { ...oldMessage, isPin: isPinMessage };
          setMessageList(showMessage);
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [changePinList]);

  useEffect(() => {
    let isMounted = true;
    const isValid =
      updateSender && updateSender.accountId && updateSender.affectGroupIds.includes(reduxRef.current.groupId);

    if (isValid) {
      let isUpdated = false;
      const newMessageList = pagination.showMessage.map(messageItem => {
        if (messageItem.isAvatar && messageItem.senderId === updateSender.accountId) {
          messageItem.senderName = updateSender.name;
          isUpdated = true;
          return { ...messageItem };
        } else if (messageItem.sendType === SystemConstant.SEND_TYPE.leaveGroup) {
          isUpdated = true;
          // Update modified field to force update view
          return { ...messageItem, modified: Date.now() };
        }

        return messageItem;
      });

      if (isMounted && isUpdated && updateSender.affectGroupIds.includes(reduxRef.current.groupId)) {
        setMessageList(newMessageList);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [updateSender]);

  return {
    ...pagination,
    scrollDataRef: scrollDataRef,
    messageListRef: messageListRef,
    isQueryOldest: PROCESS_STATUS.queryOldest === scrollDataRef.current.processStatus,
    isQueryNewest: PROCESS_STATUS.queryNewest === scrollDataRef.current.processStatus,
    getNewerMessage,
    getOlderMessage,
    resetData,
    updateMessageList,
    setMessageList,
    scrollToLastMessage,
  };
};

export default useQueryMessage;

const getDefaultViewPaging = () => ({
  isLoadMore: true,
  isNewest: false,
  isOldest: false,
  showMessage: [],
});

const PROCESS_STATUS = {
  nothing: "nothing",
  init: "init", // Init data when changing group/ thread
  queryNewest: "queryNewest",
  queryOldest: "queryOldest",
  scrollMessage: "scrollMessage",
};
const PAGING_SIZE = 15;
const SCROLL_DATA_DEFAULT = {
  isNewest: false,
  isOldest: false,
  processStatus: PROCESS_STATUS.nothing,
};

const clearScrollId = () => {
  if (store.getState().conversationRedux.scrollToChildId) {
    store.dispatch(
      ConversationActions.conversationSet({
        scrollToChildId: null,
      }),
    );
  }
};

let backgroundTimeout;
let messageListEl = [];
export const SCROLL_TRIGGER_CLASS = "scroll-message";
const scrollToMessage = (scrollToChildId, isMyMessage) => {
  if (backgroundTimeout) {
    clearTimeout(backgroundTimeout);
    messageListEl.forEach(messageEl => {
      messageEl.classList.remove("my-message-highlight");
      messageEl.classList.remove("other-message-highlight");
    });
  }

  const elementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, scrollToChildId);
  const scrollToEl = document.getElementById(elementId);
  const messageContainer = document.getElementById(CHAT_WRAPPER_ID);

  if (scrollToEl) {
    const isScrollBottom = scrollToEl.offsetTop + scrollToEl.offsetHeight < messageContainer.offsetHeight * 0.5;
    if (isScrollBottom) scrollToEl.scrollIntoView({ block: "center", behavior: "smooth" });
    else scrollToTopInbox();
    messageListEl = scrollToEl.querySelectorAll(`.${SCROLL_TRIGGER_CLASS}`);

    // Highlight message
    if (messageListEl.length > 0) {
      messageListEl.forEach(messageEl => {
        messageEl.classList.add(isMyMessage ? "my-message-highlight" : "other-message-highlight");
      });

      backgroundTimeout = setTimeout(() => {
        messageListEl.forEach(messageEl => {
          messageEl.classList.remove(isMyMessage ? "my-message-highlight" : "other-message-highlight");
        });
      }, 1500);
    }
    return true;
  }

  return false;
};

const queryMessage = async (condition, group) => {
  const { groupId, threadId } = group;
  const isValid = Boolean(groupId || threadId);
  if (!isValid) {
    return [];
  }

  const queryCondition = {
    isShowDeletingMessage: true,
    ...condition,
  };
  return threadId
    ? await getInteractor().LocalMessageService.getThreadMessage(threadId, queryCondition)
    : await getInteractor().LocalMessageService.getGroupMessage(groupId, queryCondition);
};
