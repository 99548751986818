import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNSLang } from "utils/lang.utils";
import { LangConstant } from "const";
import { convertString2JSON, StorageUtil } from "utils";
import ChatTypography from "./ChatTypography";
import clsx from "clsx";
import { getInteractor } from "services/local.service";

const ScreenCaptureMessage = ({ message }) => {
  const classes = useStyles();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [content, setContent] = useState("");

  const handleScreenCaptureMsg = async message => {
    const msgContent = message.content ? convertString2JSON(message.content, {}) : {};
    const captureAccountId = msgContent.capture_account_id;
    const captureAccountDetails = (await getInteractor(prefixKey).LocalAccountService.get(captureAccountId)) || {};
    const content = getNSLang(LangConstant.NS_HOME_CONVERSATION, "FM_SCREEN_CAPTURE", {
      senderName: captureAccountDetails.name,
    });

    setContent(content);
  };

  useEffect(() => {
    handleScreenCaptureMsg(message);
  }, [message]);

  return (
    <Box className={classes.root}>
      <ChatTypography className={clsx(classes.content, "ellipsis")} messageContent={content} messageId={message.id} />
    </Box>
  );
};

export default ScreenCaptureMessage;

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  content: {
    maxWidth: "60%",
  },
});
