import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StorageUtil, getDataFromPrefixKey, isJSONString, toCamel } from "utils";
import { LangConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { ConversationActions } from "redux-store";
import { isGroupOrChannelType } from "pages/Call";
import { AvatarGroup } from "components";
import {
  NoticeContentText,
  NotificationContent,
  NotificationItem,
  NotificationTime,
  useNotificationStyles,
} from "./component";

const NotificationTextItem = ({ data, ...otherProps }) => {
  const classes = useNotificationStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const [account_id, branch_id] = getDataFromPrefixKey(prefixKey);

  const [senderDetail, setSenderDetail] = useState({
    avatarId: null,
    name: "",
    groupName: "",
    groupId: "",
    groupType: "",
  });
  const [message, setMessage] = useState({});

  const handleClickNotification = async () => {
    // Using find function because the function need to update sql to query data
    const groupList = await getInteractor().LocalGroupService.find({ account_id, branch_id, id: senderDetail.groupId });
    const group = groupList[0];

    if (group && group.id) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: message.groupId,
          threadingId: message.threadId,
          scrollToChildId: message.parentId || message.sourceId,
        }),
      );
    }
  };

  useEffect(() => {
    if (false === Boolean(data)) return;

    const queryParams = {
      ...data,
      options: isJSONString(data.options) ? toCamel(JSON.parse(data.options)) : {},
    };
    getInteractor(prefixKey)
      .LocalNotificationService.getNormalNotificationDetail(queryParams)
      .then(noticeDetail => {
        setSenderDetail({
          ...noticeDetail,
          name: noticeDetail.accountName,
          avatarId: noticeDetail.accountAvatar,
        });
        setMessage(toCamel({ ...noticeDetail.message }));
      });
  }, [data]);

  const isRead = data.status === SystemConstant.NOTIFICATION_STATUS.read;

  return (
    <NotificationItem className={isRead ? classes.readNotice : ""} onClick={handleClickNotification} {...otherProps}>
      <AvatarGroup avatarId={senderDetail.avatarId} group={{ groupName: senderDetail.name }} />
      <NotificationContent>
        <NoticeContentText
          className="regular-md-txt ellipsis"
          dangerouslySetInnerHTML={{
            __html: getLabel(
              isGroupOrChannelType(senderDetail.groupType)
                ? LangConstant.FM_MENTION_NOTIFICATION
                : LangConstant.FM_MENTION_INBOX,
              {
                accountName: senderDetail.name,
                groupName: senderDetail.groupName,
                message: message.content,
              },
            ),
          }}
        />
        <NotificationTime created={data.created} isUnread={!isRead} />
      </NotificationContent>
    </NotificationItem>
  );
};

export default NotificationTextItem;
