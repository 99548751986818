import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AvatarInfo } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import SearchPopup from "../SearchPopup";
import { isGroupOrChannelType } from "pages/Call";
import { useOnlineGroup } from "hooks";
import { getInteractor } from "services/local.service";
import { deepCloneJsonObject, StorageUtil } from "utils";
import { useConversationContext } from "../ConversationContext";
import TitleChatAction from "./TitleChatAction";
import { Error } from "@mui/icons-material";
import { withDrawerLayout } from "components/hoc";
import GroupInfoDrawer from "../../GroupInfoDrawer";
import { VerifiedIcon } from "components/icons";

const TitleChat = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const classes = useStyles();
  const searchAnchorRef = useRef(null);
  const {
    groupDetail: contextGroupDetail,
    store: { isInactive },
  } = useConversationContext();
  const isGroupOrChannel = isGroupOrChannelType(contextGroupDetail.groupType);

  const [isAdminGroup, setIsAdminGroup] = useState(false);
  const [groupDetail, setGroupDetail] = useState({
    groupMembers: [],
  });

  const handleChangeGroupDetail = async groupDetail => {
    const isValidData = groupDetail && groupDetail.id;
    if (false === isValidData) return;

    const accountGroupRecords = await getInteractor().LocalAccountGroupService.find({
      group_id: groupDetail.id,
      account_id: accountId,
      type: SystemConstant.ROLE_OBJ.admin,
    });
    setIsAdminGroup(Boolean(isGroupOrChannel && accountGroupRecords.length > 0));
  };

  useEffect(() => {
    setGroupDetail(deepCloneJsonObject(contextGroupDetail));
    handleChangeGroupDetail(contextGroupDetail);
  }, [contextGroupDetail]);

  return (
    <Stack className={classes.wapFlex} ref={searchAnchorRef}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <GroupInfo data={groupDetail} isInactive={isInactive} isAdminGroup={isAdminGroup} />

        <Stack direction="row">
          {!isInactive && <TitleChatAction groupDetail={groupDetail} />}
          <GroupInfoWithIcon data={groupDetail} isInactive={isInactive} isAdminGroup={isAdminGroup} />
        </Stack>
      </Stack>

      {searchAnchorRef.current && <SearchPopup anchor={searchAnchorRef.current} />}
    </Stack>
  );
};

export default memo(TitleChat);

const GroupInfoWithIcon = memo(
  withDrawerLayout(
    GroupInfoDrawer,
    <IconButton>
      <Error color="action" />
    </IconButton>,
  ),
);

const GroupAvatarAndName = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { groupDetail } = useConversationContext();
  const isGroupOrChannel = isGroupOrChannelType(groupDetail.groupType);
  const { isOnline } = useOnlineGroup(groupDetail.id);

  return (
    <AvatarInfo
      avatar={{
        avatarId: groupDetail.avatarId,
        type: groupDetail.groupType,
        name: groupDetail.groupName,
        isServerLogo: groupDetail.isBot,
      }}
      privateF={groupDetail.privateF}
      title={groupDetail.groupName}
      sendToMe={groupDetail.sendToMe}
      secondTitle={isGroupOrChannel ? getLabel("FM_MEMBERS_IN_GROUP", { number: groupDetail.groupMembers.length }) : ""}
      sx={{ p: 0 }}
      BadgeIcon={
        groupDetail.isBot ? (
          <VerifiedIcon />
        ) : (
          <Box
            className={clsx(
              classes.status,
              isOnline ? classes.onlineStatus : classes.offline,
              isGroupOrChannel && "hidden",
            )}
          />
        )
      }
      className={classes.titleCursor}
    />
  );
};

const GroupInfo = withDrawerLayout(GroupInfoDrawer, <GroupAvatarAndName />);

const useStyles = makeStyles(theme => ({
  wapFlex: {
    position: "relative",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 22,
  },

  titleChat: {
    color: theme.palette.black,
    position: "relative",
  },

  status: {
    position: "relative",
    width: 10,
    height: 10,
    borderRadius: "50%",
    bottom: 5,
    left: 0,
  },

  onlineStatus: {
    backgroundColor: "#2CC84A",
    border: "1px solid white",
  },

  offline: {
    backgroundColor: theme.palette.divider,
    border: `1px solid ${theme.palette.grey[300]}`,
  },

  groupTypeText: {
    color: theme.palette.grey[500],
  },

  titleCursor: {
    cursor: "pointer",
  },
}));
