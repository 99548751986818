import { AppDrawer, IOSSwitch } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { memo, useEffect, useState } from "react";
import AccountItem from "../../AccountItem";
import { useTranslation } from "react-i18next";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { SettingTimeDialog } from "./SettingTimeDialog";
import TurnOffNoticeNote from "./TurnOffNoticeNote";
import { checkValidGlobalGroupSettingOff } from "./setting-time.helper";
import { useDispatch, useSelector } from "react-redux";
import { NotificationSettingActions, NotificationSettingSelectors } from "redux-store";
import { updateAccountNotiSetting } from "services/notification-setting.service";

const DEFAULT_SETTING = {
  state: SystemConstant.STATE.active,
  value: -1, // setting.value + modified
};

const SettingAppNotificationDrawer = ({ open, onClose }) => {
  const { t: getSettingNoticeLabel } = useTranslation(LangConstant.NS_SETTING_NOTIFICATION);
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  let checkingTimeout;

  const remoteAccountSetting = useSelector(NotificationSettingSelectors.getUpdateAccountSetting);

  const [openSettingTime, setOpenSettingTime] = useState(false);
  const [localNewMsgSetting, setLocalNewMsgSetting] = useState(DEFAULT_SETTING);
  const [localThreadSetting, setLocalThreadSetting] = useState(SystemConstant.STATE.active);

  const handleSubmitTime = settingTime => {
    dispatch(
      NotificationSettingActions.updateAccountSetting(
        {
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
          created: Date.now(),
          state: SystemConstant.STATE.inactive,
          value: settingTime,
        },
        prefixKey,
      ),
    );
    setOpenSettingTime(false);
  };

  const handleChangeNewMsgSetting = () => {
    if (Boolean(localNewMsgSetting.state)) {
      setOpenSettingTime(true);
    } else {
      setOpenSettingTime(false);
      dispatch(
        NotificationSettingActions.updateAccountSetting(
          {
            settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
            created: Date.now(),
            state: SystemConstant.STATE.active,
          },
          prefixKey,
        ),
      );
    }
  };

  const handleChangeThreadSetting = () => {
    const newThreadState = !localThreadSetting;
    dispatch(
      NotificationSettingActions.updateAccountSetting(
        {
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL_THREAD,
          created: Date.now(),
          state: newThreadState,
        },
        prefixKey,
      ),
    );
  };

  const handleInit = async () => {
    const localNewMsgSetting = await getInteractor(prefixKey).LocalAccountSettingService.findOne({
      account_id: accountId,
      setting_sub_type: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
    });
    const isValidNewMsgSettingOff = checkValidGlobalGroupSettingOff(prefixKey, localNewMsgSetting);
    if (isValidNewMsgSettingOff) {
      setLocalNewMsgSetting({
        state: localNewMsgSetting.state,
        value: Boolean(localNewMsgSetting.value) ? Number(localNewMsgSetting.value) + localNewMsgSetting.modified : -1,
      });
    } else {
      setLocalNewMsgSetting(DEFAULT_SETTING);
    }

    const localThreadSetting = await getInteractor(prefixKey).LocalAccountSettingService.findOne({
      account_id: accountId,
      setting_sub_type: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL_THREAD,
    });

    if (localThreadSetting && localThreadSetting.id) {
      setLocalThreadSetting(localThreadSetting.state);
    } else {
      setLocalThreadSetting(SystemConstant.STATE.active);
    }
  };

  useEffect(() => {
    handleInit();

    return () => {
      if (checkingTimeout) clearTimeout(checkingTimeout);
    };
  }, []);

  useEffect(() => {
    if (
      localNewMsgSetting &&
      localNewMsgSetting.state === SystemConstant.STATE.inactive &&
      localNewMsgSetting.value > 0
    ) {
      const timeout = localNewMsgSetting.value - Date.now();
      if (checkingTimeout) clearTimeout(checkingTimeout);

      checkingTimeout = setTimeout(() => {
        // Update UI
        setLocalNewMsgSetting({
          state: SystemConstant.STATE.active,
          value: -1,
        });

        // Update to server
        updateAccountNotiSetting(
          prefixKey,
          SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
          Date.now(),
          SystemConstant.STATE.active,
        );

      }, timeout);
    }
  }, [localNewMsgSetting]);

  useEffect(() => {
    remoteAccountSetting.forEach(setting => {
      switch (setting.setting_sub_type) {
        case SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL:
          setLocalNewMsgSetting({
            state: setting.state,
            value: Boolean(setting.value) ? Number(setting.value) + setting.modified : -1,
          });
          break;

        case SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL_THREAD:
          setLocalThreadSetting(setting.state);

          break;

        default:
          break;
      }
    });
  }, [remoteAccountSetting]);

  return (
    <AppDrawer open={open} onClose={onClose} title={getSettingNoticeLabel("TXT_SETTING_APP_NOTIFICATION")}>
      <AccountItem
        text={getSettingNoticeLabel("TXT_NOTICE_NEW_MSG")}
        endIcon={<IOSSwitch checked={Boolean(localNewMsgSetting.state)} />}
        onClick={handleChangeNewMsgSetting}
      />

      {Boolean(localNewMsgSetting.state) ? (
        <AccountItem
          text={getSettingNoticeLabel("TXT_NOTICE_NEW_THREAD_MSG")}
          endIcon={<IOSSwitch checked={Boolean(localThreadSetting)} />}
          onClick={handleChangeThreadSetting}
        />
      ) : (
        <TurnOffNoticeNote time={localNewMsgSetting.value} />
      )}

      {openSettingTime && (
        <SettingTimeDialog onSubmitTime={handleSubmitTime} onClose={() => setOpenSettingTime(false)} />
      )}
    </AppDrawer>
  );
};

export default memo(SettingAppNotificationDrawer);
