import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { AvatarGroup } from "components";
import { getInteractor } from "services/local.service";
import {
  NoticeContentText,
  NotificationContent,
  NotificationItem,
  NotificationTime,
  useNotificationStyles,
} from "./component";

const NotificationOwnerItem = ({ data, ...otherProps }) => {
  const classes = useNotificationStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [notification, setNotification] = useState(data);

  useEffect(() => {
    getInteractor()
      .LocalBranchService.get(data.branchId)
      .then(tmpBranchDetail => {
        if (tmpBranchDetail) setNotification({ ...notification, avatarId: tmpBranchDetail.attachment_id });
      });
  }, [data]);

  const isRead = data.status === SystemConstant.NOTIFICATION_STATUS.read;

  return (
    <NotificationItem className={isRead ? classes.readNotice : ""} {...otherProps}>
      <AvatarGroup avatarId={notification.avatarId} />
      <NotificationContent>
        <NoticeContentText
          className="regular-md-txt ellipsis"
          dangerouslySetInnerHTML={{
            __html: getLabel(LangConstant.FM_OWNER_NOTIFICATION, {
              message: notification.content,
            }),
          }}
        />
        <NotificationTime created={notification.created} isUnread={!isRead} />
      </NotificationContent>
    </NotificationItem>
  );
};
export default NotificationOwnerItem;
