export const AUTO_DELETE_TIME_OPTIONS = [
  { value: 0, label: "Tắt" },
  { value: 30 * 1000, label: "30 giây" },
  { value: 60 * 1000, label: "1 phút" },
  { value: 5 * 60 * 1000, label: "5 phút" },
  { value: 60 * 60 * 1000, label: "1 giờ" },
  { value: 4 * 60 * 60 * 1000, label: "4 giờ" },
  { value: 8 * 60 * 60 * 1000, label: "8 giờ" },
  { value: 24 * 60 * 60 * 1000, label: "1 ngày" },
  { value: -1, label: "Tùy chỉnh" },
];

export const CUSTOM_TIME = [
  { label: "Giây", maxValue: 59 },
  { label: "Phút", maxValue: 59 },
  { label: "Giờ", maxValue: 23 },
  { label: "Ngày", maxValue: 6 },
  { label: "Tuần", maxValue: 4 },
];

export const convertLabel = time => {
  if (Boolean(time)) {
    const fixedTime = AUTO_DELETE_TIME_OPTIONS.find(item => item.value === Number(time));
    return fixedTime ? fixedTime.label : "Tùy chỉnh";
  } else {
    return "Tắt";
  }
};

export const convertTimeLabel = time => {
  if (Boolean(time)) {
    const fixedTime = AUTO_DELETE_TIME_OPTIONS.find(item => item.value === time);
    if (fixedTime) {
      return fixedTime.label;
    } else {
      let label = "";
      switch (true) {
        case time <= 59 * 1000:
          label = `${time / 1000} giây`;
          break;

        case time <= 59 * 60 * 1000:
          label = `${time / (60 * 1000)} phút`;
          break;

        case time <= 23 * 60 * 60 * 1000:
          label = `${time / (60 * 60 * 1000)} giờ`;
          break;

        case time <= 6 * 24 * 60 * 60 * 1000:
          label = `${time / (24 * 60 * 60 * 1000)} ngày`;
          break;

        case time <= 4 * 7 * 24 * 60 * 60 * 1000:
          label = `${time / (7 * 24 * 60 * 60 * 1000)} tuần`;
          break;

        default:
          break;
      }

      return label;
    }
  } else {
    return "Tắt";
  }
};

export const convertTimeObj2Value = timeObj => {
  let timeValue = 0;
  switch (timeObj.unit) {
    case "Giây":
      timeValue = Number(timeObj.time) * 1000;
      break;

    case "Phút":
      timeValue = Number(timeObj.time) * 60 * 1000;
      break;

    case "Giờ":
      timeValue = Number(timeObj.time) * 60 * 60 * 1000;
      break;

    case "Ngày":
      timeValue = Number(timeObj.time) * 24 * 60 * 60 * 1000;
      break;

    case "Tuần":
      timeValue = Number(timeObj.time) * 7 * 24 * 60 * 60 * 1000;
      break;

    default:
      break;
  }

  return timeValue;
};

export const getOptionsValue = time => {
  if (Boolean(time)) {
    const fixedTime = AUTO_DELETE_TIME_OPTIONS.find(item => item.value === Number(time));
    return fixedTime ? fixedTime.value : -1;
  } else {
    return 0;
  }
};
