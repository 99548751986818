import { convertString2JSON } from "utils";

export interface IEventDto {
  event_id: string;
  source_event_id: string;
  subscriber_device_id: string;
  subscriber_account_id: string;
  state: number;
  topic: string;
  priority: string;
  on_route: string;
  type: string;
  subtype: string;
  content: string;
  details: null | { [x: string]: unknown };
  created: number;
  modified: number;
  ttl: number;
  retry: number | undefined;
}

export default class EventDto implements IEventDto {
  private _contentObj!: {
    branch_id?: string;
    account_id?: string;
    device_id?: string;
    group_id?: string;
    source_id?: string;
    account_ids?: string[];
    room_id?: string;
    message_source_id?: string;
    attachment_id?: string;
    call_history_ids?: string[];
    room_ids?: string[];
    notification_id?: string;
    state?: number;
    setting_sub_type_list?: number[];
    thread_id?: string;
  };

  constructor(data: IEventDto) {
    this.event_id = data.event_id;
    this.source_event_id = data.source_event_id;
    this.subscriber_device_id = data.subscriber_device_id;
    this.subscriber_account_id = data.subscriber_account_id;
    this.state = Number(data.state) || 0;
    this.topic = data.topic;
    this.priority = data.priority;
    this.on_route = data.on_route;
    this.type = data.type;
    this.subtype = data.subtype;
    this.content = data.content;
    this.details = data.details;
    this.created = data.created;
    this.modified = data.modified;
    this.ttl = data.ttl;
    this.retry = data.retry;
  }

  get contentObj() {
    if (this._contentObj) return this._contentObj;

    this._contentObj = convertString2JSON(this.content, {});

    return this._contentObj;
  }

  event_id: string;
  source_event_id: string;
  subscriber_device_id: string;
  subscriber_account_id: string;
  state: number;
  topic: string;
  priority: string;
  on_route: string;
  type: string;
  subtype: string;
  content: string;
  details: { [x: string]: unknown } | null;
  created: number;
  modified: number;
  ttl: number;
  retry: number | undefined;
}
