import { Avatar, Box, Stack, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { formatSentTime } from "utils/date.utils";

export const NotificationTime = ({ created, isUnread }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <NoticeTimeText>{formatSentTime(created)}</NoticeTimeText>
      {isUnread && <NoticeUnreadIcon />}
    </Stack>
  );
};

export const NotificationItem = styled(Stack)(props => ({
  padding: "12px 14px",
  borderTop: "0.5px solid " + props.theme.palette.divider,
  cursor: "pointer",
  flexDirection: "row",

  "&:hover": {
    backgroundColor: props.theme.palette.grey[100],
  },
}));

export const NoticeTimeText = styled(Typography)({
  lineHeight: "1rem",
  marginTop: 5,
  fontSize: "0.625rem",
  fontWeight: 400,
});

const NoticeUnreadIcon = styled(Box)({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: "#FF625A ",
});

export const NoticeAvatar = styled(Avatar)({
  width: 50,
  height: 50,
  color: "#000000",
  background: "#CBCACA",
  flexShrink: 0,
});

export const NotificationContent = styled(Box)({
  maxWidth: "calc(100% - 50px)",
  height: "100%",
  paddingLeft: 16,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const NoticeContentText = styled(Typography)(props => ({
  lineHeight: "1rem",
  color: props.theme.palette.black,
  WebkitLineClamp: 3,
  lineBreak: "auto",
}));

export const useNotificationStyles = makeStyles({
  readNotice: {
    opacity: 0.6,
  },
});
