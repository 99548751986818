import { KeyConstant, SystemConstant } from "const";
import { checkValidGroupSettingOff } from "pages/HomePage/components/Account/SettingApp/SettingAppNotification/setting-time.helper";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationSettingActions, NotificationSettingSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";

const useMuteGroup = groupId => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const updateGroupSetting = useSelector(NotificationSettingSelectors.getUpdateGroupSetting);

  const [isMuted, setIsMuted] = useState(false);

  // Setting NOTIFICATION_GROUP for only personal group
  const mutePersonalGroup = useCallback(isMuted => {
    dispatch(
      NotificationSettingActions.updateGroupSetting(
        {
          groupId: groupId,
          settingSubType: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
          created: Date.now(),
          state: Number(isMuted),
        },
        StorageUtil.getCurrentPrefixKey(),
      ),
    );
  }, []);

  const checkGroupNoticeStt = useCallback(() => {
    getInteractor(prefixKey)
      .LocalGroupSettingService.findOne({
        account_id: accountId,
        group_id: groupId,
        setting_sub_type: SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
      })
      .then(setting => {
        setIsMuted(checkValidGroupSettingOff(prefixKey, setting, groupId));
      });
  }, [groupId]);

  useEffect(() => {
    checkGroupNoticeStt();
  }, [groupId]);

  useEffect(() => {
    if (groupId && updateGroupSetting && updateGroupSetting.groupId === groupId) {
      const updateList = updateGroupSetting.updateList;
      const settingGroup = updateList.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
      );
      if (settingGroup) {
        setIsMuted(checkValidGroupSettingOff(prefixKey, settingGroup, groupId));
      }
    }
  }, [updateGroupSetting, groupId]);

  return { isMuted, mutePersonalGroup, checkGroupNoticeStt };
};

export default useMuteGroup;
