import { PersonAddAltOutlined } from "@mui/icons-material";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { getNSLang } from "utils/lang.utils";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import {
  NoticeAvatar,
  NoticeContentText,
  NoticeTimeText,
  NotificationContent,
  NotificationItem,
  NotificationTime,
  useNotificationStyles,
} from "./component";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const NotificationBranchReqItem = ({ data, ...otherProps }) => {
  const classes = useNotificationStyles();
  const branchReqStyles = useStyles();
  const dispatch = useDispatch();

  const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO);

  const handleSelectNotice = () => {
    dispatch(
      SystemActions.systemSet({
        requestNotice: data.requestNotice,
      }),
    );
  };

  const isAccepted = data.state === SystemConstant.BRANCH_REQ_NOTICE_STATE.active;
  const isRejected = data.state === SystemConstant.BRANCH_REQ_NOTICE_STATE.reject;
  const isRead = data.status === SystemConstant.NOTIFICATION_STATUS.read;

  return (
    <NotificationItem
      className={clsx(isRead && classes.readNotice, (isAccepted || isRejected) && branchReqStyles.disabled)}
      onClick={handleSelectNotice}
      {...otherProps}
    >
      <NoticeAvatar>
        <PersonAddAltOutlined />
      </NoticeAvatar>
      <NotificationContent>
        <NoticeContentText fontWeight={700} fontSize="0.875rem">
          {getNSLang(LangConstant.NS_HOME, "FM_REQUEST_FROM", {
            serverName: branchInfo.name,
            accountName: data.requestNotice.name,
          })}
        </NoticeContentText>
        <NoticeTimeText>
          {isAccepted &&
            getNSLang(LangConstant.NS_HOME, "FM_ACCEPTED_REQUEST", { accountName: data.requestNotice.name })}
          {isRejected &&
            getNSLang(LangConstant.NS_HOME, "FM_REJECTED_REQUEST", { accountName: data.requestNotice.name })}
        </NoticeTimeText>
        <NotificationTime created={data.created} isUnread={!isRead} />
      </NotificationContent>
    </NotificationItem>
  );
};

export default NotificationBranchReqItem;

const useStyles = makeStyles({
  disabled: {
    pointerEvents: "none",
    cursor: "default",
  },
});
