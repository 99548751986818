import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Logout } from "@mui/icons-material";
import clsx from "clsx";
import { SystemConstant, LangConstant, KeyConstant } from "const";
import { showConfirmLeaveGroup } from "utils/view.utils";
import DialogGroupMember from "../../GroupInfoDrawer/DialogGroupMember";
import ChooseAdminDrawer from "../../GroupInfoDrawer/GroupMember/ChooseAdminDrawer";
import { ConversationActions } from "redux-store";
import { getCommonLang } from "utils/lang.utils";
import { StorageUtil } from "utils";
import DialogConfirmLeave from "../../AddingContact/DialogConfirmLeave";
import { getSavedServer } from "utils/branch.utils";

const ContactGroupActionMenu = ({ anchorEl, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [isConfirmLeave, setIsConfirmLeave] = useState(false);
  const [isChooseAdmin, setIsChooseAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [thirdRowContent, setThirdRowContent] = useState({
    content: "",
    icon: null,
    function: null,
  });

  const onLeaveGroup = () => {
    showConfirmLeaveGroup(data, setIsConfirmLeave, setIsChooseAdmin);
  };

  const onClickSetAdmin = () => {
    onClose();
    setIsAdmin(true);
    setIsChooseAdmin(false);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmLeave(false);
    onClose();
  };

  const handleLeaveGroup = () => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const currentBranchId = getSavedServer().id;
    dispatch(
      ConversationActions.sendMessage({
        groupId: data.id,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: accountId,
        parentId: null,
        deviceList: [],
        branchId: currentBranchId,
        mentionIdsArr: [],
        threadId: null,
        removingId: accountId,
      }),
    );

    handleCloseConfirmDialog();
  };

  useEffect(() => {
    let result = { ...thirdRowContent };

    result.content =
      data.groupType === SystemConstant.GROUP_CHAT_TYPE.group
        ? getLabel("TXT_LEAVE_GROUP")
        : getLabel("TXT_LEAVE_CHANNEL");
    result.icon = <Logout />;
    result.function = onLeaveGroup;

    setThirdRowContent(result);
  }, [data, anchorEl]);

  return (
    <>
      <Menu
        open={Boolean(anchorEl)}
        onClose={onClose}
        classes={{
          root: classes.chatMenuRoot,
          paper: classes.chatMenuPaper,
          list: classes.chatMenuList,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
        }}
      >
        <MenuItem className={clsx(classes.menuItem, classes.lastRow)} onClick={thirdRowContent.function}>
          {thirdRowContent.icon} {thirdRowContent.content}
        </MenuItem>
      </Menu>

      <DialogConfirmLeave
        open={isConfirmLeave}
        onClose={handleCloseConfirmDialog}
        title={getLabel("TXT_LEAVE_GROUP")}
        content={getLabel("TXT_CONTENT_BUTTON_GROUP")}
        submitProps={{
          submitText: getLabel("TXT_LEAVE_GROUP_INFO"),
          onClick: handleLeaveGroup,
        }}
        cancelProps={{
          cancelText: getCommonLang("TXT_CANCEL"),
          onClick: handleCloseConfirmDialog,
        }}
      />

      <DialogGroupMember
        open={isChooseAdmin}
        onClickSetAdmin={onClickSetAdmin}
        onClose={() => {
          setIsChooseAdmin(false);
          onClose();
        }}
        groupDetail={data}
        subTitle={
          data.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
            ? getLabel("TXT_SUBTITLE_LEAVE_CHANNEL")
            : getLabel("TXT_DES_BUTTON_CONFIRM")
        }
        confirmButtonText={
          data.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
            ? getLabel("TXT_LEAVE_CHANNEL")
            : getLabel("TXT_LEAVE_GROUP")
        }
      />
      <ChooseAdminDrawer
        dataAdmin={data.groupMembers}
        onClose={() => {
          setIsAdmin(false);
          onClose();
        }}
        open={isAdmin}
        groupId={data.id}
      />
    </>
  );
};

ContactGroupActionMenu.propTypes = {
  anchorEl: PropTypes.object,

  onClose: PropTypes.func,

  data: PropTypes.shape({
    type: PropTypes.number,
  }),
};

export default ContactGroupActionMenu;

const useStyles = makeStyles(theme => ({
  chatMenuRoot: {},

  chatMenuPaper: {
    borderRadius: 20,
    padding: "12px 10px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "50%",
      left: -10,
      width: 20,
      height: 20,
      backgroundColor: theme.palette.white,
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },

  chatMenuList: {
    padding: 0,
  },

  menuItem: {
    borderRadius: 10,
    padding: "10px 14px",
    fontSize: "0.9375rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    color: "#1E272E",

    "&::hover": {
      backgroundColor: "#F2F2F2",
    },

    "&>svg": {
      marginRight: 10,
    },
  },

  leftArrow: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid blue",
    position: "absolute",
  },

  lastRow: {
    color: "#FF2C28",
  },
}));
