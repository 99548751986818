import MigrateVersion48 from "./MigrateVersion48";
import MigrateVersion51 from "./MigrateVersion51";
import MigrateVersion56 from "./MigrateVersion56";

const HandlingMigrateVersion = () => {
  return (
    <>
      <MigrateVersion48 />
      <MigrateVersion51 />
      <MigrateVersion56 />
    </>
  );
};
export default HandlingMigrateVersion;
