import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { BranchSelectors, ConversationActions, ConversationSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";

const HandlingDisappearMsg = () => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const currentDisappearTimeRef = useRef(0);
  const isExecutingRef = useRef(false);

  let disappearTimeout;

  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);
  const disappearTimeRedux = useSelector(ConversationSelectors.getDisappearTime);

  const [disappearTimeState, setDisappearTimeState] = useState(0); // min disappear_time in local

  const handleDisappearMsg = async disappearTime => {
    const disappearMsgList = await getInteractor(prefixKey).LocalMessageService.getDisappearMsg(disappearTime);
    if (false === Boolean(Array.isArray(disappearMsgList) && disappearMsgList.length > 0)) {
      isExecutingRef.current = false;
      return;
    }

    /**
     * Update message
     * Delete local file
     *
     * Update related data: thread, file, lastMessage
     *
     * write function to update all related logic when disappear message: thread, lastMessage
     */
    await getInteractor(prefixKey).LocalMessageService.updateDisappearMessage(disappearMsgList);
    isExecutingRef.current = false;

    // Trigger update UI
    const currentDisappearMsgList = store.getState().conversationRedux.disappearMsg || [];
    dispatch(
      ConversationActions.conversationSet({
        disappearMsg:
          currentDisappearMsgList.length > 100 ? disappearMsgList : currentDisappearMsgList.concat(disappearMsgList),
      }),
    );

    // Get min disappear time in local db => update disappearTime state => execute timeout
    const minDisappearTime = await getInteractor(prefixKey).LocalMessageService.getMinDisappearTime();
    if (minDisappearTime !== 0 && minDisappearTime !== disappearTimeState) setDisappearTimeState(minDisappearTime);
  };

  useEffect(() => {
    return () => {
      if (disappearTimeout) clearTimeout(disappearTimeout);
    };
  }, [selectedServerRedux]);

  useEffect(() => {
    if (
      false === isExecutingRef.current ||
      (isExecutingRef.current && disappearTimeState < currentDisappearTimeRef.current)
    ) {
      if (disappearTimeout) clearTimeout(disappearTimeout);
      isExecutingRef.current = true;

      const currentTimestamp = Date.now();
      const queryTime = Math.max(Number(disappearTimeState), currentTimestamp);
      currentDisappearTimeRef.current = queryTime;

      const timeout = Math.max(Math.abs(currentTimestamp - queryTime), 500);

      disappearTimeout = setTimeout(() => {
        handleDisappearMsg(queryTime);
      }, timeout);
    }
  }, [disappearTimeState]);

  // add useEffect listener redux state => update disappearTime
  useEffect(() => {
    if (disappearTimeRedux > 0 && disappearTimeRedux !== disappearTimeState) setDisappearTimeState(disappearTimeRedux);
  }, [disappearTimeRedux]);

  return null;
};
export default HandlingDisappearMsg;
