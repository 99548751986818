import React, { memo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { getCreatedTimeText, useMessageStyle } from "../ChatItem.helper";
import { makeStyles } from "@mui/styles";
import SingleMedia from "./SingleMedia";
import MultipleMedia from "./MultipleMedia";
import { AppAvatar } from "components";
import { convertJSONObject, convertString2JSON, StorageUtil, toCamel } from "utils";
import { KeyConstant } from "const";
import { getMessageFileList } from "utils/message.utils";
import { PushPin, TimerOutlined } from "@mui/icons-material";
import { SCROLL_TRIGGER_CLASS } from "../../useQueryMessage";

const ImageMessage = ({ message, isMine }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();

  const fileObj = toCamel(convertJSONObject(convertString2JSON(message.content)));
  const caption = String(fileObj.contentMessage || "");
  const messageFileList = getMessageFileList(message);

  const isAvatar = Boolean(message.isAvatar);
  const msgCreatedTime = getCreatedTimeText(message);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const isCurrentDevice = message.senderDeviceId === deviceId;

  return (
    <Stack direction="row" spacing={2}>
      {!isMine && (
        <AppAvatar avatarId={message.avatarId} size={40} className={!isAvatar ? messengerClasses.hiddenAvatar : ""} />
      )}

      <Box>
        {!isMine && msgCreatedTime && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            {message.isDisappear && <TimerOutlined className={classes.timerIcon} />}
            <Typography className={clsx("ellipsis", messengerClasses.createdTimeText, "msg-sender-time")}>
              {msgCreatedTime}
            </Typography>
          </Stack>
        )}

        <Stack direction={isMine ? "row-reverse" : "row"}>
          <Box className={classes.previewBox}>
            <ImagePreview
              fileInfoList={messageFileList}
              caption={caption}
              groupId={message.groupId}
              isMine={isMine}
              mentions={message.mentions}
              isCurrentDevice={isCurrentDevice}
              messageId={message.id}
            />
          </Box>
          {(message.isPin || message.isDisappear) && (
            <Stack justifyContent="space-between" mr={0.5}>
              <Box>{message.isPin && <PushPin className={classes.pinIcon} />}</Box>
              {isMine && message.isDisappear && <TimerOutlined className={classes.timerIcon} />}
            </Stack>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(ImageMessage);

export const ImagePreview = ({
  fileInfoList,
  groupId,
  isMine,
  caption,
  mentions,
  onClick,
  imageWidth,
  isCurrentDevice,
  messageId,
}) => {
  const isSingleMedia = fileInfoList.length === 1;
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const hasSpacingClass = Boolean(caption);

  return (
    <Stack
      alignItems="center"
      className={clsx(
        messengerClasses.messengerRoot,
        caption && isMine && messengerClasses.myMessage,
        hasSpacingClass && messengerClasses.spacing,
        classes.media,
        !isSingleMedia && classes.containerBox,
        SCROLL_TRIGGER_CLASS,
      )}
    >
      {isSingleMedia ? (
        <SingleMedia
          fileInfo={fileInfoList[0] || null}
          groupId={groupId}
          isMine={isMine}
          mentions={mentions}
          caption={caption}
          onClick={onClick}
          isCurrentDevice={isCurrentDevice}
          messageId={messageId}
        />
      ) : (
        <MultipleMedia
          fileList={fileInfoList}
          groupId={groupId}
          isMine={isMine}
          onClick={onClick}
          imageWidth={imageWidth}
          isCurrentDevice={isCurrentDevice}
        />
      )}
    </Stack>
  );
};

const useStyles = makeStyles(theme => ({
  media: {
    width: "100%",
  },

  containerBox: {
    height: "fit-content",
    backgroundColor: theme.palette.grey[100],
  },

  previewBox: {
    width: "100%",
    minWidth: 450,
    maxWidth: 456,
    borderRadius: 20,
    border: "solid 1px",
    borderColor: theme.palette.grey[100],
  },

  pinIcon: {
    fontSize: 14,
    transform: "rotate(45deg)",
    color: theme.palette.grey[500],
  },

  timerIcon: {
    fontSize: 14,
    color: theme.palette.grey[500],
  },
}));
