import { useEffect } from "react";
import { SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import { useDispatch } from "react-redux";

import HandlingUnreadMessage from "./HandlingUnreadMessage";
import HandlingBlockAccount from "./HandlingBlockAccount";
import HandlingOnlineStatus from "./HandlingOnlineStatus";
import HandlingBranchInvite from "./HandlingBranchInvite";
import HandlingFocusApp from "./HandlingFocusApp";

import HandleAppEvents from "./HandleAppEvents";
import HandlingMigrateVersion from "./HandlingMigrateVersion";
import HandleScreenLock from "./HandleScreenLock";
import HandlingRequestNotice from "./HandlingRequestNotice";
import HandlingDisappearMsg from "./HandlingDisappearMsg";

const HandlingParallel = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SystemActions.synchronizeData(StorageUtil.getCurrentPrefixKey()));
  }, []);

  return (
    <>
      <HandlingBlockAccount />
      <HandlingUnreadMessage />
      <HandlingOnlineStatus />
      <HandlingBranchInvite />
      <HandlingFocusApp />

      <HandleAppEvents />
      <HandlingMigrateVersion />
      <HandleScreenLock />

      <HandlingRequestNotice />
      <HandlingDisappearMsg />
    </>
  );
};
export default HandlingParallel;
