import { LangConstant, SystemConstant } from "const";
import { updateAccountNotiSetting, updateGroupNotiSetting } from "services/notification-setting.service";
import { getNSLang } from "utils/lang.utils";

export const SETTING_TIME = [
  { value: -1, label: getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_UNTIL_TURN_ON") },
  { value: 60 * 60 * 1000, label: "1 giờ" },
  { value: 4 * 60 * 60 * 1000, label: "4 giờ" },
  { value: 8 * 60 * 60 * 1000, label: "8 giờ" },
];

const ONE_HOUR = 60 * 60 * 1000;

export const convertTime2Label = time => {
  let label = "";
  if (Boolean(time)) {
    const expiredTime = time - Date.now();
    const expiredHour = Math.ceil(expiredTime / ONE_HOUR) || 1;
    label = `${expiredHour} giờ`;
  } else {
    label = getNSLang(LangConstant.NS_SETTING_NOTIFICATION, "TXT_UNTIL_TURN_ON");
  }

  return label;
};

export const checkValidGlobalGroupSettingOff = (prefixKey, globalGroupSetting) => {
  if (false === Boolean(globalGroupSetting && globalGroupSetting.id)) return false;

  const expiredTime = Number(globalGroupSetting.value) + globalGroupSetting.modified;

  const isValidGlobalGroupSetting =
    globalGroupSetting.state === SystemConstant.STATE.inactive &&
    (expiredTime > Date.now() || false === Boolean(globalGroupSetting.value));

  // Check need to update setting state to server
  const isNeed2UpdateServer =
    globalGroupSetting.state === SystemConstant.STATE.inactive &&
    Boolean(globalGroupSetting.value) &&
    expiredTime <= Date.now();

  if (isNeed2UpdateServer) {
    updateAccountNotiSetting(
      prefixKey,
      SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GLOBAL,
      Date.now(),
      SystemConstant.STATE.active,
    );
  }

  return isValidGlobalGroupSetting;
};

// Checking off setting valid
export const checkValidGroupSettingOff = (prefixKey, groupSetting, groupId) => {
  if (false === Boolean(groupSetting && groupSetting.id)) return false;

  const expiredTime = Number(groupSetting.value) + groupSetting.modified;

  const isValidGroupSettingOff =
    groupSetting.state === SystemConstant.STATE.inactive &&
    (expiredTime > Date.now() || false === Boolean(groupSetting.value));

  // Check need to update setting state to server
  const isNeed2UpdateServer =
    groupSetting.state === SystemConstant.STATE.inactive && Boolean(groupSetting.value) && expiredTime <= Date.now();
  if (isNeed2UpdateServer) {
    updateGroupNotiSetting(
      prefixKey,
      groupId,
      SystemConstant.SETTING_SUB_TYPE.NOTIFICATION_GROUP,
      Date.now(),
      SystemConstant.STATE.active,
    );
  }

  return isValidGroupSettingOff;
};
