import { useCallback, useEffect, useState } from "react";
import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { AppDrawer, WarningNote } from "components";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import theme from "theme/material";
import { AUTO_DELETE_TIME_OPTIONS, convertLabel, convertTimeLabel, getOptionsValue } from "./setting-message.helper";
import CustomSettingTimeDialog from "./CustomSettingTimeDialog";
import AccountItem from "../../Account/AccountItem";
import { NavigateNext, TimerOutlined } from "@mui/icons-material";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { useConversationContext } from "../../MessengerChat/ConversationContext";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ConversationSelectors, NotificationSettingActions, NotificationSettingSelectors } from "redux-store";
import { createSelector } from "reselect";

const DEFAULT_DISAPPEAR_TIME = 0;

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getSelectedGroupId, NotificationSettingSelectors.getUpdateGroupSetting],

  (selectedGroupId, updateGroupSetting) => {
    if (updateGroupSetting && updateGroupSetting.groupId === selectedGroupId) {
      return updateGroupSetting.updateList.find(
        item => item.setting_sub_type === SystemConstant.SETTING_SUB_TYPE.DISAPPEAR_MESSAGE,
      );
    }

    return null;
  },
);

const AutoDeleteMessageDrawer = () => {
  const classes = useStyles();
  const { t: getSettingMessageLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { groupDetail } = useConversationContext();

  const remoteDisappearSetting = useSelector(memoizedIncomingState);

  const [settingTime, setSettingTime] = useState(DEFAULT_DISAPPEAR_TIME);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = isOpen => () => setOpenDrawer(isOpen);

  const handleChangeTime = value => {
    setSettingTime(value);
  };

  const handleInit = async () => {
    const localSetting = await getInteractor(prefixKey).LocalGroupSettingService.findOne({
      group_id: groupDetail.id,
      setting_sub_type: SystemConstant.SETTING_SUB_TYPE.DISAPPEAR_MESSAGE,
    });
    if (localSetting && localSetting.id) {
      setSettingTime(Number(localSetting.value));
    } else {
      setSettingTime(DEFAULT_DISAPPEAR_TIME);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    if (remoteDisappearSetting && Number(remoteDisappearSetting.value) !== settingTime) {
      setSettingTime(Number(remoteDisappearSetting.value));
    }
  }, [remoteDisappearSetting]);

  return (
    <>
      <AccountItem
        text={getSettingMessageLabel("TXT_AUTO_DELETE_MESSAGE")}
        endIcon={<EndIcon content={convertLabel(settingTime)} />}
        startIcon={<TimerOutlined />}
        onClick={handleOpenDrawer(true)}
      />
      {settingTime > 0 && (
        <Typography className={classes.textNote}>
          {getSettingMessageLabel("FM_DISAPPEAR_TIME", { time: convertTimeLabel(settingTime) })}
        </Typography>
      )}

      {openDrawer && (
        <AppDrawer
          open={openDrawer}
          onClose={handleOpenDrawer(false)}
          title={getSettingMessageLabel("TXT_AUTO_DELETE_MESSAGE")}
          anchor="right"
        >
          <Stack spacing={1} py={1} px={2}>
            <Typography variant="subtitle2" fontWeight={700}>
              {getSettingMessageLabel("TXT_SET_TIME_AUTO_DELETE")}
            </Typography>
            <Typography fontSize="0.875rem" color={theme.palette.grey[300]}>
              {getSettingMessageLabel(settingTime > 0 ? "FM_DISAPPEAR_TIME" : "TXT_SET_TIME_AUTO_DELETE_SUBTITLE", {
                time: convertTimeLabel(settingTime),
              })}
            </Typography>
          </Stack>
          <SettingTime settingTime={settingTime} onChange={handleChangeTime} groupId={groupDetail.id} />
          <WarningNote content={getSettingMessageLabel("TXT_SET_TIME_AUTO_DELETE_NOTE")} />
        </AppDrawer>
      )}
    </>
  );
};

export default AutoDeleteMessageDrawer;

const EndIcon = ({ content = "Tắt" }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography color={theme.palette.grey[300]}>{content}</Typography>
      <NavigateNext />
    </Stack>
  );
};

const SettingTime = ({ settingTime, onChange, groupId }) => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [openCustomTime, setOpenCustomTime] = useState(false);

  const updateSettingTime = useCallback(
    debounce(timeValue => {
      dispatch(
        NotificationSettingActions.updateGroupSetting(
          {
            groupId: groupId,
            settingSubType: SystemConstant.SETTING_SUB_TYPE.DISAPPEAR_MESSAGE,
            created: Date.now(),
            state: Boolean(Number(timeValue)) ? SystemConstant.STATE.active : SystemConstant.STATE.inactive,
            value: Number(timeValue),
          },
          prefixKey,
        ),
      );
    }, AppConstant.DEBOUNCE_TIME),
    [],
  );

  const handleClick = event => {
    const newTime = Number(event.target.value);
    if (isNaN(newTime)) return;

    if (newTime === -1) {
      setOpenCustomTime(true);
    } else {
      setOpenCustomTime(false);
      onChange(newTime);
      updateSettingTime(newTime);
    }
  };

  const handleSubmitCustomTime = timeValue => {
    setOpenCustomTime(false);
    onChange(Number(timeValue));
    updateSettingTime(Number(timeValue));
  };

  return (
    <SettingTimeComponent>
      <FormControl fullWidth>
        <RadioGroup value={getOptionsValue(settingTime)} onClick={handleClick}>
          {AUTO_DELETE_TIME_OPTIONS.map((item, index) => (
            <SettingTimeItem px={2} key={item.value}>
              <SettingTimeLabel value={item.value} control={<Radio />} label={item.label} />
              {index !== AUTO_DELETE_TIME_OPTIONS.length - 1 && <Divider />}
            </SettingTimeItem>
          ))}
        </RadioGroup>
      </FormControl>

      {openCustomTime && (
        <CustomSettingTimeDialog open onSubmit={handleSubmitCustomTime} onClose={() => setOpenCustomTime(false)} />
      )}
    </SettingTimeComponent>
  );
};

const SettingTimeComponent = styled(Box)(props => ({
  height: "fit-content",
  margin: "16px 16px",
  borderRadius: 8,
  border: "solid",
  borderWidth: "thin",
  borderColor: props.theme.palette.grey[200],
  overflowX: "hidden",
  overflowY: "auto",
}));

const SettingTimeLabel = styled(FormControlLabel)({
  margin: 0,
  padding: 6,
  flexDirection: "row-reverse",
  justifyContent: "space-between",
});

const SettingTimeItem = styled(Stack)(props => ({
  "&:hover": {
    backgroundColor: props.theme.palette.grey[100],
  },
}));

const useStyles = makeStyles(theme => ({
  textNote: {
    fontSize: "0.875rem",
    color: theme.palette.grey[300],
    padding: "0px 16px",
    paddingBottom: 8,
    paddingLeft: 50,
  },
}));
