import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class PickerColumn extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    itemHeight: PropTypes.number.isRequired,
    columnHeight: PropTypes.number.isRequired,
    tabIndex: PropTypes.number.isRequired,

    onChange: PropTypes.func.isRequired,
    onChangeUnit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      ...this.computeTranslate(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isMoving) {
      return;
    }
    this.setState(this.computeTranslate(nextProps));
  }

  computeTranslate = props => {
    const { data, value, itemHeight, columnHeight } = props;
    let selectedIndex = data.indexOf(value);
    if (selectedIndex < 0) {
      this.onValueSelected(data[0]);
      selectedIndex = 0;
    }
    return {
      scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
      minTranslate: columnHeight / 2 - itemHeight * data.length + itemHeight / 2,
      maxTranslate: columnHeight / 2 - itemHeight / 2,
    };
  };

  onValueSelected = newValue => {
    this.props.onChange(this.props.name, newValue);
    if (this.props.name === "unit" && this.props.onChangeUnit) {
      this.props.onChangeUnit(newValue);
    }
  };

  handleTouchStart = event => {
    const startTouchY = event.targetTouches[0].pageY;
    this.setState(({ scrollerTranslate }) => ({
      startTouchY,
      startScrollerTranslate: scrollerTranslate,
    }));
  };

  handleTouchMove = event => {
    event.preventDefault();
    const touchY = event.targetTouches[0].pageY;
    this.setState(({ isMoving, startTouchY, startScrollerTranslate, minTranslate, maxTranslate }) => {
      if (!isMoving) {
        return {
          isMoving: true,
        };
      }

      let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
      if (nextScrollerTranslate < minTranslate) {
        nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
      } else if (nextScrollerTranslate > maxTranslate) {
        nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
      }
      return {
        scrollerTranslate: nextScrollerTranslate,
      };
    });
  };

  handleTouchEnd = event => {
    if (!this.state.isMoving) {
      return;
    }
    this.setState({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
    });
    setTimeout(() => {
      this.postMove();
    }, 0);
  };

  handleTouchCancel = event => {
    if (!this.state.isMoving) {
      return;
    }
    this.setState(startScrollerTranslate => ({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      scrollerTranslate: startScrollerTranslate,
    }));
  };

  handleItemClick = option => {
    if (option !== this.props.value) {
      this.onValueSelected(option);
    }
  };

  postMove() {
    const { data, itemHeight } = this.props;
    const { scrollerTranslate, minTranslate, maxTranslate } = this.state;
    let activeIndex;
    if (scrollerTranslate > maxTranslate) {
      activeIndex = 0;
    } else if (scrollerTranslate < minTranslate) {
      activeIndex = data.length - 1;
    } else {
      activeIndex = -Math.floor((scrollerTranslate - maxTranslate) / itemHeight);
    }
    this.onValueSelected(data[activeIndex]);
  }

  postWheel() {
    const that = this;
    setTimeout(() => {
      if (that.state.isScrolling > Date.now() - 250) {
        this.postWheel();
        return;
      }
      this.postMove();
    }, 250);
  }

  handleScroll = event => {
    // Support for keyboard up/down
    let deltaY;
    if (!!event.keyCode && (event.keyCode === 38 || event.keyCode === 40)) {
      deltaY = event.keyCode === 38 ? 53 : -53;
    } else if (!!event.deltaY) {
      deltaY = event.deltaY;
    } else {
      deltaY = 0;
    }

    this.setState(({ scrollerTranslate, minTranslate, maxTranslate }) => {
      const newTranslate = Math.max(
        minTranslate,
        Math.min(maxTranslate, (scrollerTranslate || 0) - Math.round(deltaY)),
      );

      this.postWheel();

      return {
        scrollerTranslate: newTranslate,
        isScrolling: Date.now(),
      };
    });
  };

  renderItems() {
    const { data, itemHeight, value } = this.props;
    return data.map((option, index) => {
      const style = {
        height: itemHeight + "px",
        lineHeight: itemHeight + "px",
      };
      const className = `picker-item${option === value ? " picker-item-selected" : ""}`;
      return (
        <div key={index} className={className} style={style} onClick={() => this.handleItemClick(option)}>
          {option}
        </div>
      );
    });
  }

  render() {
    const { tabIndex } = this.props;
    const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
    const style = {
      MsTransform: translateString,
      MozTransform: translateString,
      OTransform: translateString,
      WebkitTransform: translateString,
      transform: translateString,
    };
    if (this.state.isMoving) {
      style.transitionDuration = "0ms";
    }
    return (
      <div className="picker-column">
        <div
          tabIndex={tabIndex}
          className="picker-scroller"
          style={style}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onTouchCancel={this.handleTouchCancel}
          onWheel={this.handleScroll}
          onKeyDown={this.handleScroll}
        >
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

export default class Picker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTyps = {
    optionGroups: PropTypes.object.isRequired,
    valueGroups: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    itemHeight: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    itemHeight: 36,
    height: 108,
  };

  renderInner() {
    const { optionGroups, valueGroups, itemHeight, height, onChange, onChangeUnit } = this.props;
    const highlightStyle = {
      height: itemHeight,
      marginTop: -(itemHeight / 2),
    };
    let index = 1000;
    const columnNodes = [];
    for (let name in optionGroups) {
      columnNodes.push(
        <PickerColumn
          key={name}
          name={name}
          data={optionGroups[name]}
          value={valueGroups[name]}
          tabIndex={index++}
          itemHeight={itemHeight}
          columnHeight={height}
          onChange={onChange}
          onChangeUnit={onChangeUnit}
        />,
      );
    }
    return (
      <div className="picker-inner">
        {columnNodes}
        <div className="picker-highlight" style={highlightStyle} />
      </div>
    );
  }

  render() {
    const style = {
      height: this.props.height,
    };

    return (
      <div className="picker-container" style={style}>
        {this.renderInner()}
      </div>
    );
  }
}
