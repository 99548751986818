import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Button, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { convertString2JSON, getPrefixKey, toCamel, toSnake } from "utils";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { LocalDbManagement, LocalKeyActionService, getInteractor } from "services/local.service";
import { AuthActions } from "redux-store";
import { StorageUtil } from "utils";
import { AuthService, CommonBranchInfoService, remoteApiFactory } from "services";
import { BGlobalServerImage } from "const/image.const";
import { ConfirmDialog } from "components";
import { changeBranchServer } from "utils/branch.utils";
import { handlingLogoutBranch } from "utils/auth.utils";
import { NoticeAvatar, NotificationContent, NotificationItem, useNotificationStyles } from "./component";

const NotificationInviteItem = ({ data, onAccept, ...otherProps }) => {
  const classes = useStyles();
  const notificationStyles = useNotificationStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();

  const [branchDetail, setBranchDetail] = useState({});
  const [notification, setNotification] = useState(data);
  const [currentLoginBranch, setCurrentLoginBranch] = useState({});
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const currentPrefixKey = StorageUtil.getCommonKey(KeyConstant.KEY_PREFIX);

  const handleAccept = async () => {
    // Go to branch if user login branch before
    let branchItem = await LocalDbManagement.findOne({
      state: SystemConstant.STATE.active,
      branch_id: branchDetail.id,
    });

    branchItem = branchItem ? toCamel(branchItem) : {};
    if (branchItem.id && branchItem.ownerName === notification.invitePhone) {
      const prefixKey = getPrefixKey(branchItem.accountId, branchItem.branchId);
      const selectedBranch = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);
      setCurrentLoginBranch({});
      updateInvitation(data, true, currentPrefixKey);
      changeBranchServer(selectedBranch);
      onAccept();
      return;
    } else if (branchItem.id) {
      setCurrentLoginBranch(branchItem);
      setIsShowConfirm(true);
    } else {
      setCurrentLoginBranch({});
      await handleLoginBranch();
    }
  };

  const handleLoginBranch = async () => {
    updateInvitation(data, true, currentPrefixKey);
    StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, branchDetail);
    const [loginParams, dataLogin] = await requestLogin(branchDetail.domain, notification);
    if (dataLogin) {
      const verifyParams = {
        device_name: StorageUtil.getCommonKey(KeyConstant.KEY_DEVICE_NAME),
        phone: loginParams.phone,
        token: loginParams.token,
        account_uuid: loginParams.accountUuid,
        device_uuid: loginParams.deviceUuid,
        verify_token: notification.content,
      };

      setNotification(preState => ({ ...preState, state: SystemConstant.INVITATION_STATE.accepted }));
      dispatch(AuthActions.requestVerify(toSnake(verifyParams)));
    }

    if (currentLoginBranch && currentLoginBranch.id) {
      const prefixKey = getPrefixKey(currentLoginBranch.accountId, currentLoginBranch.branchId);
      await handlingLogoutBranch(prefixKey);
    }
    onAccept();
  };

  const handleDecline = () => {
    setNotification(preState => ({ ...preState, state: SystemConstant.INVITATION_STATE.rejected }));

    // Decline invitation
    updateInvitation(data, false, currentPrefixKey);
  };

  const handleUpdateBranch = async data => {
    const branchInfo = await CommonBranchInfoService.getBranchWithoutToken(data.branchDomain, data.branchId);
    const branchAvatarUrl = CommonBranchInfoService.getBranchAvatarUrl(data.branchDomain, data.branchId);

    setBranchDetail({ ...branchInfo, branchAvatarUrl: branchAvatarUrl || BGlobalServerImage });
  };

  useEffect(() => {
    if (!data) return;

    const inviteOptions = toCamel(convertString2JSON(data.options, {}));
    handleUpdateBranch(inviteOptions);

    setNotification({ ...data, invitePhone: inviteOptions.ownerName });
  }, [data]);

  let notifyContent = "";
  switch (notification.state) {
    case SystemConstant.INVITATION_STATE.accepted:
      notifyContent = getLabel(LangConstant.TXT_INVITE_ACCEPTED);
      break;

    case SystemConstant.INVITATION_STATE.deleted:
      notifyContent = getLabel(LangConstant.TXT_INVITE_DISMISSED);
      break;

    case SystemConstant.INVITATION_STATE.rejected:
      notifyContent = getLabel(LangConstant.TXT_INVITE_REJECTED);
      break;

    default:
      notifyContent = getLabel(LangConstant.TXT_INVITE_TO_SERVER);
      break;
  }

  const isRead = data.status === SystemConstant.NOTIFICATION_STATUS.read;

  return (
    <>
      <NotificationItem className={isRead ? notificationStyles.readNotice : ""} {...otherProps}>
        <NoticeAvatar
          alt="Branch Avatar"
          src={branchDetail.branchAvatarUrl}
          variant="square"
          className={classes.branchAvatar}
        />
        <NotificationContent gap={0.5}>
          <Typography variant="subtitle2" lineHeight="1rem">
            {branchDetail.name}
          </Typography>
          <Typography className="regular-sm-txt" lineHeight="1rem">
            {notifyContent}
          </Typography>
        </NotificationContent>
      </NotificationItem>

      {notification.state === SystemConstant.INVITATION_STATE.pending && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1} spacing={1} px={1.75} pb={1.5}>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.actionButton, "semiBold-sm-txt")}
            onClick={handleAccept}
          >
            {getCommonLang(LangConstant.TXT_ACCEPT)}
          </Button>
          <Button
            className={clsx(classes.actionButton, classes.deniedButton, "semiBold-sm-txt")}
            onClick={handleDecline}
          >
            {getCommonLang(LangConstant.TXT_DENIED)}
          </Button>
        </Stack>
      )}

      {isShowConfirm && (
        <ConfirmDialog
          open
          content={getNSLang(LangConstant.NS_COMMON, "FM_SWITCH_BRANCH", {
            branchName: currentLoginBranch.branchName,
            phone: currentLoginBranch.ownerName,
          })}
          submitProps={{
            submitText: getNSLang(LangConstant.NS_COMMON, "TXT_CONFIRM"),
            onClick: handleLoginBranch,
          }}
          cancelProps={{ onClick: () => setIsShowConfirm(false) }}
        />
      )}
    </>
  );
};

export default NotificationInviteItem;

const updateInvitation = async (notification, isAccepted, currentPrefixKey) => {
  const noticeState = isAccepted ? SystemConstant.INVITATION_STATE.accepted : SystemConstant.INVITATION_STATE.rejected;

  // Update invitation on global server
  const invitationResponse = await remoteApiFactory.getBranchApi().updateMessageStatus(
    toSnake({
      notifyIds: [notification.id],
      status: SystemConstant.NOTIFICATION_STATUS.read,
      state: noticeState,
    }),
  );
  if (invitationResponse.status !== ApiConstant.STT_OK) return;

  // Update invitation on global database
  await getInteractor(currentPrefixKey).LocalNotificationService.update(
    {
      status: SystemConstant.NOTIFICATION_STATUS.read,
      state: noticeState,
      modified: Date.now(),
    },
    { id: notification.id },
  );
};

const requestLogin = async (domain, notification) => {
  const initLoginData = await LocalKeyActionService.getInitLoginData();
  const loginParams = {
    ...initLoginData,
    regionCode: "+84",
    phone: notification.invitePhone,
    deviceUuid:
      StorageUtil.getCommonKey(KeyConstant.KEY_MAC) || StorageUtil.getCommonKey(KeyConstant.KEY_DEFAULT_DEVICE_ID),
    verify_token: notification.content,
    version: window.env?.APP_VERSION || initLoginData.version,
  };

  const loginResponse = await AuthService.login(toSnake(loginParams), domain);
  const dataLogin = toCamel(loginResponse.data);
  if (loginResponse.status === ApiConstant.STT_OK && dataLogin) {
    return [loginParams, dataLogin];
  }

  return [];
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    borderRadius: 10,
    height: 35,
    minHeight: "unset",
    width: "50%",
  },

  deniedButton: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.black,
  },

  branchAvatar: {
    backgroundColor: "transparent",
    "& img": {
      objectFit: "contain",
    },
  },
}));
